export default {
    position: 'top-right',
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    hideProgressBar: false,
    progressStyle: {
        background: '#d20e50',
    },
    bodyStyle: {
        color: '#303030',
    },
};

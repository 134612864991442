import React from 'react';
import styled from 'styled-components';

import MonitoringMenuItem, {
    ChartContainer,
} from '../MenuItem/MonitoringMenuItem';
import { SeeHistoryButton } from '../MenuItem';
import HealthChart from '../HealthChart';

const DetailsContainer = styled.div`
    margin-bottom: 20px;
`;

export const ActionMenu = styled.div`
    display: flex;
    justify-content: space-between;

    max-width: 480px;
    margin: 20px auto;

    @media (max-width: 395px) {
        flex-wrap: wrap;

        div {
            width: 100%;
        }
    }
`;

export default ({ seeHistory, subjectData, subject }) => (
    <DetailsContainer>
        <ChartContainer>
            <div className="h-full w-full">
                <HealthChart subjectData={subjectData.data} />
            </div>
        </ChartContainer>

        <ActionMenu>
            <MonitoringMenuItem subjectData={subjectData} subject={subject} />
            <SeeHistoryButton onClick={seeHistory} height="100%" />
        </ActionMenu>
    </DetailsContainer>
);

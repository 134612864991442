export const BiologicalSex = [
    {
        label: 'Feminino',
        value: 'F',
    },
    {
        label: 'Masculino',
        value: 'M',
    },
];

export const Gender = [
    {
        label: 'Masculino',
        value: 'Masculino',
    },
    {
        label: 'Feminino',
        value: 'Feminino',
    },
    {
        label: 'Homem Transgênero',
        value: 'Homem Transgênero',
    },
    {
        label: 'Mulher Transgênero',
        value: 'Mulher Transgênero',
    },
    {
        label: 'Homem Transexual',
        value: 'Homem Transexual',
    },
    {
        label: 'Mulher Transexual',
        value: 'Mulher Transexual',
    },
    {
        label: 'Cisgênero',
        value: 'Cisgênero',
    },
    {
        label: 'Não sei responder',
        value: 'Não sei responder',
    },
    {
        label: 'Prefiro não responder',
        value: 'Prefiro não responder',
    },
    {
        label: 'Outro',
        value: 'Outro',
    },
];

export const MaritalStatus = [
    {
        label: 'Solteiro(a)',
        value: 'S',
    },
    {
        label: 'Casado(a)',
        value: 'C',
    },
    {
        label: 'Divorciado(a)',
        value: 'D',
    },
    {
        label: 'Viúvo(a)',
        value: 'V',
    },
    {
        label: 'Outro',
        value: 'O',
    },
];

export const Children = [
    {
        label: 'Não tenho',
        value: '0',
    },
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '+3',
        value: '+3',
    },
];

export const YN = [
    {
        label: 'Sim',
        value: 'S',
    },
    {
        label: 'Não',
        value: 'N',
    },
];

import React, { useState } from 'react';
import Icon from '../ui/Icon';
import ListExamItem from './ListExamItem';
import ListAttachmentItem from './ListAttachmentItem';
import cn from 'classnames';

const ListTabPanel = ({
    userFiles,
    OnEdit = () => {},
    OnDelete = () => {},
    tab, 
    setTab
}) => {
    return (
        <div className="flex flex-col w-full h-auto bg-white">
            <div className="flex flex-col m-6">
                <div className="grid grid-cols-2 gap-2.5">
                    <div
                        className={cn(
                            tab === 'exames'
                                ? 'border rounded'
                                : 'cursor-pointer',
                        )}
                        style={
                            tab === 'exames'
                                ? {
                                      borderColor: '#FBE7EE',
                                      background: '#FBE7EE',
                                  }
                                : {}
                        }
                        onClick={() => setTab('exames')}
                    >
                        <div className="flex flex-row ml-2 mt-2">
                            <Icon
                                name={tab === 'exames' ? 'paper-pink' : 'paper'}
                                size={21}
                            />
                            <span
                                className={cn(
                                    'ml-2 text-base',
                                    tab === 'exames'
                                        ? 'font-medium'
                                        : 'font-thin',
                                )}
                            >
                                Meus Exames
                            </span>
                        </div>
                        <div
                            className={cn(
                                'border mx-2 mb-0 mt-2 rounded',
                                tab === 'exames'
                                    ? 'border-pink-500'
                                    : 'border-gray-600',
                            )}
                        />
                    </div>
                    <div
                        className={cn(
                            tab === 'anexos'
                                ? 'border rounded'
                                : 'cursor-pointer',
                        )}
                        style={
                            tab === 'anexos'
                                ? {
                                      borderColor: '#FBE7EE',
                                      background: '#FBE7EE',
                                  }
                                : {}
                        }
                        onClick={() => setTab('anexos')}
                    >
                        <div className="flex flex-row ml-2 mt-2">
                            <Icon
                                name={
                                    tab === 'exames' ? 'folder' : 'folder-pink'
                                }
                                size={21}
                            />
                            <span
                                className={cn(
                                    'ml-2 text-base',
                                    tab === 'exames'
                                        ? 'font-thin'
                                        : 'font-medium',
                                )}
                            >
                                Meus Anexos
                            </span>
                        </div>
                        <div
                            className={cn(
                                'border mx-2 mb-0 mt-2 rounded',
                                tab === 'exames'
                                    ? 'border-gray-600'
                                    : 'border-pink-500',
                            )}
                        />
                    </div>
                </div>

                <div
                    className={cn(
                        'grid grid-cols-1 gap-4',
                        tab === 'exames' ? 'block' : 'hidden',
                    )}
                >
                    {userFiles
                        .filter((f) => f.upload_type === 'exam')
                        .map((f) => (
                            <ListExamItem
                                f={f}
                                key={f.id}
                                OnAction={(file, action) => {
                                    if (action === 'edit') OnEdit(file);
                                    else OnDelete(file);
                                }}
                            />
                        ))}
                    {userFiles.filter((f) => f.upload_type === 'exam')
                        .length === 0 && (
                        <span className="flex justify-center m-4">
                            Nenhum exame encontrado.
                        </span>
                    )}
                </div>

                <div
                    className={cn(
                        'grid grid-cols-1 gap-4',
                        tab === 'exames' ? 'hidden' : 'block',
                    )}
                >
                    {userFiles
                        .filter((f) => f.upload_type === 'attach')
                        .map((f) => (
                            <ListAttachmentItem
                                f={f}
                                key={f.id}
                                OnAction={(file, action) => {
                                    if (action === 'edit') OnEdit(file);
                                    else OnDelete(file);
                                }}
                            />
                        ))}
                    {userFiles.filter((f) => f.upload_type === 'attach')
                        .length === 0 && (
                        <span className="flex justify-center m-4">
                            Nenhum anexo encontrado.
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListTabPanel;

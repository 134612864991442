import { backendServices } from './AxiosInstances';

export const getMedicalUnits = () =>
    backendServices
        .get('/api/v1/unit')
        .then(({ data }) => data.data)
        .catch((error) => {
            throw new Error(error);
        });

export const appointmentsService = (query) =>
    backendServices
        .get('/api/v1/consultation', { params: query })
        .then(({ data }) => data.data)
        .catch((error) => {
            throw new Error(error);
        });

export const getSpecialties = async () => {
    const res = await backendServices
        .get('/api/v1/specialty')
        .then(({ data }) => {
            return data.data.map(({ nome, id, ...rest }) => {
                if (!nome) return;
                return {
                    label: nome,
                    id,
                    value: {
                        ...rest,
                    },
                };
            });
        })
        .catch((error) => {
            throw new Error(error);
        });

    return res.filter((el) => el);
};

export const getPaymentProfiles = (params) =>
    backendServices
        .get('/api/v1/user/payment-profiles', params)
        .then(({ data: { data: { payment_profiles } } }) =>
            payment_profiles.map(
                ({ id, card_number_last_four, payment_company }) => ({
                    id,
                    label: `Cartão com final ${card_number_last_four}`,
                    value: payment_company.code,
                }),
            ),
        )
        .catch((error) => {
            throw new Error(error);
        });

export const bookAppointment = (body) =>
    backendServices
        .post('/api/v1/consultation/schedule', body)
        .then(({ data }) => data.data);

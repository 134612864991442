import React, { useContext } from 'react';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';

const MessageNurse = () => {
    const { question, messageNurse } = useContext(HealthContext);
    return (
        <div
            className={`messageNurse container border-gray-500 ${
                question && (question.message ? 'max-w-2xl' : 'max-w-lg')
            }  mx-auto py-4 sm:my-4 sm:border sm:rounded`}
        >
            <p className="text-white">
                Você está respondendo o questionario para o usuário:{' '}
                {messageNurse.nome}, cpf: {messageNurse.cpf}
            </p>
        </div>
    );
};

export default MessageNurse;

import React from 'react';
import cn from 'classnames';

import { Icon } from '../../ui';

const Badge = ({ title, className, iconName, color = 'teal' }) => {
    return (
        <div
            className={cn(
                `px-2 py-1 bg-${color}-600 rounded-full flex items-center`,
                className,
            )}
        >
            {iconName && (
                <Icon
                    name={iconName}
                    size={16}
                    className="fill-current text-white mr-1"
                />
            )}
            <span className="text-xs text-white font-medium">{title}</span>
        </div>
    );
};

export default Badge;

import React from 'react';
import styled, { css } from 'styled-components';

import avatar_m from '../../../assets/illustrations/doctor-avatar-m.svg';
import avatar_f from '../../../assets/illustrations/doctor-avatar-f.svg';
import { Icon } from '../../ui';

const avatarStyle = css`
    border-radius: 50%;

    ${({ theme: { mixins } }) => mixins.assina_shadow_sm}
`;

const AvatarM = styled(avatar_m)`
    background: ${({ simpleBG }) => simpleBG && '#E05685'};
    ${avatarStyle}

    ${({ size }) =>
        size &&
        css`
            min-height: ${size ? `${size}px` : '58px'};
            min-width: ${size ? `${size}px` : '58px'};

            height: ${size ? `${size}px` : '58px'};
            width: ${size ? `${size}px` : '58px'}; ;
        `}
`;

const AvatarF = styled(avatar_f)`
    background: ${({ simpleBG }) => simpleBG && '#E05685'};
    ${avatarStyle}

    ${({ size }) =>
        size &&
        css`
            min-height: ${size ? `${size}px` : '58px'};
            min-width: ${size ? `${size}px` : '58px'};

            height: ${size ? `${size}px` : '58px'};
            width: ${size ? `${size}px` : '58px'}; ;
        `}
`;

const ProfilePic = styled.img`
    ${avatarStyle}

    ${({ size }) =>
        size &&
        css`
            min-height: ${size ? `${size}px` : '58px'};
            min-width: ${size ? `${size}px` : '58px'};

            height: ${size ? `${size}px` : '58px'};
            width: ${size ? `${size}px` : '58px'}; ;
        `}
`;

const EmptyAvatar = styled.div`
    ${avatarStyle}

    height: 58px;
    width: 58px;
    padding: 15px;
    background: ${({ theme: { colors } }) => colors.primaryPink3};
    ${({ theme: { mixins } }) => mixins.full_flex}
`;

const Avatar = ({ doctor: { foto, sexo, nome }, simpleBG, size }) => {
    return foto ? (
        <ProfilePic
            src={foto.replace('/media', 'http://localhost:8000/static')}
            alt={nome}
            size={size}
        />
    ) : sexo === 'M' ? (
        <AvatarM size={size} simpleBG={simpleBG} />
    ) : sexo === 'F' ? (
        <AvatarF size={size} simpleBG={simpleBG} />
    ) : (
        <EmptyAvatar>
            <Icon
                name="profile"
                size={27}
                className="fill-current text-white"
            />
        </EmptyAvatar>
    );
};

export default Avatar;

import React from 'react';
import { Circle } from 'rc-progress';

import {
    Button,
    Container,
    DeskProgress,
    MobiProgress,
    Title,
    Diretionals,
} from './styles';

import { Icon } from '../../ui';

const FirstSteps = ({
    item,
    max,
    active,
    first,
    last,
    getPrevious,
    getNext,
    percentage,
    buttonAction,
    stepDone,
}) => {
    if (!active) return null;
    return (
        <Container>
            <DeskProgress>
                <span>
                    {item} de {max}
                </span>
                <Circle
                    percent={percentage}
                    strokeWidth="6"
                    strokeColor="#D20E50"
                    trailWidth="6"
                    trailColor="#EAEAEA"
                    style={{ height: '100%' }}
                />
            </DeskProgress>
            <div className="w-full">
                <Title>
                    <MobiProgress>
                        <span>
                            {item} de {max}
                        </span>
                        <Circle
                            percent={percentage}
                            strokeWidth="6"
                            strokeColor="#D20E50"
                            trailWidth="6"
                            trailColor="#EAEAEA"
                            style={{ height: '100%' }}
                        />
                    </MobiProgress>
                    <div className="text-left w-full flex flex-col justify-center items-center sm:items-start">
                        <h3 className="font-semibold text-lg text-pink-700 mb-2 select-none">
                            Primeiros passos
                        </h3>
                        <h2 className="font-semibold text-xl text-pink-600 select-none">
                            {active.title}
                        </h2>
                    </div>
                </Title>
                <p className="text-xl text-gray-700 my-4 select-none">
                    {active.message}
                </p>
                <div className="flex items-center justify-between">
                    <Button
                        success={stepDone}
                        type="button"
                        onClick={() => {
                            if (stepDone) return;
                            buttonAction(active.name);
                        }}
                    >
                        {stepDone ? 'Feito' : active.buttonTitle}
                    </Button>
                    <div className="flex">
                        <Diretionals first={first}>
                            <Icon
                                name="directional_left"
                                size={30}
                                className="cursor-pointer mx-8"
                                onClick={getPrevious}
                            />
                        </Diretionals>
                        <Diretionals last={last}>
                            <Icon
                                name="directional_right"
                                size={30}
                                className="cursor-pointer"
                                onClick={getNext}
                            />
                        </Diretionals>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default FirstSteps;

import React from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';

import { Button, Icon } from '../../../ui';

import { formatDate, splitAndParseDate } from '../../../../utils/Dates';

const ItemsWrapper = styled.div`
    width: 100%;

    ${({ size }) =>
        size > 1
            ? css`
                  div:first-child {
                      border-radius: 6px 6px 0 0;
                  }

                  div:last-child {
                      border-radius: 0 0 6px 6px;
                  }
              `
            : css`
                  div {
                      border-radius: 6px;
                  }
              `}
`;

const ScheduleItem = ({ next, date, data }) => (
    <div className="flex">
        <div
            className={cn(
                'text-xl mr-4 mt-4',
                next ? 'text-teal-600' : 'text-gray-700',
            )}
        >
            <h2 className="font-medium">
                {format(splitAndParseDate(date), 'dd')}
            </h2>
            <span>
                {formatDate(splitAndParseDate(date), 'MMMM').substr(0, 3)}
            </span>
        </div>
        <ItemsWrapper
            className={cn(data.length < 1 && 'space-y-4')}
            size={data.length}
        >
            {data.map(
                ({
                    id,
                    created_at,
                    user_rule: { title, content, link, link_title },
                }) => (
                    <div
                        key={id}
                        className={cn(
                            'w-full p-4 assina-shadow-sm',
                            next ? 'bg-teal-600' : 'bg-white',
                        )}
                    >
                        <div className="flex justify-between">
                            <h1
                                className={cn(
                                    'text-xl font-medium',
                                    next ? 'text-white' : 'text-teal-600',
                                )}
                            >
                                {title}
                            </h1>
                        </div>
                        {content && (
                            <div
                                className={cn(
                                    'flex mt-2 text-lg',
                                    next ? 'text-white' : 'text-gray-800',
                                )}
                            >
                                <span>{content}</span>
                            </div>
                        )}
                        <div className="flex justify-between">
                            <div
                                className={cn(
                                    'flex items-center mt-4',
                                    next ? 'text-white' : 'text-gray-800',
                                )}
                            >
                                <Icon
                                    className={cn(
                                        'fill-current mr-2',
                                        next ? 'text-white' : 'text-gray-800',
                                    )}
                                    name="clock"
                                    size={30}
                                />
                                <span className="text-xl font-medium">
                                    {created_at && created_at.substr(created_at.length - 5)}
                                </span>
                            </div>
                            {link && link_title && (
                                <Button
                                    className="px-4 mt-4"
                                    onClick={() => window.open(link, '_blank')}
                                >
                                    {link_title}
                                </Button>
                            )}
                        </div>
                    </div>
                ),
            )}
        </ItemsWrapper>
    </div>
);

export default ScheduleItem;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import Tippy from '@tippyjs/react';
import cn from 'classnames';
import styled, { css } from 'styled-components';

import AnimatedGift from './AnimatedGift';
import { Spinner, Button, Icon, ReactModal as Modal } from '../ui';

const Accordion = styled.div`
    ${({ isDescriptionExpanded }) =>
        isDescriptionExpanded
            ? css`
                  max-height: 100%;
              `
            : css`
                  max-height: 0;
              `}

    transition: max-height .6s ease-out;
    overflow: hidden;
`;

const InvitePage = ({
    link,
    balance,
    onCopy,
    onClaim,
    hasCopied,
    referees,
    isClaimModalVisible,
    isDescriptionExpanded,
    setDescriptionExpanded,
    loading,
    setClaimModalVisible,
    openSupportWhatsApp,
    openPrompt,
}) => {
    return (
        <div className="bg-gray-300 p-4">
            <div className="mt-4 p-4 bg-white relative rounded max-w-xl mx-auto">
                <div className="relative">
                    <div className="relative mx-auto block w-32 h-32">
                        <AnimatedGift />
                    </div>
                    <h1 className="text-2xl font-medium text-teal-600 text-center px-4">
                        Convide seus amigos e ganhe descontos
                    </h1>
                    <div
                        className="flex justify-between mt-4 cursor-pointer"
                        onClick={() =>
                            setDescriptionExpanded(!isDescriptionExpanded)
                        }
                    >
                        <h3 className="text-lg font-medium text-pink-600">
                            Como funciona?
                        </h3>
                        <Icon
                            name={
                                isDescriptionExpanded
                                    ? 'angle-up'
                                    : 'angle-down'
                            }
                            className="fill-current text-pink-600"
                        />
                    </div>
                    <Accordion
                        className="mt-2 text-gray-900"
                        isDescriptionExpanded={isDescriptionExpanded}
                    >
                        <p>
                            É super simples! Clique abaixo e envie seu convite
                            personalizado para todos os seus amigos e familiares
                            por whatsapp, facebook ou email. Pronto: a cada nova
                            assinatura a partir do seu link, você ganha um mês
                            de isenção, e a primeira mensalidade de sua
                            indicação sai a partir de R$&nbsp;4,90.
                            <br /> <br /> E o melhor de tudo: é acumulativo!!!
                            Ou seja, se 10 amigos realizarem a adesão através do
                            seu link, você tem 10 meses de assinatura gratuita.
                            É a forma de manter o cuidado de sua saúde e não ter
                            nenhum custo com isso por vários meses, aproveite!!!
                        </p>

                        <div className="flex items-center justify-center flex-col mt-4">
                            <span>Ainda ficou alguma dúvida?</span>
                            <button
                                type="button"
                                className="bg-pink-600 px-4 py-2 mt-2 text-lg font-medium text-white tracking-widest hover:bg-pink-700 rounded flex justify-center items-center"
                                onClick={openSupportWhatsApp}
                            >
                                Entre em contato{' '}
                                <Icon
                                    size={20}
                                    name="whatsapp"
                                    className="fill-current text-white ml-2"
                                />
                            </button>
                        </div>
                    </Accordion>
                    <div className="flex justify-center items-center rounded overflow-hidden h-12 mt-4">
                        <Button className="px-4" onClick={openPrompt}>
                            Enviar convite
                        </Button>
                    </div>
                    <p className="text-teal-600 font-medium text-lg mt-4">
                        Seus convidados que aderiram à Kompa Saúde:
                    </p>
                    {referees.map((r, idx) => (
                        <div key={idx} className={cn(idx > 0 && 'mt-2')}>
                            <p className="text-green-600 font-medium">
                                {r.name}
                            </p>
                        </div>
                    ))}
                    {referees.length === 0 && loading && (
                        <Spinner color="teal" />
                    )}
                    {referees.length === 0 && !loading && (
                        <div>
                            Nenhuma indicação utilizou o seu link até agora.
                        </div>
                    )}
                    <hr className="mt-4" />
                    <div className="mt-2 flex justify-between">
                        <p className="font-medium text-teal-600 text-lg">
                            Saldo:
                        </p>
                        <p className="text-green-600 font-medium text-lg">
                            R$&nbsp;{balance},00
                        </p>
                    </div>
                    {balance > 0 && loading && (
                        <button
                            type="button"
                            className="w-full h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-medium tracking-widest mt-4"
                        >
                            <Spinner />
                        </button>
                    )}
                    {balance > 0 && !loading && (
                        <button
                            type="button"
                            onClick={onClaim}
                            className="w-full h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-medium tracking-widest mt-4 hover:bg-pink-700"
                        >
                            Solicitar resgate
                        </button>
                    )}
                    {balance === 0 && (
                        <Tippy content="Você não tem saldo para resgatar.">
                            <button
                                type="button"
                                disabled
                                className="cursor-default w-full h-12 bg-gray-600 text-lg text-white rounded text-center flex items-center justify-center font-medium tracking-widest mt-4"
                            >
                                Solicitar resgate
                            </button>
                        </Tippy>
                    )}
                </div>
            </div>
            <Modal
                isOpen={isClaimModalVisible}
                onRequestClose={() => setClaimModalVisible(false)}
                contentLabel="Solicitação de resgate confirmada"
            >
                <h2 className="text-lg font-medium text-teal-600">
                    Solicitação realizada com sucesso!
                </h2>
                <div>
                    Nossa equipe entrará em contato em até 24 horas para
                    confirmar o seu desconto.
                </div>
                <Button
                    as="a"
                    className="mt-4"
                    onClick={() => setClaimModalVisible(false)}
                >
                    OK
                </Button>
            </Modal>
        </div>
    );
};

export default InvitePage;

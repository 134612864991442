/* eslint-disable no-restricted-properties */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
export function isWorkingHour(time) {
    return (
        time.getDay() !== 0 &&
        time.getDay() <= 5 &&
        time.getHours() >= 8 &&
        time.getHours() < 18
    );
}

export const clearingPhoneNumber = (v, replaceString = '') => {
    let value = v;
    if (!value) {
        return '';
    }
    [...replaceString].forEach((i) => {
        value = value.replaceAll(i, '');
    });
    return value;
};

export function formatPhoneNumber(str, type) {
    const cleaned = `${str}`.replace(/\D/g, '');
    const match =
        type === 'tel'
            ? cleaned.match(/^(\d{4})(\d{4})$/)
            : cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
        return type === 'tel'
            ? `(11) ${match[1]}-${match[2]}`
            : `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
}

export function getParameterByName(name) {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export const objToQueryParams = (params) => {
    const keys = Object.keys(params);
    let url = '';
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!params[key]) continue;
        if (url.length > 0) url += '&';
        url += `${key}=${encodeURIComponent(params[key])}`;
    }
    return url;
};

function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log(`Fallback: Copying text command was ${msg}`);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function () {
            // pass...
        },
        function (err) {
            // pass...
        },
    );
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function eraseCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function noop() {}

export function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`.replace(
        '.',
        ',',
    );
}

export const optionsSpecialty = [
    { label: 'Clínico Geral', value: 0 },
    { label: 'Alergologia', value: 1 },
    { label: 'Cirurgia plástica', value: 2 },
    { label: 'Cirurgia vascular', value: 3 },
    { label: 'Dermatologia', value: 4 },
    { label: 'Gastroenterologia', value: 5 },
    { label: 'Ginecologia', value: 6 },
    { label: 'Mastologia', value: 7 },
    { label: 'Oftalmologia', value: 8 },
    { label: 'Ortopedia', value: 9 },
    { label: 'Otorrinolaringologia', value: 10 },
    { label: 'Pediatria', value: 11 },
    { label: 'Proctologia', value: 12 },
    { label: 'Urologia', value: 13 },
    { label: 'Cardiologia', value: 14 },
    { label: 'Endocrinologia', value: 15 },
    { label: 'Infectologia', value: 16 },
    { label: 'Reumatologia', value: 17 },
    { label: 'Hematologia', value: 18 },
    { label: 'Nefrologia', value: 19 },
    { label: 'Nutrologia', value: 20 },
    { label: 'Pneumologia', value: 21 },
    { label: 'Geriatria', value: 22 },
    { label: 'Homeopatia', value: 23 },
    { label: 'Neurologista', value: 24 },
    { label: 'Psiquiatria', value: 25 },
    { label: 'Fisioterapia', value: 26 },
    { label: 'Acupuntura', value: 27 },
    { label: 'Psicologia', value: 28 },
    { label: 'Nutrição', value: 29 },
];

export const valuesWithoutPlan = [
    'R$ 89,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 109,00',
    'R$ 139,00',
    'R$ 139,00',
    'R$ 139,00',
    'R$ 139,00',
    'R$ 139,00',
    'R$ 139,00',
    'R$ 139,00',
    'R$ 139,00',
    'R$ 159,00',
    'R$ 159,00',
    'R$ 159,00',
    'R$ 159,00',
    'R$ 69,00',
    'R$ 79,00',
    'R$ 89,00',
    'R$ 99,00',
];

export const valuesWithPlan = [
    'Gratuito',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 39,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 59,90',
    'R$ 59,90',
    'R$ 59,90',
    'R$ 59,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
    'R$ 49,90',
];

export const aboutSpecialty = [
    'O clínico geral é um profissional da Medicina com amplo conhecimento sobre o funcionamento do corpo humano em seu conjunto. Ele é responsável por acompanhar e diagnosticar possíveis problemas de saúde em seus pacientes.',
    'Alergologia é a especialidade da Medicina que visa o diagnóstico e o tratamento das doenças alérgicas.',
    'O Cirurgião Plástico atua na forma e função dos órgãos, tecidos e músculos. Assim, o cirurgião plástico: Promove mudança na disposição dos tecidos do corpo inteiro com finalidades reparadoras e estéticas.',
    'A cirurgia vascular é a especialidade médica que cuida dos vasos sanguíneos e da circulação sanguínea. Assim como diversas partes e sistemas do organismo, as estruturas necessárias ao bom funcionamento do sistema circulatório também podem ser afetadas por diversas condições.',
    'A Dermatologia é uma especialidade médica cuja área de conhecimento se concentra no diagnóstico, prevenção e tratamento de doenças e afecções relacionadas à pele, pelos, mucosas, cabelo e unhas. É também especialidade indicada para atuação em procedimentos médicos estéticos, cirúrgicos, oncológicos.',
    'A Gastroenterologia é a especialidade médica que se ocupa do estudo, diagnóstico e tratamento clínico das doenças do aparelho digestivo. O tratamento cirúrgico de tais patologias é abordado pela Cirurgia do Aparelho Digestivo.',
    'O ginecologista é o médico responsável pelo cuidado e estudo do aparelho reprodutor feminino. Assim, cuidando da saúde da mulher como um todo, mas com maior foco em seu aparelho reprodutor. Ele pode acompanhar a gestação, cuidando da saúde tanto da mãe, quanto do bebê.',
    'A mastologia ou senologia é especialidade médica que se dedica ao estudo das glândulas mamárias. O mastologista é o especialista que estuda, previne, diagnostica, trata e reabilita todas as doenças da mama.',
    'A oftalmologia é uma especialidade da medicina que estuda e trata as doenças relacionadas ao olho, à refração e aos olhos e seus anexos. O médico oftalmologista realiza cirurgias, prescreve tratamentos e correções para os distúrbios de visão.',
    'A ortopedia é a especialidade médica que cuida da saúde relacionadas aos elementos do aparelho locomotor, como ossos, músculos, ligamentos e articulações. A traumatologia é a especialidade médica que lida com o trauma do aparelho músculo-esquelético.',
    'A otorrinolaringologia é uma especialidade médica com características clínicas e cirúrgicas. Seu campo de atuação envolve as doenças do ouvido, do nariz e seios paranasais, faringe, laringe, cabeça, pescoço, boca e esôfago',
    'A pediatria é a especialidade médica dedicada à assistência à criança e ao adolescente, nos seus diversos aspectos, sejam eles preventivos ou curativos. Pessoas como crianças e adolescentes, se estiverem com doenças, devem dirigir-se ao seu pediatra.',
    'Coloproctologia é uma especialidade médica cirúrgica, é o estudo das doenças do intestino grosso, do reto e ânus. Compreende também uma série de procedimentos de diagnóstico, como a colonoscopia, a manometria e a ultrassonografia endorretal.',
    'Urologia é uma especialidade cirúrgica da medicina que trata do trato urinário de homens e de mulheres e do sistema reprodutor das pessoas do sexo masculino. Os médicos que possuem especialização nesta área são os urologistas, sendo treinados para diagnosticar, tratar e acompanhar pacientes com distúrbios urológicos.',
    'Cardiologia é a especialidade médica que se ocupa do diagnóstico e tratamento das doenças que acometem o coração bem como os outros componentes do sistema circulatório. O médico especialista nessa área é o cardiologista.',
    'Endocrinologia é uma especialidade médica que estuda as ordens do sistema endócrino e suas secreções específicas, chamadas de secreções fisiológicas.',
    'Infectologia ou infecciologia é a especialidade médica que se ocupa do estudo das doenças causadas por diversos patógenos como príons, vírus, bactérias, protozoários, fungos e animais. A infectologia também é chamada de "doenças infecto-parasitárias" ou "moléstias infecciosas e parasitárias".',
    'Reumatologia é a área do conhecimento médico que se ocupa do estudo das doenças que acometem os tecidos conjuntivos, que incluem as articulações, os ossos, os músculos, os tendões e os ligamentos. Estas patologias são muitas vezes designadas de doenças reumáticas.',
    'Hematologia é o ramo da biologia e especialidade clínica que estuda o sangue dos demais animais com sistema circulatório fechado. A palavra é composta pelos radicais gregos: Haima, "sangue" e lógos, "estudo, tratado, discurso". ',
    'Nefrologia é a especialidade médica que se ocupa do diagnóstico e tratamento clínico das doenças do sistema urinário, em especial o rim.',
    'Nutrologia é a especialidade médica clínica que se dedica ao diagnóstico, prevenção e tratamento de doenças do comportamento alimentar. Os médicos nutrólogos não devem ser confundidos com nutricionistas. O Nutrólogo é formado em Medicina e com pós-graduação ou residência na área de nutrologia.',
    'Pneumologia é a especialidade médica a qual é responsável pelo tratamento das patologias das vias aéreas inferiores. O profissional habilitado para atuar nessa especialidade é denominado pneumologista.',
    'geriatria é o ramo da medicina que foca o estudo, a prevenção e o tratamento de doenças e da incapacidade em idades avançadas. O termo deve ser distinto de gerontologia, que é o estudo do envelhecimento em si.',
    'Homeopatia é uma forma de terapia alternativa pseudocientífica, iniciada pelo alemão Samuel Hahnemann em 1796. Baseia-se no princípio similia similibus curantur, ou seja, o suposto tratamento se dá a partir da diluição e dinamização da mesma substância que produz o sintoma num indivíduo saudável.',
    'Neurologia é a especialidade médica que trata dos distúrbios estruturais do sistema nervoso. Especificamente, ela lida com o diagnóstico e tratamento de todas as categorias de doenças que envolvem os sistemas nervoso central, periférico e autônomo, parassimpático e simpático incluindo os seus revestimentos, vasos sanguíneos, e todos os tecidos efetores, como os músculos. O correspondente cirúrgico da especialidade é a neurocirurgia.',
    'Psiquiatria é uma das especialidades da Medicina, e basicamente é responsável pelo diagnóstico e tratamento dos chamados Transtornos Mentais e de Comportamento, atuando com a prevenção, o diagnóstico, o tratamento e a reabilitação dos diferentes modos de manifestações das doenças mentais em geral.',
    'Fisioterapia é uma ciência da saúde aplicada ao estudo, diagnóstico, prevenção e tratamento de disfunções cinéticas funcionais de órgãos e sistemas. Ela estuda, diagnostica, previne e trata os distúrbios, entre outros, cinético-funcionais decorrentes de alterações de órgãos e sistemas humanos.',
    'A Acupuntura é uma terapia milenar de origem chinesa, que consiste na aplicação de agulhas bem finas, em pontos específicos do corpo, para melhorar a imunidade e ajudar no tratamento de problemas emocionais e, até, de algumas doenças físicas como sinusite, asma, enxaqueca ou artrite.',
    'Psicologia é a ciência que estuda o comportamento humano e seus processos mentais. Melhor dizendo, a Psicologia estuda o que motiva o comportamento humano – o que o sustenta, o que o finaliza e seus processos mentais, que passam pela sensação, emoção, percepção, aprendizagem, inteligência...',
    'O Nutricionista é o profissional de saúde capacitado para atuar na prevenção, promoção e recuperação da saúde humana, planejando, executando e avaliando ações baseadas nos conhecimentos da ciência da nutrição e alimentação.',
];

export const roundNumber = (expression) => Math.round(expression * 1e1) / 1e1;

export const capitalizeWords = (text) =>
    text
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

const WhatsappURL = 'https://wa.me';

export const whatsappMessage = (number, message) => {
    const formatedNumber = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
    return `${WhatsappURL}/${formatedNumber}?text=${encodeURI(message)}`;
};

export const openWhatsApp = (number, message) => {
    const formatedNumber = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
    const url = `${WhatsappURL}/${formatedNumber}?text=${encodeURI(message)}`;

    return window.open(url, '_blank');
};

export const removeCountryCode = (number) => {
    if (number.includes('+55')) return number.slice(3);
    if (number.includes('55')) return number.slice(2);
    return number;
};

export const groupBy = (array, key) =>
    array.reduce((result, current) => {
        // eslint-disable-next-line no-param-reassign
        result[current[key]] = [...(result[current[key]] || []), current];
        return result;
    });

export const range = (start, stop, step) => {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
};

import { useState } from 'react';

export default () => {
    const [isOpen, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState();

    const handleModal = (content = false) => {
        setOpen(!isOpen);
        if (content) {
            setModalContent(content);
        }
    };

    return {
        isOpen,
        setOpen,
        handleModal,
        modalContent,
    };
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';
import CreditCardNumberInput from './CreditCardNumberInput';
import { Section, Icon, FormInput, Button, FormError } from '../../ui';
import { Companies, CompanySelect } from '../../CreditCard';
import { ModalContext } from '../../../contexts/ModalContext';
import { GlobalContext } from '../../../contexts/GlobalContext';

const IconWrapper = styled.div`
    @media (max-width: 350px) {
        display: none;
    }
`;

const trimRegexp = /[^0-9]/g;

const CreditCardNewCardForm = ({ onSubmit }) => {
    const { setLoading } = useContext(GlobalContext);

    const { handleSubmit, register, errors, control } = useForm({
        mode: 'onTouched',
        defaultValues: {
            holder_name: '',
            card_number: '',
            card_company: '',
            card_expiration: '',
            card_cvv: '',
        },
    });

    const interceptSubmit = async (data) => {
        setLoading(true)
        await onSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(interceptSubmit)}>
            <Section>
                <FormInput
                    label="Nome impresso no cartão"
                    htmlFor="holder_name"
                    haveInput
                    invalid={errors.holder_name}
                >
                    <IconWrapper>
                        <Icon name="profile" className="mr-4" size={24} />
                    </IconWrapper>
                    <input
                        type="text"
                        id="holder_name"
                        name="holder_name"
                        className="field-input __nome_cartao"
                        autoComplete="off"
                        placeholder="p. ex: Ítalo C Silva"
                        ref={register({ required: true })}
                    />
                </FormInput>
                {errors.holder_name && <FormError>Obrigatório</FormError>}
                <FormInput
                    label="Número do cartão de crédito"
                    htmlFor="card_number"
                    haveInput
                    invalid={errors.card_number}
                >
                    <IconWrapper>
                        <Icon name="credit-card" className="mr-4" size={24} />
                    </IconWrapper>
                    <Controller
                        id="card_number"
                        name="card_number"
                        placeholder="p. ex: •••• •••• •••• ••••"
                        as={CreditCardNumberInput}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: true,
                            validate: {
                                atLeastFourteen: (value = '') =>
                                    value.replace(trimRegexp, '').length >= 14,
                            },
                        }}
                    />
                </FormInput>
                {errors.card_number?.type === 'required' && (
                    <FormError>Obrigatório</FormError>
                )}
                {errors.card_number?.type !== 'required' &&
                    errors.card_number?.type === 'atLeastFourteen' && (
                        <FormError>
                            O cartão precisa ter no mínimo 15 números
                        </FormError>
                    )}
                <FormInput
                    label="Bandeira"
                    htmlFor="card_company"
                    haveInput
                    wrapperStyle={{ padding: '0' }}
                    invalid={errors.card_company}
                >
                    <Controller
                        id="card_company"
                        name="card_company"
                        placeholder="Selecione um..."
                        as={CompanySelect}
                        control={control}
                        options={Companies.filter((f) => f.active)}
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                    />
                </FormInput>
                {errors.card_company?.type === 'required' && (
                    <FormError>Obrigatório</FormError>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-2">
                    <div>
                        <FormInput
                            label="Validade"
                            htmlFor="card_expiration"
                            haveInput
                            invalid={errors.card_expiration}
                        >
                            <IconWrapper>
                                <Icon
                                    name="calendar"
                                    className="mr-4"
                                    size={24}
                                />
                            </IconWrapper>
                            <InputMask
                                type="tel"
                                id="card_expiration"
                                name="card_expiration"
                                className="field-input __validade_cartao"
                                placeholder="p. ex: 12/30"
                                mask="99/99"
                                inputRef={register({
                                    required: true,
                                    validate: {
                                        atLeastFour: (value = '') =>
                                            value.replace(trimRegexp, '')
                                                .length >= 4,
                                    },
                                })}
                            />
                        </FormInput>
                        {errors.card_expiration?.type === 'required' && (
                            <FormError>Obrigatório</FormError>
                        )}
                        {errors.card_expiration?.type !== 'required' &&
                            errors.card_expiration?.type === 'atLeastFour' && (
                                <FormError>
                                    A data de expiração precisa ter no mínimo 4
                                    números
                                </FormError>
                            )}
                    </div>
                    <div>
                        <FormInput
                            label="CVV"
                            htmlFor="card_cvv"
                            haveInput
                            invalid={errors.card_cvv}
                        >
                            <IconWrapper>
                                <Icon
                                    name="check_circle"
                                    className="mr-4"
                                    size={24}
                                />
                            </IconWrapper>
                            <input
                                maxLength={4}
                                type="tel"
                                id="card_cvv"
                                name="card_cvv"
                                className="field-input __cvv_cartao"
                                placeholder="p. ex: •••"
                                ref={register({
                                    required: true,
                                    validate: {
                                        atLeastThree: (value = '') =>
                                            value.replace(trimRegexp, '')
                                                .length >= 3,
                                    },
                                })}
                            />
                        </FormInput>
                        {errors.card_cvv?.type === 'required' && (
                            <FormError>Obrigatório</FormError>
                        )}
                        {errors.card_cvv?.type !== 'required' &&
                            errors.card_cvv?.type === 'atLeastThree' && (
                                <FormError>
                                    O CVV precisa ter no mínimo 3 números
                                </FormError>
                            )}
                    </div>
                </div>
            </Section>

            <Section className="rounded-b">
                <Button
                    style={{
                        borderRadius: '5px 5px 5px 5px',
                        width: '100%',
                    }}
                >
                    Cadastrar novo cartão
                </Button>
            </Section>
        </form>
    );
};

export default CreditCardNewCardForm;

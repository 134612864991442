import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Component from '../../components/Home/FirstSteps';
import { APP_URLS, BACKEND_URLS } from '../../urls';

import messages from './messages';
import { useModalContext } from '../../contexts/ModalContext';
import { NotificationPrompt } from '../../components/Notification';

const FirstSteps = ({ firstStepsStatus }) => {
    const { handleModal, setCloseButton } = useModalContext();

    // Active Item on screen
    const [active, setActive] = useState();
    const [index, setIndex] = useState(0);

    // Directionals
    const [first, setFirst] = useState(false);
    const [last, setLast] = useState(false);

    // General states
    const [percentage, setPercentage] = useState(0);
    const [stepDone, setStepDone] = useState(false);

    const history = useHistory();

    const checkDone = (name) => {
        switch (firstStepsStatus[name]) {
            case true:
                return setStepDone(true);
            case 'finished':
                return setStepDone(true);
            case 'installed':
                return setStepDone(true);
            default:
        }
    };

    const getIndex = (name) => {
        setIndex(messages.findIndex((item) => item.name === name));
    };

    useEffect(() => {
        const firstFalseEntry = Object.values(firstStepsStatus).findIndex(
            (item) => item !== 'finished' && item !== true,
        );

        getIndex(messages[firstFalseEntry].name);
        setActive(messages[firstFalseEntry]);
        checkDone(messages[firstFalseEntry].name);
    }, []);

    useEffect(() => {
        if (index === 0) {
            setPercentage(33);
            setLast(false);
            return setFirst(true);
        }

        if (index === 1) {
            setPercentage(66);
            setLast(false);
            return setFirst(false);
        }

        setPercentage(100);
        setFirst(false);
        setLast(true);
    }, [index]);

    const getPrevious = () => {
        const newActive = messages[index - 1];
        if (!newActive) return;
        setStepDone(false);
        getIndex(newActive.name);
        setActive(newActive);
        checkDone(newActive.name);
    };

    const getNext = () => {
        const newActive = messages[index + 1];
        if (!newActive) return;
        setStepDone(false);
        getIndex(newActive.name);
        setActive(newActive);
        checkDone(newActive.name);
    };

    const handleOnboarding = () => history.push(APP_URLS.ONBOARDING);

    const handleKarmen = () => window.open(BACKEND_URLS.CHAT, '_self');

    const handlePush = () => handleModal(<NotificationPrompt />);

    const handleAction = (name) => {
        switch (name) {
            case 'onboarding':
                return handleOnboarding();
            case 'karmen':
                return handleKarmen();
            case 'push': {
                setCloseButton(false);
                return handlePush();
            }
            default:
        }
    };

    return (
        <Component
            item={index + 1}
            max={messages.length}
            active={active}
            first={first}
            last={last}
            getPrevious={getPrevious}
            getNext={getNext}
            percentage={percentage}
            buttonAction={handleAction}
            stepDone={stepDone}
        />
    );
};

export default FirstSteps;

import styled from 'styled-components';

const InputStyle = styled.input`
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: textfield;
    &:required {
        box-shadow: none;
    }
`;

export default InputStyle;

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AnimatedDiv } from '../../../ui';
import Subscription from '../Subscription';
import BillingHistory from '../BillingHistory';
import PaymentMethod from '../PaymentMethod';
import MessagePrompt from '../../MessagePrompt';

import { GlobalContext } from '../../../../contexts/GlobalContext';
import { ModalContext } from '../../../../contexts/ModalContext';

import { Parent, Item } from './Animate';
import Skeleton from './Skeleton';
import { getUserBillsById } from '../../../../services/vindi';

const DetailsView = ({ userSubscriptions, browserHistory }) => {
    const { setLoading } = useContext(GlobalContext);
    const { handleModal, setCloseButton } = useContext(ModalContext);

    const [bills, setBills] = useState();
    const [offset, setOffset] = useState(2);
    const [maxOffset, setMaxOffset] = useState(0);

    const { id } = useParams();

    const loadUserBills = async (offsets) => {
        setLoading(true);
        setCloseButton(false);

        try {
            const res = await getUserBillsById(id, offsets);
            setMaxOffset(res.number_of_bills);
            if (res.bills.length === 1) setOffset(1);
            setBills(res.bills);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro durante a sua requisição."
                    errorAction={() => window.location.reload()}
                />,
            );
        }
    };

    useEffect(() => {
        loadUserBills();
    }, []);

    return bills && userSubscriptions ? (
        <>
            <AnimatedDiv
                layout
                variants={Parent}
                initial="hidden"
                animate="show"
            >
                <AnimatedDiv layout variants={Item}>
                    <Subscription
                        details={userSubscriptions.find(
                            (item) => item.id === Number(id),
                        )}
                        className="py-4"
                    />
                </AnimatedDiv>

                <AnimatedDiv layout variants={Item}>
                    <PaymentMethod
                        browserHistory={browserHistory}
                        paymentInfo={userSubscriptions.find(
                            (item) => item.id === Number(id),
                        )}
                    />
                </AnimatedDiv>

                <AnimatedDiv layout variants={Item}>
                    <BillingHistory
                        userBillHistory={bills}
                        maxOffset={maxOffset}
                        offset={offset}
                        setOffset={setOffset}
                        loadUserBills={loadUserBills}
                    />
                </AnimatedDiv>
            </AnimatedDiv>
        </>
    ) : (
        <Skeleton />
    );
};

export default DetailsView;

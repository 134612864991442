import React, { useContext, useEffect, useState } from 'react';

import { AnimatedDiv, Button, FormInput, Section } from '../../ui';
import { CompanySelect } from '../../CreditCard';

import { APP_URLS } from '../../../urls';
import { formatValue } from '../BookingUtils';
import { getPaymentProfiles, bookAppointment } from '../../../services/booking';

import { ModalContext } from '../../../contexts/ModalContext';
import { GlobalContext } from '../../../contexts/GlobalContext';
import MessagePrompt from '../../Profile/MessagePrompt';
import { Parent } from './Animate';
import { getParameterByName } from '../../../utils';
import CreditCardPayment from '../CreditCardPayment';
import {
    getUserSubscriptions,
    addNewCreditCard,
} from '../../../services/vindi';

const SelectPayment = ({
    browserHistory,
    bookingOptions,
    priceToPay,
    addOptions,
    isFree,
    user: { onboarding_done, isStaff },
}) => {
    const { handleModal, setCloseButton, isOpen } = useContext(ModalContext);
    const { setLoading } = useContext(GlobalContext);

    const [creditCard, setCreditCard] = useState('');

    const [userPaymentMethod, setUserPaymentMethod] = useState(null);

    const selectCreditCard = (e) => {
        if (e.value === 'new') {
            handleModal(<CreditCardPayment onSubmit={submitPaymentProfile} />);
        } else {
            setCreditCard(e);
        }
    };

    const addNewCreditCardOption = (res) => {
        res.push({
            id: 0,
            label: 'Adicionar cartão',
            value: 'new',
        });
    };

    const loadUserPaymentMethods = async () => {
        setLoading(true);
        setCloseButton(false);

        try {
            const params = {};
            const res = await getPaymentProfiles(params);
            addNewCreditCardOption(res);
            setUserPaymentMethod(res);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro durante a sua requisição."
                    errorAction={() => window.location.reload()}
                />,
            );
            throw new Error(error);
        }
    };

    useEffect(() => {
        if (!bookingOptions) return browserHistory.replace(APP_URLS.BOOKING);
        loadUserPaymentMethods();
    }, []);

    const finishAppointmentBooking = async (method, data) => {
        setLoading(true);
        setCloseButton(false);

        try {
            const res = await bookAppointment(data);
            addOptions({
                status: 'requested',
                bookedResponse: res,
                paymentMethod: method,
            });
            setLoading(false);
            browserHistory.replace(APP_URLS.BOOKING_CONFIRM);
        } catch (error) {
            setLoading(false);
            addOptions({ status: 'error' });
            if (error.response.status === 422) {
                handleModal(
                    <MessagePrompt
                        error
                        message="Ops, parece que você já tem uma consulta agendada para esse dia e horário."
                        errorAction={() => null}
                    />,
                );
                throw new Error(error);
            }
            browserHistory.replace(APP_URLS.BOOKING_CONFIRM);
            throw new Error(error);
        }
    };

    const selectPaymentMethod = async (method) => {
        const defaultParams = {
            specialty_id: bookingOptions.specialty.id,
            consultation_id: bookingOptions.selectedOption.id,
            service_type:
                bookingOptions.type === 'telemedicina'
                    ? 'telemedicine'
                    : 'presential',
        };

        const extraParams =
            isStaff === 'True' && getParameterByName('patient_id')
                ? {
                      patient_id: getParameterByName('patient_id'),
                      ...defaultParams,
                  }
                : {
                      ...defaultParams,
                  };

        switch (method) {
            case 'credit_card': {
                await finishAppointmentBooking('credit_card', {
                    payment_method_code: 'credit_card',
                    payment_profile_id: creditCard.id,
                    ...extraParams,
                });
                break;
            }
            case 'bank_slip': {
                if (onboarding_done === 'None') {
                    if (isStaff === 'False') {
                        return handleModal(
                            <MessagePrompt
                                error
                                errorButton="Ir agora"
                                message="Ops, parece que você não completou suas informações cadastrais. Por favor, para prosseguir clique no botão abaixo e complete!"
                                errorAction={() =>
                                    browserHistory.push(APP_URLS.ONBOARDING)
                                }
                            />,
                        );
                    }
                }
                await finishAppointmentBooking('bank_slip', {
                    payment_method_code: 'bank_slip_yapay',
                    ...extraParams,
                });
                break;
            }
            case 'cash': {
                await finishAppointmentBooking('cash', {
                    payment_method_code: 'cash',
                    ...extraParams,
                });
                break;
            }
            default:
        }
    };

    const submitPaymentProfile = async (data) => {
        try {
            const { card_company, card_expiration, card_number } = data;
            const [month, year] = card_expiration.split('/');

            const vindiData = {
                ...data,
                card_number: card_number.replace(/\s/g, ''),
                card_expiration: `${month}/20${year}`,
                payment_method_code: 'credit_card',
                payment_company_code: card_company.value,
            };
            const res = await addNewCreditCard(vindiData);
            handleModal();
            if (!res.ok) {
                setLoading(false);

                handleModal(
                    <MessagePrompt
                        error
                        message="Ocorreu um erro durante a validação de seu cartão de crédito. Verifique as informações e tente novamente."
                    />,
                );
            } else {
                setLoading(false);
                handleModal(
                    <MessagePrompt
                        message="Método de pagamento atualizado!"
                        completeAction={() => handleModal()}
                    />,
                );
            }
        } catch (error) {
            setLoading(false);
            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro ao cadastrar seu cartão de crédito. Verifique as informações e tente novamente."
                />,
            );
            throw new Error(error);
        }
        loadUserPaymentMethods();
    };

    return (
        <AnimatedDiv
            variants={Parent}
            initial="initial"
            animate="in"
            exit="exit"
            className="container py-8 max-w-xl mx-auto"
        >
            <h1 className="text-2xl text-pink-600 font-medium text-center">
                Pagamento
            </h1>
            <div className="my-4 px-4">
                <div className="flex justify-between">
                    <span className="font-medium">Subtotal</span>
                    <span>{formatValue(priceToPay)}</span>
                </div>
                <div className="mt-2 flex justify-between">
                    <span className="font-medium">Desconto</span>
                    <span>R$ 0,00</span>
                </div>
                <div className="mt-2 font-medium flex justify-between">
                    <span>Total</span>
                    <span>{formatValue(priceToPay)}</span>
                </div>
            </div>

            <hr className="bg-gray-100 my-4" />

            <Section
                className="flex flex-col rounded assina-shadow-sm px-6 pb-0"
                as="div"
            >
                <h1 className="text-2xl text-gray-800 font-medium text-center">
                    Pague com seu cartão de crédito
                </h1>
                <h2 className="text-lg text-green-700 text-center mt-2">
                    Mais segurança e comodidade para você.
                </h2>
                <FormInput
                    haveInput
                    notAnimated
                    htmlFor="card_number"
                    primary
                    wrapperStyle={{ padding: '0', margin: '26px 0' }}
                >
                    <CompanySelect
                        placeholder="Selecione um cartão"
                        value={creditCard}
                        options={userPaymentMethod || []}
                        onChange={(e) => selectCreditCard(e)}
                    />
                </FormInput>
                <Button
                    disabled={creditCard === ''}
                    style={{ borderRadius: '0 0 5px 5px', margin: '0 -24px' }}
                    onClick={() => selectPaymentMethod('credit_card')}
                    id="pagar-cartao"
                >
                    Pagar com cartão
                </Button>
            </Section>

            <Section className="flex flex-col rounded assina-shadow-sm mt-6">
                <h2 className="text-xl text-gray-900 font-medium text-center">
                    ou selecione outra forma de pagamento:
                </h2>
                <Button
                    className="my-4"
                    id="pagar-boleto"
                    onClick={() => selectPaymentMethod('bank_slip')}
                >
                    Boleto bancário
                </Button>
                {bookingOptions && bookingOptions.type === 'presencial' && (
                    <Button onClick={() => selectPaymentMethod('cash')}>
                        Pagar na unidade
                    </Button>
                )}
            </Section>
        </AnimatedDiv>
    );
};

export default SelectPayment;

import React, { useContext, useState } from 'react';
import { ModalContext } from '../../../contexts/ModalContext';

import { AnimatedDiv, Calendar, Icon } from '../../ui';
import { Parent } from './Animate';
import './styles.css';

const BookingCalendar = ({ bookData, minDate, changeDate }) => {
    const { handleModal } = useContext(ModalContext);
    const [date, setDate] = useState(bookData);

    const onClick = () => {
        changeDate(date);
        handleModal();
    };

    return (
        <AnimatedDiv
            variants={Parent}
            initial="initial"
            animate="in"
            exit="exit"
            className="my-4 shadow-md text-center"
        >
            <div className="bg-teal-600 rounded-t-md py-4">
                <h2 className="text-xl text-white font-medium tracking-wider">
                    Selecione o dia
                </h2>
            </div>
            <div className="px-2 py-2 bg-white">
                <Calendar
                    date={date}
                    minDate={minDate}
                    onChangeDate={setDate}
                    minDetail="month"
                    navigationLabel={({ label }) => {
                        const splited = label.split(' ')[0];
                        const formated =
                            splited.charAt(0).toUpperCase() + splited.slice(1);
                        return `${formated}`;
                    }}
                    tileClassName="tile"
                    prevLabel={
                        <Icon
                            name="directional_left"
                            size={20}
                            className="fill-current text-pink-600 mx-auto"
                        />
                    }
                    prev2Label={null}
                    nextLabel={
                        <Icon
                            name="directional_right"
                            size={20}
                            className="fill-current text-pink-600 mx-auto"
                        />
                    }
                    next2Label={null}
                />
            </div>
            <button
                type="button"
                onClick={onClick}
                className="h-12 cursor-pointer text-lg text-center flex items-center justify-center font-medium tracking-widest focus:outline-none bg-pink-600 hover:bg-pink-700 text-white w-full rounded-b-lg"
            >
                Selecionar
            </button>
        </AnimatedDiv>
    );
};

export default BookingCalendar;

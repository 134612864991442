/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

import Icon from '../../../../assets/svg/dropdown.svg';

const SelectContainer = styled.div`
    max-width: 300px;
`;

const SelectOutside = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5 15px;

    border-bottom: 3px solid ${(props) => props.theme.colors.teal3};
`;

const SelectInput = styled.input`
    background: none;
    width: 100%;
    padding: 4px 0;

    font-weight: 300;
    font-size: 25px;
    fill: ${(props) => props.theme.colors.gray1};

    ::placeholder {
        fill: ${(props) => props.theme.colors.gray3};
    }

    &:focus {
        outline: none;
    }
`;

const DropdownIcon = styled(Icon)`
    zoom: 1.3;
    fill: ${(props) => props.theme.colors.primary};

    cursor: pointer;
`;

const Dropdown = styled.div`
    display: ${(props) => (props.openDropdown ? 'inline' : 'none')};

    &::after {
        content: '';
        position: relative;
        top: -12px;
        left: 43%;
        width: 0;
        height: 0;
        border: 21px solid transparent;
        border-bottom-color: rgba(210, 14, 80, 0.92);
        border-top: 0;
    }
`;

const DropdownInner = styled.div`
    padding: 25px 0;
    margin: 30px 0 0;

    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    max-height: 200px;
    max-width: 300px;
    width: 100%;
    overflow-y: hidden;
    background: rgba(210, 14, 80, 0.92);
    border-radius: 20px;

    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1);

    z-index: 10;
`;

const Scrollbar = styled(Scrollbars)`
    div {
        display: flex;
        flex-direction: column;
    }
`;

const DropdownItem = styled.span`
    font-size: 20px;
    color: ${(props) => props.theme.colors.gray6};

    padding: 8px 25px;

    cursor: pointer;

    &:hover {
        background: ${(props) => props.theme.colors.primaryPink4};
    }
`;

export default ({ options, selectOption, inputRef, focused }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [results, setResults] = useState(options);

    const filter = (e) => {
        setOpenDropdown(true);

        const filtered = options.filter((item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()),
        );

        setInputValue(e.target.value);
        setResults(filtered);
    };

    useEffect(() => {
        if (focused) {
            setOpenDropdown(true);
        }
    }, [focused]);

    return (
        <SelectContainer>
            <SelectOutside>
                <SelectInput
                    type="text"
                    placeholder="Escreva aqui"
                    value={inputValue}
                    onChange={(e) => filter(e)}
                    ref={inputRef}
                />
                <DropdownIcon onClick={() => setOpenDropdown(!openDropdown)} />
            </SelectOutside>
            <Dropdown openDropdown={openDropdown}>
                <DropdownInner>
                    <Scrollbar>
                        {results.map((item, index) => (
                            <DropdownItem
                                key={`${item.type} - ${index}`}
                                onClick={() => {
                                    selectOption(item.name);
                                    setInputValue(item.name);
                                    setOpenDropdown(false);
                                }}
                            >
                                {item.name}
                            </DropdownItem>
                        ))}
                    </Scrollbar>
                </DropdownInner>
            </Dropdown>
        </SelectContainer>
    );
};

/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import PropTypes from 'prop-types';

import DocTemplate from './DocTemplate';

// eslint-disable-next-line react/prefer-stateless-function
class ExamRequest extends React.Component {
    render() {
        const { doctor, date, patient, indication, exams } = this.props;

        return (
            <DocTemplate doctor={doctor} date={date}>
                <div className="doc-table">
                    <div>
                        <h4>Solicito a: {patient.name}</h4>
                        <h4>Indicação clínica: {indication || '-'}</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th className="exams">
                                    Exames / Procedimentos
                                </th>
                                <th>Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exams.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.value}</td>
                                    <td>{item.quantidade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </DocTemplate>
        );
    }
}

ExamRequest.propTypes = {
    doctor: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
        mobilePhone: PropTypes.string,
    }),
    date: PropTypes.string,
    patient: PropTypes.shape({
        name: PropTypes.string,
    }),
    indication: PropTypes.string,
    exams: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            quantidade: PropTypes.number,
        }),
    ),
};

ExamRequest.defaultProps = {
    doctor: {},
    date: '',
    patient: {},
    indication: '',
    exams: [],
};

export default ExamRequest;

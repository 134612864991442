/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';

import LocationPage from '../../components/Location/Location';
import { getLocationByGeolocation } from '../../services/api';

let map = null;

const LocationPageContainer = ({
    querybyUser,
    addressByUser,
    setResponseAddress,
    setResponseQuery,
    zoom = 17,
    visible,
    tryUserLocation,
    mapLatLng,
    splitedData,
}) => {
    const mapRef = useRef(null);
    const [latlng, setLatlng] = useState(
        mapLatLng || { lat: -13.6632305, lng: -69.6410913 },
    );

    const textSearch = (service, queryString) => {
        const request = {
            query: queryString,
            location: latlng,
            radius: '500',
        };

        service.textSearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                setResponseQuery(results);
            }
        });
    };

    const findPlaceFromQuery = (service, queryString) => {
        const requestPlace = {
            query: queryString,
            fields: ['name', 'geometry'],
        };

        service.findPlaceFromQuery(requestPlace, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                setLatlng({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                });
            }
        });
    };

    const geolocationToLocation = async (latLng) => {
        let formatedData = {
            route: '',
            street_number: '',
            political: '',
            administrative_area_level_2: {},
            administrative_area_level_1: {},
            country: {},
            postal_code: '',
        };
        try {
            const data = await getLocationByGeolocation(latLng);

            if (!data.data.results[0]) return;

            data.data.results[0].address_components.forEach((elm) => {
                formatedData = {
                    ...formatedData,
                    [elm.types[0]]:
                        elm.types[0] === 'administrative_area_level_1' ||
                        elm.types[0] === 'administrative_area_level_2' ||
                        elm.types[0] === 'country'
                            ? elm
                            : elm.long_name,
                };
            });

            setResponseAddress({
                ...splitedData,
                formatedData,
                latlng: data.data.results[0].geometry.location,
            });
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(() => {
        if (tryUserLocation) {
            setLatlng(mapLatLng);
            geolocationToLocation(`${mapLatLng.lat},${mapLatLng.lng}`);
        }
    }, []);

    useEffect(() => {
        setResponseAddress({ ...splitedData, latlng });
    }, [latlng]);

    useEffect(() => {
        const service = new google.maps.places.PlacesService(map);
        if (querybyUser) {
            textSearch(service, querybyUser);
        }
        if (addressByUser) {
            findPlaceFromQuery(service, addressByUser);
        }
    }, [querybyUser, addressByUser]);

    useEffect(() => {
        if (mapRef) {
            map = new google.maps.Map(mapRef.current, {
                center: latlng,
                zoom,
                disableDefaultUI: true,
                gestureHandling: 'greedy',
            });
            const marker = new google.maps.Marker({
                position: map.getCenter(),
                map,
            });
            map.addListener('dragend', async () => {
                const currentPositon = map.getCenter();
                marker.setPosition(currentPositon);
                setLatlng({
                    lat: currentPositon.lat(),
                    lng: currentPositon.lng(),
                });
            });
        }
    }, [mapRef, latlng]);

    return <LocationPage mapRef={mapRef} visible={visible} />;
};

export default LocationPageContainer;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../ui';

export default () => {
    const history = useHistory();

    return (
        <div className="text-gray-600 font-medium rounded my-4 flex items-center sm:ml-10 ml-4">
            <Icon
                name="arrow-left"
                size={25}
                className="cursor-pointer stroke-current text-gray-600 hover:text-gray-800"
                style={{ strokeWidth: '3px' }}
                onClick={() => history.goBack()}
            />
        </div>
    );
};

import React from 'react';
import { motion } from 'framer-motion';

const AnimatedDiv = ({ className, children, ...rest }) => (
    <motion.div className={className} {...rest}>
        {children}
    </motion.div>
);

export default AnimatedDiv;

/* eslint-disable no-alert */
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Component from '../../components/InvitePage/InvitePage';
import { GlobalContext } from '../../contexts/GlobalContext';
import { APP_URLS } from '../../urls';
import { copyTextToClipboard, openWhatsApp } from '../../utils';
import { SUPPORT_WHATSAPP } from '../../constants';
import { ModalContext } from '../../contexts/ModalContext';
import SendInvitePrompt from '../../components/InvitePage/SendInvitePrompt/SendInvitePrompt';

import ToastStyles from '../../utils/ToastStyles';

const InvitePage = () => {
    const history = useHistory();
    const { user } = useContext(GlobalContext);
    const { handleModal, setCloseButton } = useContext(ModalContext);

    const [referees, setReferees] = useState([]);
    const link = `https://kompa.com.br/convite?ref=${user.email}&ref_name=${
        user.name.split(' ')[0]
    }`;
    const [isClaimModalVisible, setClaimModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(
                    `${process.env.BROKER_URL}/referees?email=${user.email}`,
                );
                setReferees(response.data.data);
            } catch (err) {
                console.error(err);
                alert(
                    'Ocorreu um erro desconhecido ao carregar suas indicações. Retornando ao menu principal.',
                );
                history.replace(APP_URLS.HOME);
            }
            setLoading(false);
        })();
    }, []);

    const onCopy = () => {
        copyTextToClipboard(link);
        toast('Link copiado com sucesso!', ToastStyles);
    };

    const onClaim = async () => {
        setLoading(true);
        try {
            await axios.post(`${process.env.BROKER_URL}/referees/redeem`, {
                email: user.email,
            });
            setClaimModalVisible(true);
        } catch (err) {
            console.error(err);
            alert(
                'Ocorreu um erro ao solicitar o resgate. Por favor, entre em contato o atendimento.',
            );
        }
        setLoading(false);
    };

    const balance = referees.map((r) => r.value).reduce((v, acc) => v + acc, 0);

    const openSupportWhatsApp = () =>
        openWhatsApp(
            SUPPORT_WHATSAPP,
            'Olá, estou com dúvidas em como usar o meu link de convite.',
        );

    const openPrompt = () => {
        setCloseButton(false);
        handleModal(<SendInvitePrompt link={link} onCopy={onCopy} />);
    };

    return (
        <>
            <ToastContainer />
            <Component
                referees={referees}
                link={link}
                onCopy={onCopy}
                balance={balance}
                onClaim={onClaim}
                isClaimModalVisible={isClaimModalVisible}
                setClaimModalVisible={setClaimModalVisible}
                isDescriptionExpanded={isDescriptionExpanded}
                setDescriptionExpanded={setDescriptionExpanded}
                loading={loading}
                openSupportWhatsApp={openSupportWhatsApp}
                openPrompt={openPrompt}
            />
        </>
    );
};

export default InvitePage;

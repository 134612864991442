/* eslint-disable no-underscore-dangle */

import { postQuestionrio } from '../services/api';
import { APP_URLS } from '../urls';

/* eslint no-alert: "error" */

export const getSessionItem = (name) => sessionStorage.getItem(name);

export const setSessionItem = (name, value) =>
    sessionStorage.setItem(name, value);

export const getStorageItem = (name) => localStorage.getItem(name);

export const setStorageItem = (name, value) =>
    localStorage.setItem(name, value);

const returnParseLocalItem = (item) => {
    return JSON.parse(localStorage.getItem(item));
};

export const setItemLocal = (item, data) => {
    return localStorage.setItem(item, JSON.stringify(data));
};

const excludeItemLocal = (item) => {
    return localStorage.removeItem(item);
};

const verifiedInputLocalStorage = (question) => {
    const parseQuestionario = returnParseLocalItem('questionario');
    if (parseQuestionario) {
        for (const i in parseQuestionario.respostas) {
            if (
                parseQuestionario.respostas[i].id === question.id &&
                parseQuestionario.respostas[i].resposta
            ) {
                return true;
            }
        }
        return false;
    }
};

const newModifiedQuiz = ({ parseQuestionario, question, resposta }) => {
    if (!parseQuestionario) {
        return false;
    }
    for (const i in parseQuestionario.respostas) {
        if (parseQuestionario.respostas[i].id === question.id) {
            const newAnswer = {
                resposta,
                id: question.id,
                pergunta: question.title,
            };
            const obj = { index: i, newAnswer };
            return obj;
        }
    }
    return false;
};

const newQuiz = ({ parseQuestionario, question, user, resposta }) => {
    if (!parseQuestionario) {
        return {
            cpf: user.cpf,
            nome: user.name,
            respostas: [
                {
                    id: question.id,
                    pergunta: question.title,
                    resposta,
                },
            ],
        };
    }
    const newAnswer = parseQuestionario.respostas;
    newAnswer.push({
        id: question.id,
        pergunta: question.title,
        resposta,
    });
    return {
        ...parseQuestionario,
        respostas: newAnswer,
    };
};

const saveOnlocalStorage = async ({
    value,
    question,
    setLastMessage,
    history,
    setPromiseLoading,
    genericError,
    promiseLoading,
    pushNextQuestion,
}) => {
    const parseQuestionario = returnParseLocalItem('questionario');
    const user = window.__USER;
    const resposta = question.textarea ? value[question.name] : value;
    const newAnswer = newModifiedQuiz({
        parseQuestionario,
        question,
        resposta,
    });
    let newQuestionnaire;
    if (newAnswer) {
        parseQuestionario.respostas[newAnswer.index] = newAnswer.newAnswer;
        newQuestionnaire = parseQuestionario;
    } else {
        newQuestionnaire = newQuiz({
            parseQuestionario,
            question,
            user,
            resposta,
        });
    }
    if (question.lastQuestion) {
        newQuestionnaire.finished = true;
    }
    setItemLocal('questionario', newQuestionnaire);
    await handlePostQuestionario({
        setLastMessage,
        history,
        setPromiseLoading,
        genericError,
        promiseLoading,
        question,
        pushNextQuestion,
    });
};

const handleLocalStoragePost = ({
    setLastMessage,
    setPromiseLoading,
    history,
    question,
    data,
}) => {
    if (question.lastQuestion) {
        setLastMessage(true);
        localStorage.removeItem('questionario');
        localStorage.setItem('questionarioAnswered', true);
        return history.replace(APP_URLS.QUESTIONNAIRE_FINISHED);
    }
    if (data.id) {
        let questionario = returnParseLocalItem('questionario');
        questionario = { ...questionario, quizId: data.id };
        setItemLocal('questionario', questionario);
    }
    setPromiseLoading(false);
};

const handlePostQuestionario = async ({
    setLastMessage,
    history,
    setPromiseLoading,
    genericError,
    promiseLoading,
    question,
    pushNextQuestion,
}) => {
    const questionario = localStorage.getItem('questionario');
    const parsedData = JSON.parse(questionario);
    if (!promiseLoading) {
        setPromiseLoading(true);
        setTimeout(async () => {
            await postQuestionrio(parsedData)
                .then((data) => {
                    handleLocalStoragePost({
                        data: data.data,
                        setLastMessage,
                        history,
                        setPromiseLoading,
                        question,
                    });
                    if (pushNextQuestion) {
                        pushNextQuestion();
                    }
                })
                .catch((error) => {
                    setPromiseLoading(false);
                    console.error(error);
                    return genericError(error);
                });
        }, 200);
    }
};

const localStorageBackQuestion = (id) => {
    const parsedData = returnParseLocalItem('questionario');
    const arrayFilter = parsedData.respostas.filter((obj) => {
        return obj.id !== id;
    });
    const ultimo = arrayFilter[arrayFilter.length - 1];
    const newArray = arrayFilter.slice(0, -1);
    parsedData.respostas = newArray;
    setItemLocal('questionario', parsedData);
    return ultimo.id;
};

const handleStartQuestionario = (history) => {
    const parsedData = returnParseLocalItem('questionario');
    if (parsedData) {
        const ultimo = parsedData.respostas[parsedData.respostas.length - 1];
        return ultimo
            ? history.push(`/questionario/${ultimo.id}`)
            : history.push('/questionario/1');
    }
    history.push('/questionario/1');
};

export {
    saveOnlocalStorage,
    handlePostQuestionario,
    localStorageBackQuestion,
    handleStartQuestionario,
    verifiedInputLocalStorage,
    returnParseLocalItem,
    excludeItemLocal,
};

import React from 'react';
import styled from 'styled-components';

import Icon from '../Icon';

const FABButton = styled.a`
    position: fixed;

    bottom: 130px;
    right: 24px;

    z-index: 20;

    @media (max-width: ${({ theme: { sizes } }) => sizes.sm}) {
        bottom: 24px;
        left: 24px;
    }
`;

export default function FAB({ href, ...rest }) {
    return (
        <FABButton href={href} {...rest}>
            <Icon name="whatsapp-filled" size={50} />
        </FABButton>
    );
}

import React from 'react';

import Button from '../../../ui/Button';

function HeartSvg({ size, ...rest }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            height={size}
            width={size}
            style={{ minWidth: size, minHeight: size }}
            viewBox="0 0 40 32"
            {...rest}
        >
            <path
                fill="current-color"
                fillRule="evenodd"
                d="M12.014 28.294c2.177 2.11 4.822 3.18 7.869 3.047 2.678.1 5.123-.636 7.131-2.41 3.683-3.248 7.266-6.73 10.38-10.547 3.582-4.387 3.047-11.016-1.005-15.034C32.271-.7 25.642-1.07 21.222 2.68c-1.038.871-1.64.77-2.645-.033C14.961-.266 10.843-.802 6.691 1.174-.441 4.589-2.215 13.227 3.04 19.12c2.846 3.18 5.893 6.194 8.974 9.174zm7.433-17.779c-.737 2.11-1.473 4.22-2.31 6.596-.126-.31-.234-.622-.34-.926-.43-1.241-.814-2.347-2.104-2.59-2.177-.434-4.387-.3-6.563-.1-.77.067-1.138.603-1.138 1.373.033.77.468 1.239 1.205 1.373.428.08.878.075 1.332.07l.342-.003c.37 0 .754-.033 1.137-.066.902-.076 1.798-.153 2.48.2.718.382.894 1.246 1.074 2.127.088.433.177.869.331 1.254.402 1.038.87 1.976 2.177 2.01 1.406.033 1.875-.972 2.243-2.11.502-1.507 1.004-3.013 1.54-4.487.077-.221.143-.45.21-.68.195-.676.396-1.374.895-1.998l.138.276c.314.622.595 1.181.8 1.766.602 1.741 1.807 2.511 3.616 2.444 1.005-.022 1.996-.014 2.982-.007.491.004.98.007 1.47.007.972-.033 1.91-.167 1.91-1.44-.034-1.238-.871-1.472-1.91-1.472-.428.013-.856.005-1.285-.003-.643-.012-1.286-.024-1.929.036-1.406.134-2.276-.234-2.678-1.708-.335-1.138-.904-2.276-1.473-3.348-.804-1.506-2.746-1.473-3.583 0-.207.385-.362.822-.51 1.243l-.06.163z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default function DedicatedDoctorCard({ handleDedicatedDoctor }) {
    return (
        <div className="bg-white rounded-md assina-shadow-sm p-4 mb-4">
            <div className="flex space-x-4 sm:items-center">
                <HeartSvg size="45" className="fill-current text-pink-600" />
                <div className="w-full text-gray-900">
                    <h1 className="text-xl font-medium leading-snug">
                        Agende uma consulta com seu Médico Dedicado!
                    </h1>
                    <p className="text-lg font-base mt-1 leading-snug">
                        É grátis e pode solucionar a maioria dos casos.
                    </p>
                </div>
            </div>
            <Button
                className="w-full mt-4"
                onClick={handleDedicatedDoctor}
                id="doutor-dedicado"
            >
                Agendar
            </Button>
        </div>
    );
}

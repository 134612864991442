/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Modal_ from 'react-modal';

const customStyles = {
    content: {
        zIndex: '3',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        inset: '50% auto auto 50%',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255) none repeat scroll 0% 0%',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'currentcolor none medium',
    },
    overlay: {
        zIndex: 2000,
    },
};

const Modal = ({
    isOpen,
    onRequestClose,
    contentLabel,
    className,
    children,
}) => {
    return (
        <Modal_
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={contentLabel}
            style={customStyles}
            className={className}
        >
            {children}
        </Modal_>
    );
};

export default Modal;

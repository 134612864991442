import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Component from '../../components/Prescriptions/Prescriptions';
import { GlobalContext } from '../../contexts/GlobalContext';
import { fetchApi } from '../../services/api';

const Prescriptions = () => {
    const history = useHistory();
    const { user } = useContext(GlobalContext);
    const [prescriptions, setPrescriptions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showPrescription, setShowPrescription] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);

    const getPrescriptions = async (params = '') => {
        setLoading(true);

        setTimeout(async () => {
            try {
                const res = await fetchApi(
                    `/api/v1/user/${user.id}/prescriptions${params}`,
                );
                setPrescriptions(res.data);
                setTotalPages(res.total_pages);
                setLoading(false);
            } catch (error) {
                setPrescriptions(null);
                setLoading(false);
                throw new Error(error);
            }
        }, 1000);
    };

    useEffect(() => {
        getPrescriptions(`?page=${page}`);
    }, [page]);

    return (
        <Component
            history={history}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            prescriptions={prescriptions}
            loading={loading}
            showPrescription={showPrescription}
            setShowPrescription={setShowPrescription}
        />
    );
};

export default Prescriptions;

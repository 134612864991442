import React, { useEffect } from 'react';
import cn from 'classnames';
import { usePostHogContext } from 'posthog-js/react';

import Icon from '../../ui/Icon';
import { Section } from '../../ui';

const ErrorToast = ({
    error,
    message = 'Um erro ocorreu ao receber seu arquivo.',
}) => {
    const posthogCtx = usePostHogContext();

    useEffect(() => {
        if (process.env.APP_ENV === 'production') {
            posthogCtx.client.capture('[EXAMES-ANEXOS] erro ao adicionar');
        }
    }, []);
    return (
        <Section
            className={cn(
                'flex flex-row border-2 border-red-700',
                'rounded bg-red-500',
                'assina-shadow-sm',
                error ? 'block' : 'hidden',
            )}
            as="div"
        >
            <div className="ml-3 mt-3">
                <Icon name="close-square" size={26} />
            </div>
            <div className="text-lg text-white font-normal m-3 w-full">
                {message}
                <br />
                <span className="font-medium">Tente novamente</span>
            </div>
        </Section>
    );
};

export default ErrorToast;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BACKGROUNDS } from '../../../urls';
import { SearchSelect } from '../../ui';

import Details from '../SubjectDetails/DetailsViewsDesktop';

const DesktopContainer = styled.div`
    background-image: url(${BACKGROUNDS.BG_MONITORING});
    background-size: cover;
    background-color: ${(props) => props.theme.colors.white1};
`;

const Menu = styled.div`
    background-image: url(${BACKGROUNDS.BG_GUY_STANDING});
    background-repeat: no-repeat;
    background-position: right bottom;
    max-width: 600px;
    background-size: 240px;
    height: 100%;
    min-height: calc(100vh - var(--height-header-footer));

    padding-left: 130px;

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export default ({
    subject,
    setSubject,
    measureTypes,
    width,
    subjectData,
    addHistory,
    inputRef,
    hasNoRecords,
    loading,
}) => {
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setFocused(true);
        inputRef.current.focus();
    }, []);

    return (
        <DesktopContainer>
            {subject ? (
                <Details
                    measureTypes={measureTypes}
                    resetSubject={setSubject}
                    subject={subject}
                    width={width}
                    subjectData={subjectData}
                    addHistory={addHistory}
                    hasNoRecords={hasNoRecords}
                    loading={loading}
                />
            ) : (
                <Menu>
                    <h2 className="text-3xl font-light text-pink-800">
                        <span>Sua evolução de Saúde</span>
                    </h2>
                    <span className="text-4xl font-medium text-gray-900 leading-10 mb-6">
                        O que você quer acompanhar?
                    </span>

                    <SearchSelect
                        options={measureTypes}
                        selectOption={setSubject}
                        inputRef={inputRef}
                        focused={focused}
                    />
                </Menu>
            )}
        </DesktopContainer>
    );
};

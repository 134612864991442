import React from 'react';
import Icon from '../../ui/Icon';
import cn from 'classnames';

const imageFileExtensions = ['png', 'jpeg', 'jpg'];
const documentFileExtensions = ['pdf'];
const videoFileExtensions = ['mp4'];

const ListItemIcon = ({ f }) => {
    return (
        <div
            className={cn(
                'border rounded-full',
                'bg-white border-white',
                'flex justify-center items-center',
                'assina-shadow-sm mx-1',
            )}
            style={{
                width: 40,
                height: 40,
            }}
        >
            <Icon
                name={
                    f &&
                    imageFileExtensions.includes(
                        f.nome.toLowerCase().substr(-4).replace('.', ''),
                    )
                        ? 'image-pink'
                        : 'paper-pink'
                }
                height={26}
                width={22}
            />
        </div>
    );
};

export default ListItemIcon;

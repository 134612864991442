/* eslint-disable no-shadow */
import Axios from 'axios';
import { getToken } from '../commons/utils/utils';
import { backendServices, apiGoogle, apiTP, quizAPI } from './AxiosInstances';

export const getLocationByString = (query, language = 'pt-BR') => {
    return apiGoogle.get('place/textsearch/json', {
        params: {
            query,
            language,
            key: process.env.GOOGLE_API_KEY,
        },
    });
};

export const getLocationByGeolocation = (
    latlng,
    language = 'pt-BR',
    ROOFTOP = true,
) => {
    const params = ROOFTOP
        ? {
              latlng,
              location_type: 'ROOFTOP',
              result_type: 'street_address',
              language,
              key: process.env.GOOGLE_API_KEY,
          }
        : {
              latlng,
              result_type: 'street_address',
              language,
              key: process.env.GOOGLE_API_KEY,
          };
    return apiGoogle.get('geocode/json', {
        params,
    });
};

export const postQuestionrio = (parsedData) => {
    return quizAPI.post(`/respostas`, parsedData);
};

export const checkUserKarmenData = async (id) => {
    const url = `https://app.kompa.com.br/api/v1/karmen-data`;
    const options = {
        headers: {
            Authorization: `Token ${getToken()}`,
        },
    };

    const res = await Axios.get(url, options)
        .then(({ data }) => data)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

export const checkIsQuizAnswered = async (cpf) => {
    const res = await backendServices
        .get(`/adm/check_quiz/cpf/${cpf}`)
        .then(({ data }) => data)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

export const postUserFile = async (id, data, options) => {
    const res = await backendServices
        .post(`/prontuario-v1/${id}/anexos`, data, options)
        .then(({ data }) => data.data)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

export const getUserFiles = async (id) => {
    const res = await backendServices
        .get(`/prontuario-v1/${id}/anexos`)
        .then(({ data }) => data.data)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

export const fetchApi = async (url, method, data, api = backendServices) => {
    let res;
    switch (method) {
        case 'post':
            res = await api.post(url, data);
            break;
        case 'put':
            res = await api.put(url, data);
            break;
        case 'delete':
            res = await api.delete(url, data);
            break;
        default: {
            res = await api.get(url);
        }
    }
    return res.data;
};

export const setFileObservations = async (id, data) => {
    const res = await backendServices
        .put(`/prontuario-v1/${id}/anexos`, data)
        .then(({ data }) => data.data)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

export const changeTemporaryPassword = async (data) => {
    await apiTP.post('/change_password', data);
};

export const postOnboardingData = async (data) => {
    await backendServices.post('/adm/user_onboarding', data);
};

export const getOcupations = async (value) => {
    const res = await backendServices
        .get(`/cad/profissoes?q=${value}`)
        .then(({ data }) => data.data)
        .catch((error) => {
            throw new Error(error);
        });
    return res;
};

export const disableUserFile = async (id, data) => {
    await backendServices
        .post(`/prontuario-v1/${id}/delete_anexo`, data)
        .catch((error) => {
            throw new Error(error);
        });
};

export const getUserSpecificHistory = async (id, subject) => {
    const res = await backendServices
        .get(`/prontuario-v1/${id}/historico/${subject}`)
        .then(({ data }) => data.data)
        .catch((error) => {
            throw new Error(error);
        });

    return res;
};

export const postSpecificHistory = async (id, data) => {
    await backendServices
        .post(`/prontuario-v1/${id}/historico`, data)
        .catch((error) => {
            throw new Error(error);
        });
};

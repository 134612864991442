import styled from 'styled-components';

const ShineStyles = styled.div`
    border-radius: ${(props) =>
        props.rouded ? '50%' : props.round ? props.round : ''};
    width: 100%;
    height: 100%;

    background: #d1d1d130;
    background-image: linear-gradient(
        to right,
        #00000000 0%,
        #c6c7ca50 30%,
        #e0e1e250 40%,
        #00000000 100%
    );
    background-size: 500px;
    background-repeat: no-repeat;
    position: relative;

    animation: shine 1s linear infinite forwards;

    @keyframes shine {
        0% {
            background-position-x: -500px;
        }

        100% {
            background-position-x: 700px;
        }
    }
`;

export default ShineStyles;

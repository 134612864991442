import styled from 'styled-components';

import { BACKGROUNDS } from '../../../../urls';

export const MenuHeader = styled.div`
    background-image: url(${BACKGROUNDS.BG_HEADER});
    background-position: right;
    background-repeat: no-repeat;

    height: 113px;
`;

export const Container = styled.div``;

import React from 'react';
import Select, { components } from 'react-select';

import CardFlag from '../CardFlag';

const { Option, SingleValue } = components;

const CustomStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
        border: 0,
        boxShadow: 'none',
        padding: '5px',
    }),

    placeholder: (styles) => {
        return {
            ...styles,
            fill: '#a4a4a4',
            fontSize: '18px',
        };
    },
};

const customSelectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary25: '#F2B7CB',
        primary: '#D20E50',
    },
});

const CustomDisplayDiv = ({ value, label, size }) => (
    <div className="flex items-center text-lg font-gray-900 font-medium">
        <CardFlag name={value} size={size} />
        <span className="pl-4">{label}</span>
    </div>
);

const CustomOption = ({ data: { label, value }, ...rest }) => (
    <Option {...rest}>
        <CustomDisplayDiv label={label} value={value} size={40} />
    </Option>
);

const CustomSingleValue = ({ data: { label, value }, ...rest }) => (
    <SingleValue {...rest}>
        <CustomDisplayDiv label={label} value={value} size={40} />
    </SingleValue>
);

const customNoOptionsMessage = () => (
    <div className="text-center font-gray-900 font-medium py-4 text-lg">
        <span>Nenhuma opção encontrada</span>
    </div>
);

const CompanySelect = ({ options, ...rest }) => {
    return (
        <Select
            options={options}
            components={{
                Option: CustomOption,
                SingleValue: CustomSingleValue,
                IndicatorSeparator: () => null,
                NoOptionsMessage: customNoOptionsMessage,
            }}
            className="w-full"
            isSearchable={false}
            styles={CustomStyles}
            theme={customSelectTheme}
            {...rest}
        />
    );
};

export default CompanySelect;

import { BACKGROUNDS } from '../../urls';

const { default: styled } = require('styled-components');

export const Background = styled.div`
    background-color: white;
    background-image: url(${BACKGROUNDS.APP_PASSWORD_DESKTOP});
    background-size: cover;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 180px);
    position: relative;
    overflow: hidden;
    padding: 0rem 0 0.5rem 17rem;

    @media (max-width: 750px) {
        padding: 0rem 0 0.5rem 3rem;
    }

    @media (max-width: 425px) {
        background-image: url(${BACKGROUNDS.APP_PASSWORD_MOBILE});
    }
`;

export const Title = styled.h1`
    font-size: ${(props) => (props.fontSize ? props.fontSize : '40px')};
    font-weight: normal;
    font-style: normal;
    margin-top: 1rem;
    color: #540620;
    @media (max-width: 750px) {
        font-size: ${(props) => (props.fontSize ? props.fontSize : '35px')};
    }
`;

export const SpanRed = styled.span`
    color: #d20e50;
`;

export const WrapperError = styled.div`
    background-color: white;
    background-image: url(${BACKGROUNDS.APP_PASSWORD_DESKTOP});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 180px);
    padding: 40px 15px;
    width: 100%;

    @media (max-width: 425px) {
        background-image: url(${BACKGROUNDS.APP_PASSWORD_MOBILE});
    }
`;

export const Content = styled.div`
    width: 24rem;
    @media (max-width: 750px) {
        width: auto;
    }
`;

export const H1Error = styled.h1`
    font-weight: 600;
    font-size: 89px;
    color: #540620;
    @media (max-width: 750px) {
        font-size: 34px;
        margin-top: 2rem;
    }
`;

export const Pcontent = styled.p`
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 1.5rem;
    color: #540620;
    @media (max-width: 750px) {
        font-size: 18px;
        line-height: 22px;
    }
`;

export const SvgBackgroundWomen = styled.svg`
    background-image: url(${BACKGROUNDS.SvgWomen});
    background-size: cover;
    position: absolute;
    bottom: 0rem;
    right: 12rem;
    width: 10rem;
    height: 20rem;
    @media (max-width: 900px) {
        right: 8rem;
    }
    @media (max-width: 834px) {
        right: 3rem;
        height: 18rem;
    }
    @media (max-width: 750px) {
        right: 2;
        height: 15rem;
        display: ${(props) => (props.changing ? 'none' : 'block')};
    }
    @media (max-width: 400px) {
        right: 0;
        height: 12rem;
        width: 6rem;
    }
`;

export const SvgBackgroundHappy = styled.svg`
    background-image: url(${BACKGROUNDS.SvgHappy});
    background-size: cover;
    position: absolute;
    bottom: 0rem;
    right: 18rem;
    width: 15rem;
    height: 20rem;
    @media (max-width: 750px) {
        right: 2;
        height: 15rem;
    }
    @media (max-width: 425px) {
        right: 0;
        height: 10rem;
        width: 10rem;
    }
`;

export const SvgIconInputPassword = styled.svg`
    background-image: ${(props) =>
        props.red
            ? `url(${BACKGROUNDS.SvgInputPasswordRed})`
            : `url(${BACKGROUNDS.SvgInputPassword})`};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
`;

export const SvgErrorRobot = styled.svg`
    background-image: url(${BACKGROUNDS.SvgErrorRobot});
    background-size: cover;
    width: 7rem;
    height: 7rem;
    @media (max-width: 750px) {
        width: 5rem;
        height: 5rem;
    }
`;

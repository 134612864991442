import React, { useContext } from 'react';
import styled from 'styled-components';
import { usePostHogContext } from 'posthog-js/react';

import { isProduction } from '../../../constants';
import { ModalContext } from '../../../contexts/ModalContext';
import { BACKGROUNDS } from '../../../urls';
import { Button, Icon } from '../../ui';

const Container = styled.div`
    width: 100%;

    max-width: 500px;
    height: 400px;

    color: ${(props) => props.theme.colors.primaryPink1};

    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        background-image: url(${BACKGROUNDS.MESSAGE_PROMPT_MOBILE});
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: 224px;
    }
`;

const MessagePrompt = ({
    error,
    errorButton,
    completeAction,
    errorAction,
    message,
}) => {
    const posthogCtx = usePostHogContext();

    const { handleModal } = useContext(ModalContext);

    const onErrorAction = () => {
        if (errorAction) {
            errorAction();
        }
        handleModal();
    };

    const onCompleteAction = () => {
        if (completeAction) {
            completeAction();
        }
        handleModal();
    };

    if (error) {
        if (isProduction) {
            posthogCtx.client.capture('[MODAL] erro');
        }
    }

    return (
        <Container>
            <div className="bg-white w-full h-full flex flex-col justify-center items-center rounded-md">
                {error ? (
                    <Icon name="shield_error" size={70} />
                ) : (
                    <Icon name="shield_done" size={70} />
                )}

                <h1 className="px-4 mt-10 text-center font-medium text-2xl text-pink-600">
                    {message}
                </h1>

                {error && (
                    <h2 className="px-4 mt-6 text-center font-medium text-xl text-pink-700">
                        Caso o erro persista entre em contato com nosso suporte.
                    </h2>
                )}

                <Button
                    className="shadow-md mt-6 px-10"
                    onClick={() =>
                        error ? onErrorAction() : onCompleteAction()
                    }
                >
                    {error
                        ? errorButton
                            ? `${errorButton}`
                            : 'Tentar novamente'
                        : 'Continuar'}
                </Button>
            </div>
        </Container>
    );
};

export default MessagePrompt;

import React, { useContext } from 'react';

import {
    InputPassword,
    PasswordFieldValidation,
    CheckTextChangePassword,
} from '../../Password';
import { Button } from '../../ui';
import { Content, SpanRed, SvgBackgroundWomen, Title } from '../Styles';
import { ContextTemporaryPassword } from '../../../containers/TemporaryPassword/TemporaryPassword';

const ChangingPassword = () => {
    const {
        firstPassword,
        secondPassword,
        onChangeSecondInt,
        disabledButton,
        onChangeFirstInput,
        submitPassword,
        promiseLoading,
        strength,
        conditionsValue,
        passwordEquals,
        refFirst,
        refSecond,
    } = useContext(ContextTemporaryPassword);
    return (
        <Content>
            <SvgBackgroundWomen changing />
            <Title>
                Crie sua <SpanRed>senha</SpanRed> de acesso:
            </Title>
            <form onSubmit={submitPassword}>
                <PasswordFieldValidation
                    label="Sua senha"
                    placeholder="ex: **********"
                    onChange={onChangeFirstInput}
                    value={firstPassword}
                    strength={strength}
                    useRef={refFirst}
                />
                <InputPassword
                    label="Repita sua senha"
                    placeholder="ex: **********"
                    onChange={onChangeSecondInt}
                    value={secondPassword}
                    useRef={refSecond}
                />
                <CheckTextChangePassword
                    className="mt-4"
                    valid={conditionsValue.length}
                    label="Ter no mínimo 08 caracteres"
                />
                <CheckTextChangePassword
                    valid={conditionsValue.cpf}
                    label="Não deve ser igual ao CPF"
                />
                <CheckTextChangePassword
                    valid={conditionsValue.strength}
                    label="Não seja comum"
                />
                <CheckTextChangePassword
                    valid={passwordEquals}
                    label="As duas senhas devem ser iguais"
                />
                <div className="w-1/2 pr-2">
                    <Button
                        disabled={disabledButton}
                        className="px-8 mt-4 flex items-center w-3/4 flex-shrink-0"
                        loading={promiseLoading}
                    >
                        continuar
                    </Button>
                </div>
            </form>
        </Content>
    );
};

export default ChangingPassword;

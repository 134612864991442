import React, { useContext } from 'react';
import styled from 'styled-components';

import { Button, Check, Section } from '../../../ui';

import MariaAlice from '../../../../assets/illustrations/maria-alice.svg';
import { PremiumCard } from '../../../Plans';

import { ModalContext } from '../../../../contexts/ModalContext';
import { GlobalContext } from '../../../../contexts/GlobalContext';

const AliceWrapper = styled.div`
    min-width: 80px;
    min-height: 79px;

    position: relative;

    svg {
        position: absolute;
        bottom: -49px;
    }
`;

const EmergencyCareView = () => {
    const { handleModal } = useContext(ModalContext);
    const { user } = useContext(GlobalContext);

    const openPlanModal = () => {
        handleModal(
            <div className="max-w-lg">
                <PremiumCard />
            </div>,
        );
    };

    return (
        <div className="w-full">
            <div className="text-center bg-pink-600 rounded-b-lg pt-4 pb-10 px-8 text-white assina-shadow-sm">
                <div className="max-w-xl mx-auto">
                    <h1 className="text-2xl font-medium">
                        Pronto atendimento digital Kompa Saúde
                    </h1>

                    <hr className="my-4 bg-gray-100 opacity-50" />

                    <h2 className="text-xl font-medium">
                        Fale agora com a equipe médica gratuitamente
                    </h2>
                    <p className="text-lg mt-4">
                        Nossos profissionais de saúde vão te ajudar a tratar o
                        seu problema. Toque no botão para iniciar a consulta
                    </p>

                    <a
                        href={`https://atendimentos.kompa.com.br/?internalId=${user.id}&name=${user.name}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button
                            color="white"
                            className="w-full max-w-sm mx-auto my-4"
                        >
                            Entrar
                        </Button>
                    </a>
                </div>
            </div>

            <div className="px-8 mx-auto mb-8 max-w-xl">
                <Section className="rounded-md relative bg-white assina-shadow-sm -mt-8 px-8 py-6">
                    <h2 className="font-medium text-xl text-center">
                        No seu Plano Gratuito você tem:
                    </h2>
                    <div className="text-gray-800">
                        <Check>
                            Apoio Agendamento de consultas por telemedicina
                        </Check>
                        <Check>Agendamento de consultas presenciais</Check>
                        <Check>Receitas médicas online</Check>
                        <Check>Pedidos de exames online</Check>
                        <Check>Historico de saúde online</Check>
                        <Check>Alerta de medicamentos</Check>
                    </div>
                </Section>

                <div className="flex justify-between mt-4 flex-shrink-0">
                    <h1 className="my-6 text-xl font-medium text-pink-900">
                        Com um plano Completo, você tem acesso a:
                    </h1>
                    <AliceWrapper>
                        <MariaAlice />
                    </AliceWrapper>
                </div>

                <Section className="rounded-md bg-pink-600 assina-shadow-sm px-8 py-6 text-white">
                    <h2 className="font-medium text-xl text-center">
                        Todos os benefícios do Plano <br /> Gratuito e mais:
                    </h2>
                    <div>
                        <Check colorSvg="white">
                            Apoio médico online 24h por dia
                        </Check>
                        <Check colorSvg="white">
                            Manutenção odontológica gratuita
                        </Check>
                        <Check colorSvg="white">
                            Consultas médicas a partir de R$ 19,00
                        </Check>
                        <Check colorSvg="white">
                            Desconto de até 60% em exames e cirurgias
                        </Check>
                        <Check colorSvg="white">
                            Desconto de até 50% nas principais farmácias
                        </Check>
                    </div>

                    <Button
                        color="white"
                        className="w-full max-w-sm mx-auto mt-6"
                        onClick={openPlanModal}
                    >
                        Assinar
                    </Button>
                </Section>
            </div>
        </div>
    );
};

export default EmergencyCareView;

import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import zxcvbn from 'zxcvbn';

import {
    ChangingPassword,
    PasswordFinished,
    PasswordWelcome,
    Container,
} from '../../components/TemporaryPassword';
import { GlobalContext } from '../../contexts/GlobalContext';
import { changeTemporaryPassword } from '../../services/api';
import { APP_URLS } from '../../urls';

export const ContextTemporaryPassword = createContext({
    history: () => null,
    onChangeInput: () => null,
    firstPassword: '',
    setSecondPassword: () => null,
    disabledButton: true,
    setDisabledButton: () => null,
    onChangeFirstInput: () => null,
    onChangeSecondInt: () => null,
    submitPassword: () => null,
    strength: null,
    promiseLoading: false,
    conditionsValue: {},
    passwordEquals: false,
    setFirstPassword: () => null,
    setPromiseLoading: () => null,
    setPasswordEquals: () => null,
    setStrength: () => null,
    setConditionsValue: () => null,
    secondPassword: '',
    refFirst: null,
    refSecond: null,
});

const TemporaryPassword = () => {
    const history = useHistory();

    const refFirst = useRef();
    const refSecond = useRef();

    const { user } = useContext(GlobalContext);

    const [firstPassword, setFirstPassword] = useState('');
    const [secondPassword, setSecondPassword] = useState('');
    const [disabledButton, setDisabledButton] = useState(true);
    const [promiseLoading, setPromiseLoading] = useState(false);
    const [passwordEquals, setPasswordEquals] = useState(false);
    const [strength, setStrength] = useState(0);
    const [conditionsValue, setConditionsValue] = useState({});

    const checkPasswords = (valueInputChanging, otherValueInput, object) => {
        const ArrayCondition = Object.values(object).filter((elm) => {
            return elm === false;
        });
        const isValid =
            valueInputChanging && otherValueInput
                ? ArrayCondition.length === 0 &&
                  valueInputChanging === otherValueInput
                : false;
        setDisabledButton(!isValid);
        setPasswordEquals(valueInputChanging === otherValueInput);
    };

    const onChangeFirstInput = (e) => {
        const { value } = e.target;
        const validation = {
            cpf: value !== user.cpf,
            length: value.length >= 8,
            strength: zxcvbn(value).score >= 2 && value.length >= 8,
        };
        setFirstPassword(value);
        setStrength(zxcvbn(value).score);
        setConditionsValue(validation);
        checkPasswords(value, refSecond.current.value, validation);
    };

    const onChangeSecondInt = (e) => {
        const { value } = e.target;
        const validation = {
            cpf: value !== user.cpf,
            length: value.length >= 8,
            strength: zxcvbn(value).score >= 2 && value.length >= 8,
        };
        setSecondPassword(value);
        checkPasswords(value, refFirst.current.value, validation);
    };

    const submitPassword = async (e) => {
        e.preventDefault();
        const password = e.target[1].value;
        const data = {
            id: user.id,
            password,
        };
        setPromiseLoading(true);
        setTimeout(async () => {
            try {
                await changeTemporaryPassword(data);
                history.replace(APP_URLS.CHANGE_PASSWORD_FINISHED);
                setPromiseLoading(false);
            } catch (err) {
                setFirstPassword('');
                setSecondPassword('');
                setDisabledButton(true);
                setPromiseLoading(false);
                setPasswordEquals(false);
                setStrength(0);
                setConditionsValue({});
                setPromiseLoading(false);
                history.push(APP_URLS.REQUEST_ERROR);
                throw new Error(err);
            }
        }, 1000);
    };

    useEffect(() => {
        if (!user.senha_temporaria) history.replace(APP_URLS.HOME);
    }, []);

    const providerValue = {
        history,
        disabledButton,
        firstPassword,
        onChangeFirstInput,
        onChangeSecondInt,
        submitPassword,
        strength,
        promiseLoading,
        conditionsValue,
        passwordEquals,
        setDisabledButton,
        setFirstPassword,
        setSecondPassword,
        setPromiseLoading,
        setPasswordEquals,
        setStrength,
        setConditionsValue,
        secondPassword,
        refFirst,
        refSecond,
    };

    return (
        <ContextTemporaryPassword.Provider value={providerValue}>
            <Container>
                <Switch>
                    <>
                        <Route
                            exact
                            component={PasswordWelcome}
                            path={APP_URLS.CHANGE_PASSWORD_WELCOME}
                        />
                        <Route
                            component={ChangingPassword}
                            path={APP_URLS.CHANGE_PASSWORD_CREATE}
                        />
                        <Route
                            component={PasswordFinished}
                            path={APP_URLS.CHANGE_PASSWORD_FINISHED}
                        />
                    </>
                </Switch>
            </Container>
        </ContextTemporaryPassword.Provider>
    );
};

export default TemporaryPassword;

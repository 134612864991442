import React from 'react';
import styled, { css } from 'styled-components';

import { getType } from '../../../../containers/HealthMonitoring/HealthMonitoring';

export const ChartContainer = styled.div`
    background: ${(props) => props.theme.colors.gray6};

    border-radius: 30px;
    padding: 10px 15px;

    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.25);

    margin-top: 20px;

    height: 300px;
    width: 100%;

    @media (min-width: 1440px) {
        height: 400px;
    }
`;

export const MonitoringBg = styled.div`
    background: ${(props) => props.theme.colors.gray6};

    padding: 25px;

    border-radius: 30px;

    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.25);

    min-height: 170px;

    ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}

    width: 100%;
    max-width: 190px;

    transition: all 0.2s ease;

    ${(props) =>
        props.onClick
            ? css`
                  cursor: pointer;
              `
            : css`
                  pointer-events: none;
              `}

    ${(props) =>
        props.hover &&
        css`
            &:hover {
                transform: translateY(-4px);
            }
        `}

    @media (max-width: 425px) {
        max-width: 100%;
    }
`;

export default ({ subjectData, subject }) => {
    const last = subjectData.data[subjectData.data.length - 1];
    return (
        <MonitoringBg className="my-2">
            <div>
                <h1 className="text-3xl text-pink-600 font-semibold">{`${last.value
                    .toString()
                    .replace('.', ',')} ${getType(subject)}`}</h1>
                <span className="text-xl text-teal-600">{last.date}</span>
                <br />
                <span className="text-gray-700 text-lg leading-6 font-medium">
                    Atualizado por: <br /> {last.created_by}
                </span>
            </div>
        </MonitoringBg>
    );
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, ButtonGroup, FormInput, Spinner } from '../../ui';

import Select from './Select';

import { BiologicalSex, Children, Gender, MaritalStatus, YN } from './Enums';
import AddressPrompt from './AddressPrompt';

const Container = styled.div`
    display: flex;
    justify-content: center;
    max-width: 1000px;

    margin: 0 auto;

    min-height: calc(100vh - var(--height-header-footer));

    @media (max-width: 425px) {
        min-height: calc(100vh - 180px);

        h1 {
            max-width: 290px;
        }
    }
`;

const FormContainer = styled.div`
    ${({ theme }) => theme.mixins.flex_column}

    @media (min-width : 425px) {
        padding: 30px 90px 30px 32px;
    }

    padding: 65px 32px 140px;

    width: 100%;
    max-width: 660px;

    > button {
        max-width: 170px;
        ${({ theme }) => theme.mixins.shadow_simple}
    }
`;

const ContentWrapper = styled.div`
    > div + div {
        margin-top: 25px;
    }

    @media (min-width: 425px) {
        padding-right: 20px;
    }
`;

const Form = ({
    inputValues,
    changeValue,
    handleModal,
    history,
    handleForm,
    loading,
    OcupationsResult,
    SearchOcupations,
}) => (
    <>
        <Fields
            inputValues={inputValues}
            changeValue={changeValue}
            handleModal={handleModal}
            history={history}
            handleForm={handleForm}
            loading={loading}
            OcupationsResult={OcupationsResult}
            SearchOcupations={SearchOcupations}
        />
    </>
);

const Fields = ({
    inputValues,
    changeValue,
    handleModal,
    handleForm,
    loading,
}) => (
    <ContentWrapper>
        <FormInput label="Sexo">
            <ButtonGroup
                options={BiologicalSex}
                name="biologicalSex"
                onChange={changeValue}
            />
        </FormInput>
        <FormInput label="Gênero" hidden={Object.keys(inputValues).length < 1}>
            <Select
                options={Gender}
                placeholder="Selecione..."
                name="gender"
                onChange={({ value }) => changeValue('gender', value)}
            />
        </FormInput>
        <FormInput
            label="Estado Civil"
            hidden={Object.keys(inputValues).length < 2}
        >
            <Select
                options={MaritalStatus}
                placeholder="Selecione..."
                name="maritalStatus"
                onChange={({ value }) => changeValue('maritalStatus', value)}
            />
        </FormInput>
        <FormInput
            label="Tem filhos?"
            hidden={Object.keys(inputValues).length < 3}
        >
            <ButtonGroup
                options={Children}
                name="hasChildren"
                onChange={changeValue}
            />
        </FormInput>
        <FormInput
            label="Qual seu endereço?"
            haveInput
            hidden={Object.keys(inputValues).length < 4}
        >
            <input
                type="text"
                placeholder="Pesquise..."
                name="address"
                autoComplete="off"
                defaultValue={
                    inputValues.address ? inputValues.address.complete : ''
                }
                onClick={() =>
                    handleModal(<AddressPrompt changeValue={changeValue} />)
                }
            />
        </FormInput>
        <FormInput
            label="Tem plano de saúde?"
            hidden={Object.keys(inputValues).length < 5}
        >
            <ButtonGroup
                options={YN}
                name="hasHealthCarePlan"
                onChange={changeValue}
            />
        </FormInput>
        <FormInput>
            <Button
                className="text-2xl rounded-lg px-4 mt-10"
                color={
                    Object.keys(inputValues).length < 6 ? 'disabled' : 'pink'
                }
                loading={loading}
                onClick={handleForm}
            >
                Continuar
            </Button>
        </FormInput>
    </ContentWrapper>
);

const AboutYou = ({
    inputValues,
    changeValue,
    handleModal,
    history,
    handleForm,
    loading,
    OcupationsResult,
    SearchOcupations,
}) => {
    const [rendering, setRendering] = useState(true);

    useEffect(() => {
        sessionStorage.clear();
        setTimeout(() => {
            setRendering(false);
        }, 500);
    }, []);

    return !rendering ? (
        <Container>
            <FormContainer>
                <Form
                    inputValues={inputValues}
                    changeValue={changeValue}
                    handleModal={handleModal}
                    history={history}
                    handleForm={handleForm}
                    loading={loading}
                    OcupationsResult={OcupationsResult}
                    SearchOcupations={SearchOcupations}
                />
            </FormContainer>
        </Container>
    ) : (
        <div className="flex items-center justify-center my-8">
            <Spinner color="pink" size={100} />
        </div>
    );
};

export default AboutYou;

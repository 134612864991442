import React from 'react';
import styled, { keyframes } from 'styled-components';
import Gift from '../../../assets/svg/gift.svg';
import Shadow1 from '../../../assets/svg/shadow1.svg';
import Shadow2 from '../../../assets/svg/shadow2.svg';

const AnimatedGift = () => {
    return (
        <>
            <AnimatedShadow1 className="absolute right-0 top-0" />
            <AnimatedShadow2 className="absolute right-0 top-0" />
            <Gift className="absolute right-0 top-0" />
        </>
    );
};

export default AnimatedGift;

const spin = keyframes`
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
`;

const AnimatedShadow1 = styled(Shadow1)`
    animation: ${spin} 60s infinite linear reverse;
`;

const AnimatedShadow2 = styled(Shadow2)`
    animation: ${spin} 80s infinite linear reverse;
`;

/* eslint-disable import/no-named-as-default */
import React, { useEffect } from 'react';
import { HashRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { usePostHogContext } from 'posthog-js/react';

// Contexts
import GlobalContextProvider from '../../contexts/GlobalContext';
import { ModalProvider } from '../../contexts/ModalContext';
import { WindowProvider } from '../../contexts/WindowContext';
import { NotificationProvider } from '../../contexts/NotificationContext';

// Containers
import InvitePage from '../InvitePage/InvitePage';
import HealthQuestionnaire from '../HealthQuestionnaire/HealthQuestionnaire';
import ExamUpload from '../ExamUpload/ExamUpload';
import HealthMonitoring from '../HealthMonitoring/HealthMonitoring';
import TemporaryPassword from '../TemporaryPassword/TemporaryPassword';
import Onboarding from '../Onboarding/Onboarding';
import ExamPage from '../ExamPage/ExamPage';
import Profile from '../Profile';
import TelemedicineValues from '../TelemedicineValues';
import Booking from '../Booking';
import Services from '../Services/Services';

// Components
import { Home } from '../../components/Home';
import Call0800Page from '../../components/Call0800Page/Call0800Page';
import SupportPage from '../../components/SupportPage/SupportPage';
import {
    HealthQuestionnaireWelcome,
    HealthQuestionnaireFinished,
} from '../../components/HealthQuestionnaire';
import RequestError from '../../components/RequestError/RequestError';
import BackSeparator from '../../components/BackSeparator/BackSeparator';

// Utils
import theme from '../../styles/theme';
import { APP_URLS } from '../../urls';

// Helpers
import { ScrollToTop } from '../../helpers';
import Prescriptions from '../Prescriptions/Prescriptions';
import { isProduction } from '../../constants';

const AppRouter = () => {
    const location = useLocation();
    const posthogCtx = usePostHogContext()

  useEffect(() => {
    const page = location.pathname;
    if (isProduction) {
        posthogCtx.client.capture(`[NAV] ${page}`);
    }
  }, [location]);

    return (
        <ScrollToTop>
            <ThemeProvider theme={theme}>
                <ModalProvider>
                    <NotificationProvider>
                        <GlobalContextProvider>
                            <WindowProvider>
                                <Switch>
                                    <Route exact path={APP_URLS.HOME}>
                                        <Home />
                                    </Route>
                                    <Route
                                        exact
                                        path={APP_URLS.QUESTIONNAIRE_WELCOME}
                                    >
                                        <HealthQuestionnaireWelcome />
                                    </Route>
                                    <Route
                                        path={APP_URLS.QUESTIONNAIRE_QUESTIONS}
                                    >
                                        <HealthQuestionnaire />
                                    </Route>
                                    <Route
                                        path={APP_URLS.CHANGE_PASSWORD_WELCOME}
                                    >
                                        <TemporaryPassword />
                                    </Route>
                                    <Route
                                        exact
                                        path={APP_URLS.QUESTIONNAIRE_FINISHED}
                                    >
                                        <HealthQuestionnaireFinished />
                                    </Route>
                                    <Route path={APP_URLS.EXAM_UPLOAD}>
                                        <ExamUpload />
                                    </Route>
                                    <Route path={APP_URLS.HEALTH_MONITORING}>
                                        <HealthMonitoring />
                                    </Route>
                                    <Route path={APP_URLS.ONBOARDING}>
                                        <Onboarding />
                                    </Route>
                                    <Route path={APP_URLS.CALL_0800}>
                                        <Call0800Page />
                                    </Route>
                                    <Route path={APP_URLS.SUPPORT}>
                                        <SupportPage />
                                    </Route>
                                    <Route path={APP_URLS.INVITE_FRIENDS}>
                                        <InvitePage />
                                    </Route>
                                    <Route path={APP_URLS.REQUEST_ERROR}>
                                        <RequestError />
                                    </Route>
                                    <Route path={APP_URLS.EXAM_PAGE}>
                                        <ExamPage />
                                    </Route>
                                    <Route path={APP_URLS.PROFILE}>
                                        <Profile />
                                    </Route>
                                    <Route path={APP_URLS.BOOKING}>
                                        <Booking />
                                    </Route>
                                    <Route path={APP_URLS.TELEMEDICINE_VALUES}>
                                        <TelemedicineValues />
                                    </Route>
                                    <Route path={APP_URLS.SERVICES}>
                                        <Services />
                                    </Route>
                                    <Route path={APP_URLS.PRESCRIPTIONS}>
                                        <Prescriptions />
                                    </Route>
                                </Switch>
                            </WindowProvider>
                        </GlobalContextProvider>
                    </NotificationProvider>
                </ModalProvider>
            </ThemeProvider>
        </ScrollToTop>
    );
};

function App() {
    return (
        <Router>
            <AppRouter />
        </Router>
    );
}

export default App;

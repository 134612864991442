import React from "react";

function New({ size }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" width={size} height={size}>
        <path 
            id="lineX" 
            d="M100 50 h50 v50 h50 v50 h-50 v50 h-50 v-50 h-50 v-50 h50 z" 
            stroke="#d20e50" strokeWidth="3" fill="#d20e50" />
        
      </svg>
    );
}

export default New;

/* eslint-disable import/prefer-default-export */
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'date-fns/esm/locale/pt-BR';

export const formatToMinifiedMonth = (data) => {
    return moment(data, 'DD-MM-YYYY').locale('pt-br').format('DD/MMM');
};

export const defaultDateFormat = (date) => format(new Date(date), 'dd/MM/yyyy');

export const formatDate = (date, formatTo) => {
    const formated = format(date, formatTo, { locale });

    return formated
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .replace('De', 'de');
};

export const splitAndParseDate = (date) => {
    const splited = date.split('/');
    return parseISO(`${splited[2]}-${splited[1]}-${splited[0]}`);
};

/*
    Return difference between any Date and date now, switch/case for to choose type difference
    add types to cases when necessary
*/
export const getDiffDateToDateNow = (data, type) => {
    const now = moment();
    const duration = moment.duration(now.diff(moment(data)));
    switch (type) {
        default: {
            return duration.asMonths();
        }
    }
};

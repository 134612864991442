import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../ui';
import {
    WrapperError,
    H1Error,
    SpanRed,
    SvgErrorRobot,
    Title,
} from '../TemporaryPassword/Styles';

const RequestError = () => {
    const history = useHistory();

    return (
        <WrapperError>
            <div>
                <SvgErrorRobot />
                <H1Error>Algo deu errado...</H1Error>
                <Title fontSize="25px" className="font-normal mt-4">
                    Parece que tivemos um problema ao processar sua solicitação.{' '}
                    <br /> Por favor, <SpanRed>tente novamente</SpanRed>
                </Title>
                <div className="pr-2">
                    <Button
                        onClick={() => history.goBack()}
                        className="px-4 mt-8 flex items-center flex-shrink-0"
                    >
                        Tentar novamente
                    </Button>
                </div>
            </div>
        </WrapperError>
    );
};

export default RequestError;

import React, { useState } from 'react';
import InputMask from 'react-input-mask';

const DEFAULT_MASK = '9999 9999 9999 9999';
const AMEX_MASK = '9999 999999 99999';

const CreditCardNumberInput = ({
    value,
    onChange,
    onBlur,
    name = 'card_number',
    ...others
}) => {
    const [mask, setMask] = useState(DEFAULT_MASK);

    const handleChange = (evt) => {
        const { value } = evt.target;
        setMask(DEFAULT_MASK);
        if (/^3[47]/.test(value)) setMask(AMEX_MASK);
        onChange(evt);
    };

    return (
        <InputMask
            type="tel"
            mask={mask}
            name={name}
            onChange={handleChange}
            value={value}
            onBlur={onBlur}
            {...others}
        />
    );
};

export default CreditCardNumberInput;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { APP_URLS } from '../../urls';
import AboutYou from './Partials/AboutYou';

import Message from './Partials/Message';

import { Container, Wrapper } from './Styles';

const Onboarding = ({
    inputValues,
    setInputValues,
    handleModal,
    changeValue,
    history,
    handleForm,
    loading,
    OcupationsResult,
    SearchOcupations,
}) => (
    <Container>
        <Wrapper>
            <Switch>
                <Route exact path={APP_URLS.ONBOARDING}>
                    <Message welcome />
                </Route>
                <Route path={APP_URLS.ONBOARDING_QUESTIONS}>
                    <AboutYou
                        inputValues={inputValues}
                        setInputValues={setInputValues}
                        handleModal={handleModal}
                        changeValue={changeValue}
                        history={history}
                        handleForm={handleForm}
                        loading={loading}
                        OcupationsResult={OcupationsResult}
                        SearchOcupations={SearchOcupations}
                    />
                </Route>
                <Route exact path={APP_URLS.ONBOARDING_THANKS}>
                    <Message thanks />
                </Route>
            </Switch>
        </Wrapper>
    </Container>
);

export default Onboarding;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';
import SelectStyle from './questionnaire_select_styles';

const QuestionnaireSelect = ({
    className,
    name,
    id,
    label,
    children,
    ...others
}) => {
    const { onChangeSelect, question } = useContext(HealthContext);
    const [options, setOptions] = useState();

    useEffect(() => {
        setOptions(
            question.options
                ? question.options().map((item) => {
                      return (
                          <option key={item} value={item}>
                              {item}
                          </option>
                      );
                  })
                : children,
        );
    }, []);
    return (
        <label>
            <h1 className="mt-4 font-medium text-xl">{label}</h1>
            <div className="field_select pr-2 mt-2">
                <SelectStyle
                    id={id}
                    onChange={onChangeSelect}
                    name={name}
                    {...others}
                >
                    <option disabled selected>
                        Selecione uma opção
                    </option>
                    {options}
                </SelectStyle>
            </div>
        </label>
    );
};

export default QuestionnaireSelect;

import React, { useContext, useState, useRef } from 'react';
import Icon from '../ui/Icon';
import PreviewFileContainer from './PreviewFileContainer';
import { ModalContext } from '../../../app/contexts/ModalContext';
import FloatMenu from './Partials/FloatMenu';
import ListItemIcon from './Partials/ListItemIcon';

const ListExamItem = ({ f, OnAction = () => {} }) => {
    const { handleModal, setCloseButton, setOpen } = useContext(ModalContext);
    const [floatMenu, setFloatMenu] = useState(false);
    const ref = useRef();

    const doAction = (file, action) => {
        handleModal(null);
        setOpen(false);
        setTimeout(() => {
            if (action !== 'close') OnAction(file, action);
        }, 300);
    };

    const openViewModal = () => {
        setCloseButton(false);
        handleModal(
            <PreviewFileContainer
                file={f}
                isOpen
                widthWrapper={100}
                heightWrapper={100}
                className="mt-4"
                OnAction={(file, action) => doAction(file, action)}
            />,
        );
    };

    const OpenUploadForm = (file, action) => {
        setFloatMenu(false);
        OnAction(file, action);
    };

    return (
        <div className="mt-6">
            <div className="flex flex-row">
                <div className="">
                    <ListItemIcon f={f} />
                </div>
                <div className="flex flex-col w-full ml-4">
                    <div className="text-base font-medium text-pink-600 break-all">
                        {f.nome}
                    </div>
                    <div className="text-sm font-medium text-gray-700 break-all">
                        Tipo:{' '}
                        {f.exam_type === 'Outro'
                            ? f.other_exam_description
                            : f.exam_type}
                    </div>
                    <div className="text-sm font-normal text-gray-700">
                        Adicionado em: {f.data_cadastro}
                    </div>
                </div>
                <div className="ml-2">
                    <Icon
                        name="show"
                        size={20}
                        onClick={openViewModal}
                        className="cursor-pointer"
                    />
                </div>
                <div className="ml-2">
                    <FloatMenu
                        type='exam'
                        portal={true}
                        iconName='more-vertical'
                        onEdit={() => OpenUploadForm(f, 'edit')}
                        onDelete={() => OpenUploadForm(f, 'delete')}
                    />
                </div>
            </div>
        </div>
    );
};

export default ListExamItem;

import React, { useEffect, useReducer } from 'react';
import { APP_URLS } from '../../../urls';

const initialState = {
    title: '',
    subtitle: '',
};

const reducer = (state, feature) => {
    if (feature === APP_URLS.PROFILE) {
        return {
            ...state,
            title: 'Meu plano',
            subtitle: 'Informações sobre sua assinatura',
        };
    }
    if (feature.includes('detalhes')) {
        return {
            ...state,
            title: 'Detalhes do plano',
            subtitle: 'Informações sobre sua assinatura',
        };
    }
    if (feature.includes('alterar-pagamento')) {
        return {
            ...state,
            title: 'Alterar forma de pagamento',
            subtitle: 'Sem anuidades. Sem multas. Sem taxas escondidas.',
        };
    }
};

const FeatureTitle = ({ feature }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch(feature);
    }, [feature]);

    return (
        <div className="w-full bg-pink-600 rounded-b-md">
            <div className="container text-center py-8 text-white">
                <h1 className="font-medium text-2xl">{state.title}</h1>
                <h2 className="text-xl">{state.subtitle}</h2>
            </div>
        </div>
    );
};

export default FeatureTitle;

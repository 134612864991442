import axios from 'axios';

import { getToken } from '../commons/utils/utils';

export const backendServices = axios.create({
    baseURL: process.env.BASE_API_URL,
    headers: {
        Authorization: `Token ${getToken()}`,
    },
});

export const quizAPI = axios.create({
    baseURL: process.env.QUESTIONARIO_API_URL,
});

export const apiTP = axios.create({
    baseURL: process.env.BASE_URL_ADM,
    headers: {
        Authorization: `Token ${getToken()}`,
    },
});

export const apiGoogle = axios.create({
    baseURL: process.env.GOOGLE_API_URL,
});

export const vindiAPI = axios.create({
    baseURL: process.env.VINDI_PUBLIC_URL,
    auth: {
        username: process.env.VINDI_PUBLIC_KEY,
        password: '',
    },
});

import React from "react";

function JCB({ size }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 71 71"
        >
            <g clipPath="url(#clip0)">
                <g clipPath="url(#clip1)">
                    <path fill="#FCAF26" d="M.015 22h70.56v27.586H.014V22z"></path>
                    <path
                        fill="#231F20"
                        d="M66.476 44.772c0 .629-.384 1.143-.853 1.143H4.812c-.47 0-.854-.514-.854-1.143V27.083c0-.629.385-1.143.854-1.143h60.81c.47 0 .854.514.854 1.143v17.689z"
                    ></path>
                    <path
                        fill="#204595"
                        d="M59.897 38.497c.002.034.003.07.003.105v-.006c0-.034-.002-.066-.003-.099z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M19.716 42.51c-1.022 1.037-2.535 1.275-4.764 1.275h-3.169c-4.436 0-5.847-1.102-5.847-4.906v-2.075h4.253c0 .195-.02.39-.02.562 0 1.902.449 2.507 2.228 2.507h2.044c1.636 0 1.922-.648 1.922-2.225V27.9h4.539v11.28c0 1.362-.47 2.594-1.186 3.329zm18.33-9.099c0-1.253-.756-1.771-2.392-1.771h-3.639c-2.944 0-3.66.41-3.66 3.09v2.183c0 2.464 1.002 2.96 3.66 2.96h2.638c2.514 0 3.516-.28 3.516-2.161v-.173h4.6c.02.411.04.778.04 1.146 0 1.944-.429 3.241-1.492 4.063-1.124.885-2.903 1.059-5.5 1.059h-5.07c-2.35 0-4.006-.108-5.294-1.168-1.248-1.037-1.718-2.463-1.718-4.603v-4.279c0-4.71 1.942-6.05 7.013-6.05h5.07c2.596 0 4.354.13 5.438 1.015.96.778 1.37 2.075 1.37 3.955 0 .28 0 .583-.02.886h-4.56v-.152zm7.684 10.201V27.901h13.392c3.332 0 4.947 1.427 4.947 4.387 0 1.686-.695 2.723-2.187 3.328 1.88.324 2.699 1.491 2.699 3.739 0 3.025-1.473 4.257-4.764 4.257H45.73zM59.408 32.72c0-.778-.47-1.296-1.166-1.296H50.27v2.593h7.973c.757 0 1.166-.454 1.166-1.297zm.49 5.878c0-.843-.49-1.383-1.247-1.383H50.27v2.831h8.382c.797 0 1.247-.518 1.247-1.448z"
                    ></path>
                    <path
                        fill="#FCAF26"
                        d="M5.936 27.796h6.18v6.263h-6.18v-6.263z"
                    ></path>
                    <path
                        fill="#231F20"
                        d="M10.452 30.971l.13-.006c.03-.002.083-.005.116.062.009.018.06.184.067.203.006.02.021.11-.046.126l-.132.028-.135-.413zm-.23-.765l.165.013a.077.077 0 01.068.05c.008.018.057.17.063.187.006.017.03.109-.042.11h-.142l-.111-.36zm-.464-.438s.554.084.605.093c.23.039.33.235.359.327.01.03.073.244.082.268.063.18-.097.273-.097.273s.182.035.244.19l.127.392c.01.036.093.32-.188.425-.037.014-.488.141-.488.141l-.644-2.108zm-2.237-.354l.906.143.146.522-.211-.019s.52 1.765.539 1.82c.065.198.105.404-.18.503-.037.013-.303.094-.335.102-.165.041-.374.042-.456-.229l-.33-1.09.406-.031.182.601c.037.118.06.162.176.134.085-.02.081-.057.059-.134l-.5-1.712-.232-.019-.17-.591z"
                    ></path>
                    <path
                        stroke="#231F20"
                        strokeMiterlimit="3.864"
                        strokeWidth="0.009"
                        d="M7.52 29.414l.907.143.146.522-.211-.019s.52 1.765.539 1.82c.065.198.105.404-.18.503-.037.013-.303.094-.335.102-.165.041-.374.042-.456-.229l-.33-1.09.406-.031.182.601c.037.118.06.162.176.134.085-.02.081-.057.059-.134l-.5-1.712-.232-.019-.17-.591v0z"
                    ></path>
                    <path
                        fill="#231F20"
                        d="M8.872 29.622l.38.064c.05.008.321.055.393.32.018.064.16.529.16.529l-.343-.004s-.064-.224-.078-.263c-.033-.095-.035-.12-.139-.136-.104-.012-.099.034-.075.112l.339 1.183c.036.125.046.174.156.151.096-.023.078-.077.053-.169-.035-.12-.07-.24-.107-.36l.342-.026.184.61c.009.027.082.262-.125.364-.027.013-.027.015-.054.025-.028.01-.305.091-.33.099-.027.007-.291.093-.377-.182-.008-.026-.566-1.957-.595-2.052-.05-.16.003-.297.216-.265z"
                    ></path>
                    <path
                        stroke="#231F20"
                        strokeMiterlimit="3.864"
                        strokeWidth="0.009"
                        d="M8.872 29.622l.38.064c.05.008.321.055.393.32.018.064.16.529.16.529l-.343-.004s-.064-.224-.078-.263c-.033-.095-.035-.12-.139-.136-.104-.012-.099.034-.075.112l.339 1.183c.036.125.046.174.156.151.096-.023.078-.077.053-.169-.035-.12-.07-.24-.107-.36l.342-.026.184.61c.009.027.082.262-.125.364-.027.013-.027.015-.054.025-.028.01-.305.091-.33.099-.027.007-.291.093-.377-.182-.008-.026-.566-1.957-.595-2.052-.05-.16.003-.297.216-.265v0z"
                    ></path>
                    <path
                        fill="#231F20"
                        d="M7.154 29.11l3.257.56c.132.024.352.057.445.347.018.057.419 1.325.435 1.38.016.055.133.345-.197.453l-2.894.954c-.068.021-.33.137-.438-.263l-.888-2.971a1.27 1.27 0 01-.06-.253c-.016-.233.121-.24.233-.225.042.006.08.013.107.018zm-.077-.159c.075.012 3.302.575 3.384.589.083.014.4.05.527.433.025.074.427 1.35.446 1.415.07.236.04.482-.254.586-.097.034-2.866.964-2.9.975-.076.026-.483.217-.63-.28l-.924-3.07a1.34 1.34 0 01-.067-.346c0-.387.346-.31.418-.302z"
                    ></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        d="M0 0H70.588V70.588H0z"
                        transform="translate(0 .353)"
                    ></path>
                </clipPath>
                <clipPath id="clip1">
                    <path
                        fill="#fff"
                        d="M0 0H70.588V27.586H0z"
                        transform="translate(0 22)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default JCB;
import React from 'react';

const ExamHeader = ({}) => {
    return (
        <div className="flex flex-col w-full h-auto bg-pink-600 rounded-b">
            <div className="container mt-4 mb-4 text-center">
                <h3 className="text-2xl font-medium mb-4 text-white">
                    Exames e Anexos
                </h3>
                <span className="text-white mb-4">
                    Envie exames e anexe fotos e vídeos das suas queixas para
                    sua equipe de saúde e agilize seu atendimento.
                </span>
            </div>
        </div>
    );
};

export default ExamHeader;

import * as React from 'react';
import cn from 'classnames';

import Icon from '../../Icon';

export default ({ className, color, name, defaultValue, options, ...rest }) => {
    const selectTypes = {
        gray:
            'bg-gray-400 border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline',
    };

    return (
        <div className={`inline-block relative w-full ${className}`}>
            <select
                className={cn(
                    'block appearance-none w-full border px-4 py-2 pr-8 rounded shadow leading-tight h-12',
                    selectTypes[color],
                )}
                name={name}
                defaultValue={defaultValue}
                {...rest}
            >
                <option value="" disabled>
                    Selecione...
                </option>
                {options.map((item) => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ))}
            </select>
            <div
                className="pointer-events-none absolute inset-y-0 right-0
                    flex items-center px-2 text-gray-700"
            >
                <Icon className="fill-current h-4 w-4" name="arrow-down" />
            </div>
        </div>
    );
};

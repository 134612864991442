import React, { useContext } from 'react';
import { ModalContext } from '../../../contexts/ModalContext';
import { AnimatedDiv, Button, Icon } from '../../ui';

import Animate from './Animate';

const WHATSAPP_URI = 'https://wa.me/?text=';
const FACEBOOK_URI = 'https://www.facebook.com/sharer/sharer.php?u=';

const SendInvitePrompt = ({ link, onCopy }) => {
    const { handleModal } = useContext(ModalContext);

    return (
        <AnimatedDiv
            variants={Animate}
            initial="initial"
            animate="in"
            exit="exit"
            className="bg-white py-8 rounded-lg"
        >
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-xl font-medium text-pink-900 mx-8">
                    Envie um <span className="text-pink-600">convite</span> para
                    seus amigos:
                </h1>
                <div className="w-full text-gray-900 text-lg mt-4 px-8">
                    <div
                        className="flex items-center cursor-pointer py-4 hover:bg-gray-400 px-4"
                        onClick={() =>
                            window.open(
                                `${WHATSAPP_URI}${encodeURIComponent(
                                    `Utilize meu convite e ganhe 90% de desconto na assinatura na Kompa Saúde e cuide de sua saúde! ${link}`,
                                )}`,
                                '_blank',
                            )
                        }
                    >
                        <Icon name="whatsapp-filled" />
                        <h2 className="ml-2">Convidar via Whatsapp</h2>
                    </div>
                    <hr className="bg-gray-500" />

                    <div
                        className="flex items-center cursor-pointer py-4 hover:bg-gray-400 px-4"
                        onClick={() =>
                            window.open(
                                `${FACEBOOK_URI}${encodeURIComponent(
                                    link,
                                )}&quote=Utilize meu convite e ganhe 90% de desconto na assinatura na Kompa Saúde e cuide de sua saúde!`,
                                '_blank',
                            )
                        }
                    >
                        <Icon name="facebook-filled" />
                        <h2 className="ml-2">Compartilhar no Facebook</h2>
                    </div>
                    <hr className="bg-gray-500" />

                    <div
                        className="flex items-center cursor-pointer py-4 hover:bg-gray-400 px-4"
                        onClick={() =>
                            window.open(
                                `mailto:?subject=Convite Kompa Saúde&body=Utilize meu convite e ganhe 90% de desconto na assinatura na Kompa Saúde e cuide de sua saúde! ${link}`,
                                '_blank',
                            )
                        }
                    >
                        <Icon name="email" />
                        <h2 className="ml-2">Convidar por E-mail</h2>
                    </div>
                    <hr className="bg-gray-500" />

                    <div
                        className="flex items-center cursor-pointer py-4 hover:bg-gray-400 px-4"
                        onClick={() => {
                            onCopy();
                            handleModal();
                        }}
                    >
                        <Icon name="copy" />
                        <h2 className="ml-2">Copiar link</h2>
                    </div>
                </div>

                <div className="flex justify-end w-full px-8">
                    <Button
                        color="white"
                        className="px-4"
                        onClick={() => handleModal()}
                    >
                        OK
                    </Button>
                </div>
            </div>
        </AnimatedDiv>
    );
};

export default SendInvitePrompt;

import React, { useState } from 'react';
import Component from '../../components/WelcomeModal/WelcomeModal';
import { eraseCookie } from '../../utils';

const WelcomeModal = ({ isOpen }) => {
    const [open, setOpen] = useState(isOpen);

    const close = () => {
        setOpen(false);
        eraseCookie('first_login');
    };

    return <Component isOpen={open} onRequestClose={close} />;
};

export default WelcomeModal;

const BACKEND_URL_PREFIX = process.env.BACKEND_URL_PREFIX || '/';

export const BACKEND_URLS = {
    TELEMEDICINE: `${BACKEND_URL_PREFIX}atendimento/consultas`,
    LOGIN: `${BACKEND_URL_PREFIX}cadastro/login`,
    DRUGSTORES: `${BACKEND_URL_PREFIX}atendimento/farmacias`,
    SPA_HOME: `${BACKEND_URL_PREFIX}`,
    __LOGIN_AND_REDIRECT: (targetUrl) =>
        `${BACKEND_URL_PREFIX}cadastro/login?next=${targetUrl}`,
    CHAT: `${BACKEND_URL_PREFIX}chat`,
};

export const APP_URLS = {
    HOME: '/',
    CALL_0800: '/orientacao',
    SUPPORT: '/suporte',
    INVITE_FRIENDS: '/convites',
    QUESTIONNAIRE_WELCOME: '/questionario',
    QUESTIONNAIRE_QUESTIONS: '/questionario/:questionId',
    QUESTIONNAIRE_FINISHED: '/questionario-finished',
    EXAM_UPLOAD: '/exames',
    EXAM_UPLOAD_NEW: '/exames/new',
    EXAM_UPLOAD_EDIT: '/exames/edit',
    EXAM_UPLOAD_DELETE: '/exames/delete',
    EXAM_PAGE: '/exames/:exameId',
    HEALTH_HISTORY: '/historico',
    CHANGE_PASSWORD_WELCOME: '/senha-temporaria',
    CHANGE_PASSWORD_CREATE: '/senha-temporaria/alterar',
    CHANGE_PASSWORD_FINISHED: '/senha-temporaria/tudo-certo',
    HEALTH_MONITORING: '/monitoramento',
    ONBOARDING: '/onboarding',
    ONBOARDING_QUESTIONS: '/onboarding/sobre-voce',
    ONBOARDING_THANKS: '/onboarding/obrigado',
    PROFILE: '/meu-perfil/assinaturas',
    BILLING_DETAILS: '/meu-perfil/assinaturas/detalhes/:id',
    CHANGE_PAYMENT_METHOD: '/meu-perfil/assinaturas/:id/alterar-pagamento',
    REQUEST_ERROR: '/erro',
    BOOKING: '/agendamento',
    BOOKING_CHOOSE_UNIT: '/agendamento/:type/:specialty_id/unidades',
    BOOKING_CHOOSE_TIME: '/agendamento/:type/:specialty_id/:unit_id',
    BOOKING_OVERVIEW: '/agendamento/revisao',
    BOOKING_PAYMENT: '/agendamento/pagamento',
    BOOKING_CONFIRM: '/agendamento/confirmacao',
    TELEMEDICINE_VALUES: '/telemedicina/valores',
    SERVICES: '/atendimento',
    SERVICES_EMERGENCY_CARE: '/atendimento/atendimento-rapido',
    PRESCRIPTIONS: '/prescricoes'
};

export const BACKGROUNDS = {
    TELEMEDICINE: '/static/img/backgrounds/bg-telemedicina.svg',
    CALL0800: '/static/img/backgrounds/bg-0800.svg',
    ACCREDITED_NETWORK: '/static/img/backgrounds/bg-redecredenciada.svg',
    COMING_SOON: '/static/img/backgrounds/bg-soon.svg',
    DIGITAL_CARD: '/static/img/backgrounds/bg-carteirinha.svg',
    SUPPORT: '/static/img/backgrounds/bg-questions.svg',
    DRUGSTORES: '/static/img/backgrounds/bg-farmacias.svg',
    BLOG: '/static/img/backgrounds/bg-blog.svg',
    EXAMES: '/static/img/backgrounds/bg-exames.svg',
    MONITORING: '/static/img/backgrounds/bg-monitoring.svg',
    HISTORY: '/static/img/backgrounds/bg-history.svg',
    TELEMEDICINE_BOOK: '/static/img/backgrounds/bg-telemedicine.svg',
    TELEMEDICINE_VALUES: '/static/img/backgrounds/bg-consultations.svg',
    APP_PASSWORD_DESKTOP: '/static/img/backgrounds/CirculosBG.svg',
    APP_PASSWORD_MOBILE: '/static/img/backgrounds/CirculosBGMobile.svg',
    BG_HEADER: '/static/img/backgrounds/br-menuheader.svg',
    BG_SUCCESS: '/static/img/backgrounds/bg-success-health.svg',
    BG_HOW_TO: '/static/img/backgrounds/bg-how-to-collect.svg',
    BG_MONITORING: '/static/img/backgrounds/bg-health-monitoring-desk.svg',
    BG_GUY_STANDING: '/static/img/guy-standing.svg',
    SvgInputPassword: '/static/img/backgrounds/inputPasswordSvg.svg',
    SvgInputPasswordRed: '/static/img/backgrounds/inputPasswordSvgRed.svg',
    SvgWomen: '/static/img/backgrounds/Standing.svg',
    SvgErrorRobot: '/static/img/backgrounds/ErrorRobot.svg',
    SvgHappy: '/static/img/backgrounds/Happy.svg',
    BG_ONBOARDING_MOBILE: '/static/img/backgrounds/bg-onboarding-mobile.svg',
    BG_ONBOARDING_DESKTOP: '/static/img/backgrounds/bg-onboarding-desktop.svg',
    PRESCRIPTIONS: '/static/img/backgrounds/bg-prescriptions.svg',
    BG_PWA_PROMPT_FULL: '/static/img/backgrounds/bg-pwa-prompt-full.svg',
    BG_PWA_PROMPT_MOBILE: '/static/img/backgrounds/bg-pwa-prompt-mobile.svg',
    BG_LOGO: '/static/img/backgrounds/LogoBg.svg',
    MESSAGE_PROMPT_MOBILE: '/static/img/backgrounds/bg-recover-pass-mobile.svg',
    MESSAGE_PROMPT_DESKTOP: '/static/img/backgrounds/bg-recover-pass-desktop.svg',
    DROP_DOWN_ARROW: '/static/img/backgrounds/dropDownArray.svg',
    KARMEN: '/static/img/backgrounds/bg-karmen.svg',
};

export const SITE = 'https://kompa.com.br';

export const SITE_URLS = {
    PURCHASE: `${SITE}/assinar`,
    BOOK_APPOINTMENT: `${SITE}/agendar`,
    DIGITAL_CARD: `${SITE}/carteirinha`,
    PLANS: `${SITE}/#planos`,
    BLOG: 'https://blog.kompa.com.br',
};

export const IFRAME_URLS = {
    VERSATILIS: 'http://globalmed.ddns.net:90/globalmed/login3.aspx',
};

import { useEffect, useState, useContext } from 'react';

import { GlobalContext } from '../contexts/GlobalContext';
import { checkIsQuizAnswered } from '../services/api';

const OpenModalQuizAnswered = () => {
    const [isOpenModalQuizAnswered, setIsOpenModalQuizAnswered] = useState(
        false,
    );

    const { user } = useContext(GlobalContext);

    const checkQuizIsAnswered = async () => {
        const quizResponse = await checkIsQuizAnswered(user.cpf);

        if (quizResponse.finished) setIsOpenModalQuizAnswered(true);
    };

    useEffect(() => {
        checkQuizIsAnswered();
    }, []);

    return [isOpenModalQuizAnswered, setIsOpenModalQuizAnswered];
};

export default OpenModalQuizAnswered;

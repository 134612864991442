import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BACKGROUNDS, APP_URLS, BACKEND_URLS, SITE_URLS } from '../../urls';
import { FEATURES, GlobalContext } from '../../contexts/GlobalContext';
import { Icon } from '../ui';
import FirstSteps from '../../containers/FirstSteps/FirstSteps';
import MenuItem from './MenuItem';
import CardServiceChannels from './CardServiceChannels';
import CardInviteFriends from './CardInviteFriends';
import CardSignPlan from './CardSignPlan/CardSignPlan';
import { ModalContext } from '../../contexts/ModalContext';
import { NotificationPrompt } from '../Notification';
import { checkUserKarmenData } from '../../services/api';
import { useNotification } from '../../contexts/NotificationContext';
import { openWhatsApp } from '../../utils';
import { SUPPORT_WHATSAPP } from '../../constants';

const HomeV1 = () => {
    const { handleModal, setCloseButton } = useContext(ModalContext);
    const {
        isLoggedIn,
        navigateToFeature,
        isFree,
        user,
        isUnlimited,
    } = useContext(GlobalContext);

    const { push_enabled, push_status } = useNotification();

    const [firstStepsStatus, setFirstStepsStatus] = useState({
        onboarding: '',
        karmen: '',
        push: '',
    });

    const [showFirstSteps, setShowFirstSteps] = useState(false);

    const checkFirstSteps = async () => {
        try {
            const karmenResponse = await checkUserKarmenData(user.id);

            const onboarding =
                user.onboarding_done === 'None' ? 'pending' : 'finished';
            const karmen = karmenResponse.sessionId ? true : false;
            const push = push_status === 'granted' && push_enabled;

            setFirstStepsStatus({
                onboarding,
                karmen,
                push,
            });
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(() => {
        if (push_status !== 'none' && push_enabled !== 'none')
            checkFirstSteps();
    }, [push_status, push_enabled]);

    useEffect(() => {
        const { onboarding, karmen, push } = firstStepsStatus;

        /*
            If values are not default
        */
        if (onboarding !== '' && karmen !== '' && push !== '') {
            /*
                If any step is not finished
            */
            if (onboarding !== 'finished' || !karmen || !push)
                return setShowFirstSteps(true);
        }

        return setShowFirstSteps(false);
    }, [firstStepsStatus]);

    const history = useHistory();

    return (
        <div className="container mx-auto overflow-auto">
            {showFirstSteps && (
                <div className="my-4 flex justify-center">
                    <FirstSteps firstStepsStatus={firstStepsStatus} />
                </div>
            )}

            <div className="bg-gray-300 overflow-auto">
                <div className="text-gray-600 font-medium rounded ml-4 mt-4">
                    RECOMENDADOS PARA VOCÊ
                </div>
                <div className="my-4">
                    <div className="bg-pink-600 rounded p-4 flex justify-between items-center">
                        <p className="text-xl text-white font-medium">
                            Tem vídeo consulta agendada?
                        </p>
                        <a
                            className="text-center text-base font-medium bg-white text-pink-600 px-4 py-2 rounded tracking-wider flex items-center justify-center cursor-pointer log-acao loga-aesvc"
                            href={BACKEND_URLS.TELEMEDICINE}
                            id="acessar-consultorio-virtual"
                        >
                            Acessar consultório virtual
                        </a>
                    </div>
                </div>
                <CardServiceChannels />
            </div>
            <div className="bg-gray-300">
                <div className="text-gray-600 font-medium rounded px-4 pb-4">
                    DESCONTOS
                </div>
                {isFree() ? (
                    <CardSignPlan />
                ) : (
                    <CardInviteFriends isLoggedIn={isLoggedIn()} />
                )}
            </div>
            <div className="bg-gray-300 pt-4">
                <div className="text-gray-600 font-medium rounded px-4">
                    DESTAQUES
                </div>
                <div className="mt-4 grid sm:grid-cols-3 lg:grid-cols-4 grid-cols-2 gap-2">
                    <MenuItem
                        title="Consultório virtual"
                        background={BACKGROUNDS.TELEMEDICINE}
                        href={BACKEND_URLS.TELEMEDICINE}
                        id="consultorio-virtual"
                    />
                    <MenuItem
                        className="booking"
                        title="Agendar consulta"
                        background={BACKGROUNDS.ACCREDITED_NETWORK}
                        onClick={() => {
                            if (isUnlimited()) {
                                return openWhatsApp(
                                    SUPPORT_WHATSAPP,
                                    'Olá, quero agendar uma consulta',
                                );
                            }
                            history.push(APP_URLS.BOOKING);
                        }}
                        id="agendar-consulta"
                    />
                    <MenuItem
                        href={BACKEND_URLS.CHAT}
                        title="Avaliação de Saúde Online"
                        background={BACKGROUNDS.KARMEN}
                        id="avaliacao-de-saude-online"
                    />
                    <MenuItem
                        onClick={() =>
                            navigateToFeature(FEATURES.ORIENTATION_24H)
                        }
                        title="Orientação 24 horas"
                        background={BACKGROUNDS.CALL0800}
                        exclusive={isFree()}
                        id="orientacao-24-horas"
                    />
                    <MenuItem
                        title="Minhas Prescrições"
                        background={BACKGROUNDS.PRESCRIPTIONS}
                        onClick={() =>
                            navigateToFeature(FEATURES.PRESCRIPTIONS)
                        }
                        id="minhas-prescricoes"
                    />
                    <MenuItem
                        title="Meus exames e Anexos"
                        background={BACKGROUNDS.EXAMES}
                        onClick={() => navigateToFeature(FEATURES.EXAMS)}
                        id="meus-exames"
                    />
                    <MenuItem
                        onClick={() =>
                            navigateToFeature(FEATURES.HEALTH_MONITORING)
                        }
                        title="Acompanhamento de Saúde"
                        background={BACKGROUNDS.MONITORING}
                        id="acompanhamento-de-saude"
                    />
                    <MenuItem
                        onClick={() => navigateToFeature(FEATURES.DIGITAL_CARD)}
                        title="Carteirinha virtual"
                        background={BACKGROUNDS.DIGITAL_CARD}
                        exclusive={isFree()}
                        id="carteirinha-virtual"
                    />
                    <MenuItem
                        title="Conteúdos para minha saúde"
                        background={BACKGROUNDS.BLOG}
                        onClick={() => window.open(SITE_URLS.BLOG, '_blank')}
                        id="conteudos-para-minha-saude"
                    />
                    <MenuItem
                        title="Valores das consultas por telemedicina"
                        background={BACKGROUNDS.TELEMEDICINE_VALUES}
                        onClick={() =>
                            navigateToFeature(FEATURES.TELEMEDICINE_VALUES)
                        }
                        id="valores-das-consultas-por-telemedicina"
                    />
                    <MenuItem
                        onClick={() => navigateToFeature(FEATURES.DRUGSTORES)}
                        title="Farmácias com desconto"
                        background={BACKGROUNDS.DRUGSTORES}
                        exclusive={isFree()}
                        id="farmacias-com-desconto"
                    />
                    <MenuItem
                        to={APP_URLS.SUPPORT}
                        title="Informações, reclamações e sugestões"
                        background={BACKGROUNDS.SUPPORT}
                        id="informacoes-reclamacoes-e-sugestoes"
                    />
                </div>
            </div>
            <div className="bg-gray-300 py-4">
                <div className="text-gray-600 font-medium rounded px-4">
                    MAIS SERVIÇOS
                </div>
                <div className="sm:w-1/2 rounded bg-white border border-gray-500 flex flex-col">
                    <button
                        type="button"
                        onClick={() => {
                            setCloseButton(false);
                            handleModal(<NotificationPrompt />);
                        }}
                        className="flex flex-grow p-4 hover:bg-gray-200 text-pink-600 tracking-widest font-semibold"
                    >
                        <Icon
                            name="alert-outline"
                            size={20}
                            className="fill-current text-pink-600"
                        />
                        <div className="ml-2">
                            Alterar preferências de notificação
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HomeV1;

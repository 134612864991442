import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { getSessionItem, setSessionItem } from '../../../utils/localStorage';

const ButtonGroupContainer = styled.div`
    width: 100%;

    button {
        border-radius: 5px;
    }

    button:focus {
        outline: none;
    }

    display: grid;

    grid-template-columns: repeat(2, minmax(0, 1fr));

    gap: 12px;

    @media (max-width: 425px) {
        gap: 4px;
    }
`;

const ButtonGroupButton = styled.button`
    width: 100%;

    padding: 8px 0;

    font-size: 18px;

    ${(props) =>
        props.active
            ? css`
                  background-color: ${({ theme }) => theme.colors.teal3};
                  color: ${({ theme }) => theme.colors.white1};
              `
            : css`
                  color: ${({ theme }) => theme.colors.gray3};
                  background-color: ${({ theme }) => theme.colors.white};
                  border: 2px solid ${({ theme }) => theme.colors.gray4};
              `}
`;

const ButtonGroup = ({ options, onChange, name }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const selectOption = (item, index) => {
        setActiveIndex(index);
        onChange(name, item.value);

        setSessionItem(name, index);
    };

    useEffect(() => {
        const check = getSessionItem(name);

        if (check) {
            setActiveIndex(Number(check));
            onChange(name, options[check].value);
        }
    }, []);

    return (
        <ButtonGroupContainer>
            {options.map((item, index) => (
                <ButtonGroupButton
                    className="assina-shadow-sm"
                    key={item.value}
                    active={activeIndex === index}
                    onClick={() => selectOption(item, index)}
                >
                    {item.label}
                </ButtonGroupButton>
            ))}
        </ButtonGroupContainer>
    );
};

export default ButtonGroup;

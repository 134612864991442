export const Parent = {
    hidden: { scale: 0 },
    show: {
        scale: 1,
        transition: {
            staggerChildren: 0.3,
            type: 'spring',
        },
    },
    leave: { scale: 0 },
};

export const Item = {
    hidden: { scale: 0 },
    show: { scale: 1 },
    leave: { scale: 0 },
};

import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { ModalContext } from '../../../contexts/ModalContext';

import { bookAppointment } from '../../../services/booking';
import { APP_URLS } from '../../../urls';
import MessagePrompt from '../../Profile/MessagePrompt';
import { AnimatedDiv, Button, Section } from '../../ui';

import Overview from '../Overview';
import { Wrapper } from './Animate';

const BookingOverview = ({
    browserHistory,
    bookingOptions,
    addOptions,
    user: { name, isStaff },
    priceToPay,
}) => {
    const { handleModal, setCloseButton } = useContext(ModalContext);
    const { setLoading } = useContext(GlobalContext);

    useEffect(() => {
        if (!bookingOptions) browserHistory.replace(APP_URLS.BOOKING);
    }, []);

    const handleFreeAppointment = async () => {
        setLoading(true);
        setCloseButton(false);
        try {
            const res = await bookAppointment({
                specialty_id: bookingOptions.specialty.id,
                consultation_id: bookingOptions.selectedOption.id,
                service_type:
                    bookingOptions.type === 'telemedicina'
                        ? 'telemedicine'
                        : 'presential',
            });

            setLoading(false);
            addOptions({ status: 'requested', bookedResponse: res });
            browserHistory.push(APP_URLS.BOOKING_CONFIRM);
        } catch (error) {
            setLoading(false);
            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro durante a sua requisição."
                    errorAction={() => window.location.reload()}
                />,
            );
            throw new Error(error);
        }
    };

    return (
        <AnimatedDiv
            variants={Wrapper}
            initial="initial"
            animate="in"
            exit="exit"
            className="container py-8 max-w-xl mx-auto"
        >
            <Section
                className="flex flex-col rounded assina-shadow-sm px-6"
                as="div"
            >
                <h1 className="text-2xl text-teal-600 font-medium mb-4">
                    Revise seu agendamento
                </h1>

                {bookingOptions && (
                    <>
                        <Overview
                            bookingOptions={bookingOptions}
                            priceToPay={priceToPay}
                            username={name}
                            isStaff={isStaff}
                        />

                        <hr className="bg-gray-100 my-4" />

                        <Button
                            color="outlined"
                            onClick={() =>
                                browserHistory.push(APP_URLS.BOOKING)
                            }
                        >
                            Alterar
                        </Button>

                        {bookingOptions.free_booking ? (
                            <Button
                                className="mt-4"
                                onClick={handleFreeAppointment}
                            >
                                Confirmar agendamento
                            </Button>
                        ) : (
                            <Button
                                className="mt-4"
                                id="ir-pagamento"
                                onClick={() =>
                                    browserHistory.push(
                                        APP_URLS.BOOKING_PAYMENT,
                                    )
                                }
                            >
                                Ir para pagamento
                            </Button>
                        )}
                    </>
                )}
            </Section>
        </AnimatedDiv>
    );
};

export default BookingOverview;

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { FormError, Section, Button, FormInput, Icon } from '../../../ui';
import { Companies, CompanySelect, CreditCardInput } from '../../../CreditCard';

const trimRegexp = /[^0-9]/g;

const ChangePaymentForm = ({ onSubmit }) => {
    const { handleSubmit, register, errors, control } = useForm({
        mode: 'onTouched',
        defaultValues: {
            holder_name: '',
            card_number: '',
            card_company: '',
            card_expiration: '',
            card_cvv: '',
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Section className="rounded-md shadow-sm my-6 py-4 p-6">
                <FormInput
                    haveInput
                    notAnimated
                    label="Nome impresso no cartão"
                    htmlFor="holder_name"
                    className="mt-4"
                    invalid={errors.holder_name}
                >
                    <Icon name="profile" className="mr-4" size={24} />
                    <input
                        ref={register({ required: true })}
                        type="text"
                        placeholder="Escreva aqui..."
                        id="holder_name"
                        name="holder_name"
                    />
                </FormInput>
                {errors.holder_name && <FormError>Obrigatório</FormError>}

                <FormInput
                    haveInput
                    notAnimated
                    label="Numero do cartão"
                    htmlFor="card_number"
                    className="mt-4"
                    invalid={errors.card_number}
                >
                    <Icon name="credit-card" size={30} className="mr-4" />
                    <Controller
                        id="card_number"
                        name="card_number"
                        placeholder="ex: 1234 5678 9012 3456"
                        as={CreditCardInput}
                        control={control}
                        rules={{
                            required: true,
                            validate: {
                                atLeastFourteen: (value = '') =>
                                    value.replace(trimRegexp, '').length > 14,
                            },
                        }}
                    />
                </FormInput>

                {errors.card_number?.type === 'required' && (
                    <FormError>Obrigatório</FormError>
                )}
                {errors.card_number?.type === 'atLeastFourteen' && (
                    <FormError>
                        O cartão precisa ter no mínimo 15 números
                    </FormError>
                )}

                <FormInput
                    haveInput
                    notAnimated
                    label="Bandeira"
                    htmlFor="card_company"
                    className="mt-4"
                    invalid={errors.card_company}
                    wrapperStyle={{ padding: '0' }}
                >
                    <Controller
                        id="card_company"
                        name="card_company"
                        placeholder="Selecione um..."
                        as={CompanySelect}
                        control={control}
                        options={Companies.filter(f => f.active)}
                        defaultValue=""
                        rules={{
                            required: true,
                        }}
                    />
                </FormInput>

                {errors.card_company?.type === 'required' && (
                    <FormError>Obrigatório</FormError>
                )}

                <div className="grid grid-cols-2 gap-4 my-4">
                    <div>
                        <FormInput
                            haveInput
                            notAnimated
                            label="Validade"
                            htmlFor="card_expiration"
                            invalid={errors.card_expiration}
                        >
                            <Icon name="calendar" size={27} className="mr-4" />
                            <Controller
                                id="card_expiration"
                                name="card_expiration"
                                placeholder="ex: 12/23"
                                as={NumberFormat}
                                control={control}
                                rules={{
                                    required: true,
                                    validate: {
                                        atLeastFour: (value = '') =>
                                            value.replace(trimRegexp, '')
                                                .length >= 4,
                                    },
                                }}
                                format="##/##"
                                mask="_"
                            />
                        </FormInput>

                        {errors.card_expiration?.type === 'required' && (
                            <FormError>Obrigatório</FormError>
                        )}
                        {errors.card_expiration?.type === 'atLeastFour' && (
                            <FormError>
                                A data de expiração precisa ter no mínimo 4
                                números
                            </FormError>
                        )}
                    </div>
                    <div>
                        <FormInput
                            haveInput
                            notAnimated
                            label="CVV"
                            htmlFor="card_cvv"
                            invalid={errors.card_cvv}
                        >
                            <Icon name="lock" size={25} className="mr-4" />
                            <Controller
                                id="card_cvv"
                                name="card_cvv"
                                placeholder="ex: 123"
                                as={NumberFormat}
                                control={control}
                                rules={{
                                    required: true,
                                    validate: {
                                        atLeastThree: (value = '') =>
                                            value.replace(trimRegexp, '')
                                                .length >= 3,
                                    },
                                }}
                                format="####"
                            />
                        </FormInput>

                        {errors.card_cvv?.type === 'required' && (
                            <FormError>Obrigatório</FormError>
                        )}
                        {errors.card_cvv?.type === 'atLeastThree' && (
                            <FormError>
                                O CVV precisa ter no mínimo 3 números
                            </FormError>
                        )}
                    </div>
                </div>
            </Section>

            <div className="flex justify-end">
                <Button className="shadow-md px-8">Salvar</Button>
            </div>
        </form>
    );
};

export default ChangePaymentForm;

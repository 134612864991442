import React from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';

import { Icon } from '../../ui';

const SectionTitle = ({ title, description, className }) => {
    const s = cn(
        'container max-w-xl flex justify-between container',
        className,
    );

    return (
        <div className={s}>
            <h4 className="text-lg text-gray-800">{title}</h4>
            {description && (
                <>
                    <Icon
                        name="question_mark_circle"
                        data-tip={description}
                        className="fill-current text-gray-800"
                    />
                    <ReactTooltip place="bottom" />
                </>
            )}
        </div>
    );
};

export default SectionTitle;

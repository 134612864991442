import React, { useContext } from 'react';

import { GlobalContext } from '../../contexts/GlobalContext';

import HomeV1 from './V1';
import HomeV2 from './V2';

const Home = () => {
    const { featureFlags } = useContext(GlobalContext);

    const {
        'new-notification-home': newNotificationHome,
    } = featureFlags.enabled;

    return newNotificationHome ? <HomeV2 /> : <HomeV1 />;
};

export default Home;

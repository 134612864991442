import React from 'react';

import { Button, Icon, ReactModal as Modal } from '../ui';

const UpgradeBenefitsModal = ({ title, isOpen, onAccept, onDeny }) => {
    return (
        <Modal className="max-w-xl p-6" isOpen={isOpen} onRequestClose={onDeny}>
            <div className="flex items-center">
                <div className="w-12 h-12 rounded-full bg-teal-600 flex items-center justify-center flex-shrink-0">
                    <Icon name="star" className="fill-current text-white" />
                </div>
                <h1 className="text-teal-600 font-medium text-2xl ml-2">
                    {title}
                </h1>
            </div>
            <div>
                <h3 className="text-lg mt-4">
                    E mais a partir de{' '}
                    <span className="font-medium text-pink-600">
                        R$ 39,00 / mês
                    </span>
                    :
                </h3>
                <ul className="mt-2">
                    <Benefit title="Consultas gratuitas ilimitadas com médico dedicado" />
                    <Benefit title="Acesso a equipe de saúde dedicada a qualquer tempo" />
                    <Benefit title="Médicos plantonistas online 24hrs sem nenhum custo adicional" />
                    <Benefit title="Acompanhamento odontológico gratuito em rede credenciada" />
                    <Benefit title="Consultas com médicos especialistas por apenas R$ 39,90" />
                    <Benefit title="Desconto de até 60% em exames e cirurgias" />
                    <Benefit title="Desconto de até 50% em medicamentos" />
                </ul>
            </div>
            <Button className="w-full mt-4" onClick={onAccept}>
                Assinar agora
            </Button>
            <Button color="white" className="w-full mt-2" onClick={onDeny}>
                Agora não
            </Button>
        </Modal>
    );
};

const Benefit = ({ title }) => {
    return (
        <li className="flex items-center">
            <Icon
                name="check"
                className="fill-current text-teal-600 flex-shrink-0"
            />
            <p className="ml-1">{title}</p>
        </li>
    );
};

export default UpgradeBenefitsModal;

import styled, { css } from 'styled-components';

import CloseRounded from '../../../../assets/svg/close-rounded.svg';

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    display: flex;

    ${({ isBottom }) =>
        isBottom
            ? css`
                  align-items: flex-end;
              `
            : css`
                  align-items: center;
                  justify-content: center;
              `}

    z-index: 20;
`;

export const ModalBody = styled.div`
    z-index: 20;

    max-height: 90%;
    ${({ isBottom }) =>
        isBottom
            ? css`
                  @media (max-width: 425px) {
                      max-height: calc(100vh - 40px);
                      width: 100%;
                  }
              `
            : css`
                  margin: 0 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
              `}
`;

export const CloseButton = styled(CloseRounded)`
    cursor: pointer;
`;

export const CloseDiv = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white1};

    padding: 12px;

    display: flex;
    justify-content: flex-end;

    border-radius: 6px 6px 0 0;

    display: ${({ show }) => (show ? 'flex' : 'none')};
`;

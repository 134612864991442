/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { Input } from '../../ui';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';
import InputRadioStyle from './radioInput_styles';

const InputRadio = ({ label, id, value, name, type = 'radio', ...others }) => {
    const { valueInput, onChangeRadio } = useContext(HealthContext);
    return (
        <InputRadioStyle className="flex items-center text-lg hover:bg-gray-400 rounded px-2 cursor-pointer mt-2">
            <Input
                onChange={onChangeRadio}
                type={type}
                id={id}
                name={name}
                value={value}
                checked={valueInput[name] === value}
                {...others}
            />
            <p className="pl-4" htmlFor={id}>
                {label}
            </p>
        </InputRadioStyle>
    );
};

export default InputRadio;

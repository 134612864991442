import React, { useState, useRef, useEffect } from 'react';
import { usePopper } from 'react-popper';
import Scrollbars from 'react-custom-scrollbars';

import {
    ArrowDown,
    DropdownItem,
    Input,
    NotFound,
    PopperContainer,
} from './styles';

const PopperSelect = ({
    placeholder,
    options,
    type,
    name,
    onChange,
    creatable,
    searchable,
}) => {
    const [showPopper, setShowPopper] = useState(false);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const [inputValue, setInputValue] = useState('');
    const [results, setResults] = useState(options);

    const [selected, setSelected] = useState({});

    const [dropOnFocus, setDropOnFocus] = useState(true);

    const inputRef = useRef(null);

    const { styles, attributes, update } = usePopper(
        referenceElement,
        popperElement,
        {
            placement: '',
            modifiers: [
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [0, -5],
                    },
                },
            ],
        },
    );

    useEffect(() => {
        if (inputValue === '') return;
        onChange(selected);
    }, [inputValue]);

    const filterOptions = (e) => {
        const { value } = e.target;
        const trimmed = value.replace(/"/g, '');

        setShowPopper(true);
        update();
        const filtered = options.filter((item) =>
            item.label.toLowerCase().includes(trimmed.toLowerCase()),
        );

        if (creatable) {
            if (filtered.length === 0) {
                filtered.push({ value: 253, label: `"${trimmed}"` });

                setInputValue(trimmed.replace(/"/g, ''));
                return setResults(filtered);
            }
        }

        setInputValue(trimmed);
        setResults(filtered);
    };

    const toggle = () => {
        setShowPopper(dropOnFocus);
        update();
    };

    return (
        <>
            <div
                className="flex items-center w-full relative"
                onClick={() => inputRef.current.focus()}
                onFocus={toggle}
                onBlur={() => setShowPopper(false)}
                ref={setReferenceElement}
            >
                <Input
                    ref={inputRef}
                    type={type || 'text'}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => filterOptions(e)}
                    name={name}
                    autoComplete="off"
                    readOnly={!searchable}
                />
                <ArrowDown open={showPopper} />
            </div>
            <PopperContainer
                ref={setPopperElement}
                style={styles.popper}
                showPopper={showPopper}
                {...attributes.popper}
            >
                <Scrollbars autoHeight autoHeightMin={50} autoHeightMax={150}>
                    {results.length !== 0 ? (
                        results.map((item) => (
                            <DropdownItem
                                key={item.value}
                                onMouseDown={() => {
                                    setInputValue(item.label.replace(/"/g, ''));
                                    setSelected(item);
                                }}
                            >
                                {item.label}
                            </DropdownItem>
                        ))
                    ) : (
                        <NotFound>Nenhum resultado encontrado</NotFound>
                    )}
                </Scrollbars>
            </PopperContainer>
        </>
    );
};

export default PopperSelect;

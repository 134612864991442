import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import MedicalUnit from './MedicalUnit';

import MessagePrompt from '../../Profile/MessagePrompt';
import { ModalContext } from '../../../contexts/ModalContext';

import { getMedicalUnits } from '../../../services/booking';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { APP_URLS } from '../../../urls';

const SelectMedicalUnit = ({ browserHistory, bookingOptions, addOptions }) => {
    const { handleModal, setCloseButton } = useContext(ModalContext);
    const { setLoading } = useContext(GlobalContext);

    const [medicalUnits, setMedicalUnits] = useState(null);

    const { type, specialty_id } = useParams();

    const selectUnit = ({ id, ...info }) => {
        addOptions({
            unitInfo: {
                id,
                ...info,
            },
        });

        browserHistory.push(`/agendamento/${type}/${specialty_id}/${id}`);
    };

    const loadAvailableMedicalUnits = async () => {
        setLoading(true);
        setCloseButton(false);

        try {
            const res = await getMedicalUnits();
            setMedicalUnits(res);
            setLoading(false);
        } catch (error) {
            setLoading(false);

            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro durante a sua requisição."
                    errorAction={() => window.location.reload()}
                />,
            );
        }
    };

    useEffect(() => {
        if (!bookingOptions) return browserHistory.replace(APP_URLS.BOOKING);
        loadAvailableMedicalUnits();
    }, []);

    return (
        <div className="container py-8 max-w-xl mx-auto">
            {medicalUnits && (
                <>
                    <h1 className="text-2xl text-pink-600 font-medium text-center">
                        Selecione uma unidade
                    </h1>

                    {medicalUnits.map((item) => (
                        <MedicalUnit
                            key={item.id}
                            browserHistory={browserHistory}
                            unitDetails={item}
                            selectUnit={() => selectUnit(item)}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default SelectMedicalUnit;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import cn from 'classnames';

import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';

const FormField = ({ children, className, name, inputText }) => {
    const { isWrong, question, isDisabled } = useContext(HealthContext);
    const style = () => {
        let styles = '';
        if (!isWrong[name] && question.isTouched[name]) {
            styles = cn(styles, 'field--valid');
        }
        if (isWrong[name] && question.isTouched[name]) {
            styles = cn(styles, 'field--invalid');
        }
        styles = isDisabled ? 'field-disable' : cn(styles, 'field mt-1');
        return styles;
    };
    return (
        <div className={className}>
            <label className={cn(inputText || 'h-12', style())}>
                {children}
            </label>
        </div>
    );
};

export default FormField;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useModalContext } from '../../../contexts/ModalContext';
import { APP_URLS, BACKEND_URLS } from '../../../urls';
import { AnimatedGift } from '../../InvitePage';

const CardInviteFriends = ({ isLoggedIn }) => {
    const { isOpen } = useModalContext();

    return (
        <div className="bg-white rounded p-4 overflow-hidden border border-gray-500">
            <p className="text-xl text-teal-600 font-medium sm:w-3/4">
                Dê 90% de desconto para uma pessoa especial e fique isento de
                sua próxima mensalidade
            </p>
            <div className="flex">
                <div className="w-1/2 sm:w-3/4">
                    <Subtitle>
                        Entregue saúde para quem você ama: a sua indicação
                        recebe 90% de desconto na primeira mensalidade e você
                        fica isento de sua próxima mensalidade.
                    </Subtitle>
                </div>
                {!isOpen && (
                    <AnimationContainer className="w-1/2 sm:w-1/4 relative">
                        <AnimatedGift />
                    </AnimationContainer>
                )}
            </div>
            {isLoggedIn && (
                <Link
                    to={APP_URLS.INVITE_FRIENDS}
                    className="w-full sm:w-64 h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-medium tracking-widest mt-4 hover:bg-pink-700"
                >
                    Saiba mais
                </Link>
            )}
            {!isLoggedIn && (
                <a
                    href={BACKEND_URLS.__LOGIN_AND_REDIRECT(
                        BACKEND_URLS.SPA_HOME,
                    )}
                    className="w-full sm:w-64 h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-medium tracking-widest mt-4 hover:bg-pink-700"
                >
                    Acesse sua conta
                </a>
            )}
        </div>
    );
};

export default CardInviteFriends;

const Subtitle = styled.p`
    min-height: 120px;

    @media (min-width: 640px) {
        min-height: 70px;
    }
`;

const AnimationContainer = styled.div`
    top: -8px;

    @media (min-width: 640px) {
        top: -48px;
    }
`;

import React, { useContext } from 'react';
import { Line } from 'rc-progress';

import DoctorSmile from '../../assets/svg/doctorSmile.svg';

import { Button } from '../ui';
import ButtonQuestion from './ButtonQuestion';
import ModalQuestionnaire from './ModalQuestionnaire';
import MessageNurse from './MessageNurse';
import { HealthContext } from '../../containers/HealthQuestionnaire/HealthQuestionnaire';

const HealthQuestionnaire = () => {
    const {
        percente,
        setNextQuestion,
        handlebackQuestion,
        handleNextQuestion,
        question,
        onClickCheck,
        isOpenError,
        openError,
        msgError,
        messageNurse,
    } = useContext(HealthContext);

    return (
        <>
            {messageNurse && <MessageNurse />}
            <div
                className={`container relative border-gray-500 bg-white ${
                    question
                        ? question.message
                            ? 'max-w-2xl'
                            : 'max-w-lg'
                        : null
                }  mx-auto py-4 sm:my-4 sm:border sm:rounded`}
            >
                {question && (
                    <form onSubmit={handleNextQuestion}>
                        <Line
                            percent={percente}
                            strokeWidth="4"
                            strokeColor="#87ceeb"
                            trailWidth="4"
                            trailColor="rgba(220, 220, 220, .5)"
                        />
                        <h1 className="text-3xl font-sans-Barlow mt-4">
                            {question.title}
                        </h1>
                        <div className={`mt-2 relative z-10 `}>
                            {question.renderInput({
                                setNextQuestion,
                                onClickCheck,
                            })}
                        </div>
                        <div className="w-50">
                            {question.message ? (
                                <DoctorSmile
                                    width="9rem"
                                    height="10rem"
                                    className="doctor-smile"
                                />
                            ) : null}
                        </div>
                        <div className="flex mt-4">
                            <div className="w-1/2 pr-2">
                                <Button
                                    color="white"
                                    className="w-full flex-shrink-0"
                                    onClick={handlebackQuestion}
                                    type="button"
                                >
                                    Anterior
                                </Button>
                            </div>
                            <ButtonQuestion
                                className="w-1/2 flex-shrink-0"
                                type={
                                    question.lastQuestion ? 'button' : 'submit'
                                }
                            >
                                {question.lastQuestion
                                    ? 'Finalizar'
                                    : 'Próximo'}
                            </ButtonQuestion>
                        </div>
                    </form>
                )}
                <ModalQuestionnaire
                    isOpen={isOpenError}
                    setOpen={openError}
                    label="finalizar Questionario"
                    title="Ocorreu um erro com sua requisição"
                    content={msgError}
                    msgError
                />
            </div>
        </>
    );
};

export default HealthQuestionnaire;

import React, { useContext } from 'react';
import cn from 'classnames';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';

const Textarea = ({ className, name, ...others }) => {
    const { onChangeText, valueInput, something, isDisabled } = useContext(
        HealthContext,
    );
    return (
        <textarea
            className={cn(
                'field-textarea',
                isDisabled
                    ? 'field-input-disable px-2 ml-2 block placeholder-white'
                    : className,
            )}
            onChange={onChangeText}
            name={name}
            value={valueInput[name]}
            onKeyDown={(e) => something(e)}
            disabled={isDisabled}
            {...others}
        />
    );
};

export default Textarea;

import React, {
    createContext,
    useMemo,
    useReducer,
    useContext,
    useEffect,
} from 'react';
import MessagePrompt from '../components/Profile/MessagePrompt';

import { setNotifications } from '../services/Notifications';

import { GlobalContext } from './GlobalContext';
import { useModalContext } from './ModalContext';

const initialState = {
    push_can_enable: false,
    push_enabled: 'none',
    push_supported: false,
    push_status: 'none',
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_NOTIFICATIONS': {
            return {
                ...state,
                ...action.values,
            };
        }
        /*
            Shows if push notifications are already enabled on the browser
        */
        case 'SET_IS_PUSH_ENABLED': {
            return {
                ...state,
                push_enabled: action.value,
            };
        }
        /*
            Shows if push notifications can be enabled on the browser
            Returns false if the user blocked via configs or denied the prompt
        */
        case 'SET_CAN_ENABLE': {
            return {
                ...state,
                push_can_enable: action.value,
            };
        }
        /*
            Shows if push notifications are supported by the browser
        */
        case 'SET_PUSH_SUPPORTED': {
            return {
                ...state,
                push_supported: action.value,
            };
        }
        case 'SET_PUSH_STATUS': {
            return {
                ...state,
                push_status: action.value,
            };
        }
        default:
    }
}

export const NotificationContext = createContext({
    ...initialState,
    changeNotifications: async () => null,
    setIsPushEnabled: () => null,
    handlePush: () => null,
    cannotInstall: () => null,
});

export const NotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { setLoading } = useContext(GlobalContext);

    const { handleModal } = useModalContext();

    // Actions

    /*
        Method to post the user preferences (not in use at the moment)
    */
    const changeNotifications = async (data) => {
        setLoading(true);

        try {
            const res = await setNotifications(data);
            dispatch({ type: 'SET_NOTIFICATIONS', values: res });
            setIsPushEnabled(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro com sua requisição e suas preferências de notificação não foram salvas"
                    errorAction={() => null}
                />,
            );
            throw new Error(error);
        }
    };

    const setCanEnable = (value) => dispatch({ type: 'SET_CAN_ENABLE', value });

    const setIsPushEnabled = (value) =>
        dispatch({ type: 'SET_IS_PUSH_ENABLED', value });

    const setIsPushSupported = (value) =>
        dispatch({ type: 'SET_PUSH_SUPPORTED', value });

    const setPushStatus = (value) =>
        dispatch({ type: 'SET_PUSH_STATUS', value });

    const handlePush = (enabled) => {
        if (enabled) {
            window.OneSignal.showNativePrompt();
            window.OneSignal.setSubscription(true);
            return setIsPushEnabled(enabled);
        }

        window.OneSignal.setSubscription(false);
        return setIsPushEnabled(enabled);
    };

    useEffect(() => {
        window.OneSignal.push(() => {
            const isPushSupported = window.OneSignal.isPushNotificationsSupported();
            window.OneSignal.isPushNotificationsEnabled((isEnabled) => {
                window.OneSignal.push([
                    'getNotificationPermission',
                    (permission) => {
                        const canEnable =
                            isPushSupported &&
                            !isEnabled &&
                            permission !== 'denied' &&
                            permission !== 'granted';

                        setPushStatus(permission);
                        setIsPushSupported(isPushSupported);
                        setIsPushEnabled(isEnabled);
                        setCanEnable(canEnable);
                    },
                ]);
            });

            window.OneSignal.on(
                'notificationPermissionChange',
                (permissionChange) => {
                    const currentPermission = permissionChange.to;
                    window.OneSignal.isPushNotificationsEnabled((isEnabled) => {
                        const canEnable =
                            isPushSupported &&
                            !isEnabled &&
                            currentPermission !== 'denied' &&
                            currentPermission !== 'granted';

                        if (currentPermission === 'denied') {
                            setCanEnable(canEnable);
                            setPushStatus(currentPermission);
                            setIsPushSupported(isPushSupported);
                            return setIsPushEnabled(false);
                        }

                        setCanEnable(canEnable);
                        setPushStatus(currentPermission);
                        setIsPushSupported(isPushSupported);
                        return setIsPushEnabled(true);
                    });
                },
            );
        });
    }, []);

    const providerValue = useMemo(
        () => ({
            ...state,
            changeNotifications,
            setIsPushEnabled,
            handlePush,
        }),
        [state],
    );

    return (
        <NotificationContext.Provider value={providerValue}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error(
            `useNotification must be used within a NotificationProvider`,
        );
    }
    return context;
};

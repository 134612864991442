/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel';
import { Icon } from '../ui';
import { DocumentContainer, PageControl } from './styles';
import { roundNumber } from '../../utils';

const maxZoom = 1.5;
const minZoom = 0.5;

const PDFViewer = ({
    file,
    width,
    height,
    controls = true,
    initialScale = 1.0,
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [boxHeight, setBoxHeight] = useState(100);

    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (width <= 425) return setBoxHeight(height - 280);
        setBoxHeight(height - 250);
        setScale(initialScale);
    }, [height, initialScale]);

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    const prevPage = () => {
        if (pageNumber === 1) return;
        setPageNumber(pageNumber - 1);
    };

    const nextPage = () => {
        if (pageNumber === numPages) return;
        setPageNumber(pageNumber + 1);
    };

    const zoomAction = (action) => {
        let newScale;
        switch (action) {
            case 'in':
                if (scale === maxZoom) return;
                newScale = roundNumber(scale + 0.1);
                return setScale(newScale);
            case 'out':
                if (scale === minZoom) return;
                newScale = roundNumber(scale - 0.1);
                return setScale(newScale);
            default:
                break;
        }
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <DocumentContainer maxHeight={boxHeight} maxWidth={width}>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} scale={scale} />
                </Document>
            </DocumentContainer>
            {controls && (
                <>
                    <PageControl>
                        <div className="flex">
                            <div className="flex">
                                <button
                                    type="button"
                                    onClick={() => zoomAction('out')}
                                >
                                    <Icon
                                        name="zoom-out-new"
                                        size={24}
                                        stroke="#D20E50"
                                    />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => zoomAction('in')}
                                >
                                    <Icon
                                        name="zoom-in-new"
                                        size={24}
                                        stroke="#D20E50"
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <button type="button" onClick={prevPage}>
                                <Icon
                                    name="arrow-left-new"
                                    size={20}
                                    className={cn(
                                        'fill-current',
                                        pageNumber > 1
                                            ? 'text-pink-600'
                                            : 'text-gray-600',
                                    )}
                                />
                            </button>
                            <span>
                                Pagina {pageNumber} de {numPages}
                            </span>
                            <button type="button" onClick={nextPage}>
                                <Icon
                                    name="arrow-right-new"
                                    size={20}
                                    className={cn(
                                        'fill-current',
                                        pageNumber < numPages
                                            ? 'text-pink-600'
                                            : 'text-gray-600',
                                    )}
                                />
                            </button>
                        </div>
                    </PageControl>
                    <p />
                </>
            )}
        </div>
    );
};

export default PDFViewer;

import React from 'react';
import cn from 'classnames';

import CheckRedSvg from '../../../assets/svg/CheckRed.svg';
import CheckWhiteSvg from '../../../assets/svg/correct.svg';

const Check = ({ colorSvg, children, className }) => {
    return (
        <div
            className={cn(
                'mt-6 gap-2 text-base flex items-center w-full',
                className,
            )}
        >
            <span className="w-6 mr-2">
                {colorSvg === 'white' ? (
                    <CheckWhiteSvg className="w-6" width="21" height="22" />
                ) : (
                    <CheckRedSvg className="w-6" width="21" height="22" />
                )}
            </span>
            <p>{children}</p>
        </div>
    );
};

export default Check;

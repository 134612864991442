import React, { useContext } from 'react';
import cn from 'classnames';

import { Input } from '../../ui';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';

const InputText = ({ className, name, number, ...rest }) => {
    const {
        onChangeText,
        valueInput,
        onlynumber,
        something,
        isDisabled,
    } = useContext(HealthContext);
    return (
        <Input
            className={cn(
                isDisabled
                    ? 'field-input-disable px-2 ml-2 block placeholder-white'
                    : className,
            )}
            onChange={onChangeText}
            name={name}
            type={number ? 'number' : 'text'}
            value={valueInput[name] || ''}
            onKeyPress={onlynumber}
            onKeyDown={(e) => something(e)}
            disabled={isDisabled}
            {...rest}
        />
    );
};

export default InputText;

import React, { useState } from 'react';

import { Icon } from '../../../ui';

const SettingsSelect = ({ options, onSelect }) => {
    const [open, setOpen] = useState(false);

    const onClick = () => {
        setOpen(false);
        onSelect();
    };

    return (
        <div className="relative">
            <button
                type="button"
                className="p-2 bg-gray-400 flex items-center cursor-pointer"
                onClick={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                style={{ width: 'min-content' }}
            >
                <Icon name="cog" size={25} />
                <Icon
                    name="arrow-down"
                    size={20}
                    className="fill-current text-gray-700"
                />
            </button>
            {open && (
                <div className="absolute select-none border-2 border-gray-400 mt-2">
                    {options.map(({ value, label }) => (
                        <span
                            onMouseDown={onClick}
                            key={value}
                            className="p-2 cursor-pointer bg-white hover:bg-gray-500"
                        >
                            {label}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SettingsSelect;

import React from 'react';
import styled from 'styled-components';

const Chip = styled.span`
    background-color: ${({ theme }) => theme.colors.primaryPink3};
    color: ${({ theme }) => theme.colors.white1};

    border-radius: 5px;
    padding: 5px 16px;

    cursor: pointer;
`;

const TimeChip = ({ time, ...rest }) => {
    return (
        <Chip className="assina-shadow-sm time-chip" {...rest}>
            {time.slice(0, -3)}
        </Chip>
    );
};

export default TimeChip;

import React from 'react';

import { formatDate, splitAndParseDate } from '../../../../utils/Dates';
import DoctorCard from '../DoctorCard';

const Schedule = ({ data: { data, date }, selectTime, specialty }) => {

    return (
        <>
            <h2 className="text-xl text-gray-900 my-4">
                {formatDate(splitAndParseDate(date), "eeee, dd 'de' MMMM")}
            </h2>

            {data &&
                data.map((item) => (
                    <DoctorCard
                        key={Math.floor(Math.random() * 1000)}
                        data={item}
                        selectTime={selectTime}
                        date={date}
                        specialty={specialty}
                    />
                ))}
        </>
    );
};

export default Schedule;

/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';

import Pagination from './Pagination';
import ScheduleItem from './ScheduleItem';

const Schedule = ({ data, setCurrentPage, totalPages, currentPage }) => {
    const divScroll = useRef(null);

    const nextPage = () => {
        setCurrentPage(Math.min(totalPages, currentPage + 1));
        if (currentPage !== totalPages) divScroll.current.scrollIntoView();
    };

    const previousPage = () => {
        setCurrentPage(Math.max(1, currentPage - 1));
        if (currentPage !== 1) divScroll.current.scrollIntoView();
    };

    return data ? (
        data.length ? (
            <>
                <div ref={divScroll} />
                <div className="mt-4 mb-6 space-y-4">
                    {data.map(([date, info]) => (
                        <ScheduleItem date={date} data={info} key={date} />
                    ))}
                    <div className="flex justify-center align-center">
                        <Pagination
                            nextPage={nextPage}
                            previousPage={previousPage}
                            currentPage={currentPage}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </>
        ) : (
            <div className="px-4 pt-2 pb-6 text-center">
                <span className="text-gray-900 font-medium rounded select-none">
                    Você ainda não recebeu nenhuma notificação
                </span>
            </div>
        )
    ) : null;
};

export default Schedule;

import React, { useEffect } from 'react';
import Icon from '../../ui/Icon';
import styled from 'styled-components';
import {
    Menu,
    MenuList,
    MenuButton,
    MenuItem
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";

const FloatMenuList = styled(MenuList)`
  padding: 0;
  position: absolute;
  left: -155px;
  top: 25px
`;

const FloatMenuItem = styled(MenuItem)`
  padding: 5px;
`;

const FloatMenu = ({
    type = 'exam',
    iconName = 'three-dots-pink',
    portal = false,
    onEdit = () => {},
    onDelete = () => {},
}) => {
    return (
        <Menu>
            <div className="z-50">
                <FloatMenuList portal={portal}>
                    {type === 'exam' && (
                        <FloatMenuItem onSelect={() => onEdit()}>
                            <div className="flex flex-row m-3 cursor-pointer js-edit-attach">
                                <div>
                                    <Icon
                                        name="edit"
                                        size={20}
                                        className="js-edit-attach"
                                    />
                                </div>
                                <div className="ml-4 text-base font-normal js-edit-attach">
                                    Editar {type === 'exam' ? 'exame' : 'anexo'}
                                </div>
                            </div>
                        </FloatMenuItem>
                    )}
                    <FloatMenuItem onSelect={() => onDelete()}>
                        <div className="flex flex-row m-3 cursor-pointer js-delete-attach">
                            <div>
                                <Icon
                                    name="delete"
                                    size={20}
                                    className="js-delete-attach"
                                />
                            </div>
                            <div className="ml-4 text-base font-normal js-delete-attach">
                                Deletar {type === 'exam' ? 'exame' : 'anexo'}
                            </div>
                        </div>
                    </FloatMenuItem>
                </FloatMenuList>
            </div>
            <MenuButton>
                <Icon
                    className="flex-none cursor-pointer z-10 js-three-dots-pink"
                    name={iconName}
                    size={18}
                />
            </MenuButton>
        </Menu>
    );
};

export default FloatMenu;

import React from 'react';
import cn from 'classnames';
import Spinner from '../Spinner';

const Button = ({
    children,
    color = 'pink',
    className,
    as = 'button',
    disabled,
    loading,
    style,
    ...others
}) => {
    const TagName = as;
    const colorClasses = {
        white: 'bg-white hover:bg-gray-400 text-pink-600',
        pink: 'bg-pink-600 hover:bg-pink-700 text-white',
        disabled: 'bg-gray-500 text-gray-600',
        outlined:
            'bg-white hover:bg-gray-400 text-pink-600 border-2 border-pink-600',
        green: 'bg-teal-600 hover:bg-teal-700 text-white',
    };
    return (
        <TagName
            {...others}
            className={cn(
                className,
                'h-12 cursor-pointer text-lg rounded-md text-center flex items-center justify-center font-medium tracking-widest focus:outline-none',
                disabled ? colorClasses.disabled : colorClasses[color],
            )}
            disabled={disabled || false}
            style={{ transition: 'all 0.2s ease', ...style }}
        >
            {loading ? <Spinner color="white" /> : children}
        </TagName>
    );
};

export default Button;

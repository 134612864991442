import React, { useContext } from 'react';

import { Button } from '../../ui';
import { Content, SpanRed, SvgBackgroundHappy, Title } from '../Styles';
import { ContextTemporaryPassword } from '../../../containers/TemporaryPassword/TemporaryPassword';
import { APP_URLS } from '../../../urls';

const PasswordFinished = () => {
    const { history } = useContext(ContextTemporaryPassword);
    return (
        <Content className="content-message">
            <SvgBackgroundHappy />
            <Title className="font-normal h1Style mt-4">
                Sua senha foi cadastrada <br /> com <SpanRed>sucesso</SpanRed>!
            </Title>
            <div className="w-1/2 pr-2">
                <Button
                    onClick={() => history.replace(APP_URLS.HOME)}
                    className="px-8 mt-8 flex items-center w-3/4 flex-shrink-0"
                >
                    continuar
                </Button>
            </div>
        </Content>
    );
};

export default PasswordFinished;

import moment from 'moment';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

export const getToken = () => {
    return Cookies.get('api_token');
};

export const generateJWT = (payload) =>
    jwt.sign(payload, process.env.JWT_SECRET, {
        expiresIn: '1d',
    });

// Date formaters

export const formatComplete = (data) => {
    let out = '';

    out = data.route;

    out +=
        data.street_number_user || data.street_number
            ? data.street_number_user
                ? `, ${data.street_number_user}`
                : `, ${data.street_number}`
            : '';

    out += data.complement ? ` - ${data.complement}` : '';
    out += data.political ? ` - ${data.political}` : '';
    out += data.postal_code ? ` - ${data.postal_code}` : '';

    out += data.city ? ` - ${data.city.nome}` : '';

    return out;
};

export const formatToStandard = (date) => {
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

export const formatDate = (date) => {
    return moment(date, 'DD-MM-YYYY').format('HH:mm');
};

export const formatToData = (date) => {
    return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
};

export const dateNow = () => {
    const date = moment(new Date())
        .locale('pt-br')
        .format('dddd, DD [de] MMMM YYYY');
    return date.charAt(0).toUpperCase() + date.slice(1);
};

export const fullDate = (date) => {
    const formated = moment(date, 'DD-MM-YYYY')
        .locale('pt-br')
        .format('dddd, DD [de] MMMM [de] YYYY');
    return formated.charAt(0).toUpperCase() + formated.slice(1);
};

export const shortFormat = (date) =>
    moment(date).format('DD MMM YYYY').toUpperCase();

export const formatAgeToString = (now, user) => {
    const years = now.diff(user, 'years');
    user.add(years, 'years');

    const months = now.diff(user, 'months');
    user.add(months, 'months');

    const days = now.diff(user, 'days');
    user.add(days, 'days');

    return `${years} anos, ${months} meses, ${days} dias`;
};

/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import RoundButton from './RoundButton';
import { Icon, ShineEffect } from '../../ui';

import {
    Wrapper,
    Container,
    Title,
    Diretionals,
    LeftPainel,
    SkeletonContainer,
    ChartSkeleton,
    MenuItemSkeleton,
} from './Styles';

import Details from './Partials/Details';
import History from './Partials/History';
import Success from './Partials/Success';
import HowTo from './Partials/HowTo';
import AddRecord from './Partials/AddRecord';

import { getType } from '../../../containers/HealthMonitoring/HealthMonitoring';

const Skeleton = () => (
    <SkeletonContainer>
        <ChartSkeleton>
            <ShineEffect round="30px" />
        </ChartSkeleton>
        <div className="flex mt-6 mx-auto justify-between">
            <MenuItemSkeleton>
                <ShineEffect round="30px" />
            </MenuItemSkeleton>
            <MenuItemSkeleton>
                <ShineEffect round="30px" />
            </MenuItemSkeleton>
        </div>
    </SkeletonContainer>
);

export default ({
    subject,
    resetSubject,
    setShowAddRecord,
    measureTypes,
    width,
    subjectData,
    addHistory,
    hasNoRecords,
    loading,
}) => {
    const [index, setIndex] = useState();
    const [fixed, setFixed] = useState(false);

    // Views states
    const [showHistory, setShowHistory] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showHowTo, setShowHowTo] = useState(false);

    // Directionals
    const [first, setFirst] = useState(false);
    const [last, setLast] = useState(false);

    const type = getType(subject);

    const getIndex = (name) => {
        setIndex(measureTypes.findIndex((item) => item.name === name));
    };

    useEffect(() => {
        getIndex(subject);
    }, []);

    useEffect(() => {
        if (index === 0) {
            return setFirst(true);
        }
        if (index === 9) {
            return setLast(true);
        }
        setFirst(false);
        setLast(false);
    }, [index]);

    const prev = () => {
        const newSub = measureTypes[index - 1].name;
        if (!newSub) return;
        getIndex(newSub);
        resetSubject(newSub);
    };

    const next = () => {
        const newSub = measureTypes[index + 1].name;
        if (!newSub) return;
        getIndex(newSub);
        resetSubject(newSub);
    };

    const seeHistory = () => {
        setFixed(true);
        setShowHistory('a');
    };

    const resetAll = () => {
        setShowHowTo(false);
        setShowSuccess(false);
    };

    const handleAddHistory = (data) => {
        setShowSuccess(true);
        addHistory(data);
    };

    return (
        <Wrapper>
            <Container index={index} width={width}>
                <Diretionals first={first}>
                    <Icon name="back" size={27} onClick={prev} />
                </Diretionals>
                <div className="w-full px-6 flex flex-col justify-center">
                    <div className="flex justify-between items-center">
                        <Title>{subject}</Title>
                        {width < 769 && (
                            <Icon
                                name="arrow-left"
                                className="fill-current text-gray-100"
                                onClick={() => resetSubject()}
                            />
                        )}
                    </div>
                    {loading ? (
                        <Skeleton />
                    ) : hasNoRecords ? (
                        <div className="w-full pt-20 flex flex-col text-center items-center justify-center text-gray-100 text-xl font-medium">
                            <span>
                                Você não tem nenhum registro nesta categoria :(
                            </span>
                            <span>Adicione logo ao lado!</span>
                        </div>
                    ) : !subjectData ? null : showHistory ? (
                        <History
                            historyItems={subjectData.data}
                            width={width}
                            index={index}
                            goBack={() => setShowHistory(false)}
                            type={type}
                        />
                    ) : (
                        <Details
                            seeHistory={seeHistory}
                            subject={subject}
                            subjectData={subjectData}
                        />
                    )}
                    {width < 769 && (
                        <div className="flex justify-center">
                            <RoundButton
                                index={index}
                                fixed={fixed}
                                onClick={() => setShowAddRecord(true)}
                            >
                                <Icon name="plus" />
                            </RoundButton>
                        </div>
                    )}
                </div>
                <Diretionals flip last={last}>
                    <Icon name="back" size={27} onClick={next} />
                </Diretionals>
            </Container>
            <LeftPainel>
                <div>
                    {showHowTo ? (
                        <HowTo
                            subject={subject}
                            backToBeggining={resetAll}
                            measureTypes={measureTypes}
                            width={width}
                        />
                    ) : showSuccess ? (
                        <Success backToBeggining={resetAll} width={width} />
                    ) : (
                        <AddRecord
                            addHistory={handleAddHistory}
                            setHowTo={() => setShowHowTo(true)}
                            width={width}
                            subject={subject}
                            type={type}
                        />
                    )}
                </div>
            </LeftPainel>
        </Wrapper>
    );
};

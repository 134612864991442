/* eslint-disable no-plusplus */
import React from 'react';

import {
    QuestionnaireSelect,
    InputTextarea as Textarea,
    InputDate,
    InputCheckbox as InputCheck,
    InputText,
    FormField,
    RadioInput as InputRadio,
} from '../components/HealthQuestionnaire';
import { Message } from '../styles/global';

const generateOptionsYears = (init, end) => {
    const options = [];
    for (let i = init; i <= end; i++) {
        options.push(i);
    }
    return options;
};

const questions = [
    {
        id: 1,
        title: 'Qual a sua altura em cm?',
        isTouched: {
            altura: false,
        },
        error: {
            altura: 'Preencha a sua altura em centímetros entre 0 e 300',
        },
        validate: ({ value }) => {
            return value !== '';
        },
        renderInput: () => (
            <FormField name="altura">
                <InputText
                    placeholder="Altura (em cm)"
                    className="field-input"
                    min="0"
                    max="300"
                    required
                    name="altura"
                />
            </FormField>
        ),
    },
    {
        id: 2,
        title: 'Qual o seu peso em kg?',
        isTouched: {
            peso: false,
        },
        error: {
            peso: 'O campo deve ter um numero inteiro entre 0 e 200',
        },
        validate: ({ value }) => {
            return value > 0 && value <= 200 && value !== '';
        },
        renderInput: () => (
            <FormField name="peso">
                <InputText
                    placeholder="Peso (em Kg)"
                    className="field-input"
                    min="0"
                    max="300"
                    required
                    name="peso"
                />
            </FormField>
        ),
    },
    {
        id: 3,
        title:
            'Qual a medida de sua cintura? Você pode colocar uma fita métrica ao redor da parte mais estreita do seu abdômen, que geralmente fica acima do umbigo.',
        isTouched: {
            cintura: false,
        },
        error: {
            cintura:
                'O campo deve ser preenchido com um inteiro entre 24 e 254',
        },
        validate: ({ value }) => {
            return value >= 24 && value <= 254 && value !== '';
        },
        renderInput: ({ onClickCheck }) => (
            <>
                <FormField name="cintura">
                    <InputText
                        placeholder="Circunferência abdominal (em cm)"
                        className="field-input"
                        required
                        min="24"
                        max="254"
                        name="cintura"
                    />
                </FormField>
                <InputCheck
                    id="naosei"
                    name="naosei"
                    label="Não sei"
                    value="Não sei"
                    onClick={onClickCheck}
                />
            </>
        ),
    },
    {
        id: 4,
        title:
            'Como você avaliaria a sua saúde física em comparação com outras pessoas de sua idade?',
        renderInput: () => (
            <>
                <InputRadio
                    id="excelente"
                    name="saude"
                    label="Excelente"
                    value="excelente"
                />
                <InputRadio
                    id="muitoboa"
                    name="saude"
                    label="Muito boa"
                    value="Muito boa"
                />
                <InputRadio id="boa" name="saude" label="Boa" value="Boa" />
                <InputRadio
                    id="razoavel"
                    name="saude"
                    label="Razoavel"
                    value="Razoavel"
                />
                <InputRadio id="ruim" name="saude" label="Ruim" value="Ruim" />
            </>
        ),
    },
    {
        id: 5,
        title: 'Você sabe a sua pressão arterial?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="pressao"
                        label="Sim"
                        onClick={() => {
                            setNextQuestion(6);
                        }}
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="pressao"
                        label="Não"
                        onClick={() => {
                            setNextQuestion(7);
                        }}
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 6,
        title:
            'Qual é a sua pressão arterial média em mmHg? Exemplo: 120 (Sistólica) x 80 (Diastólica) mmHg',
        isTouched: {
            sistolica: false,
            diastolica: false,
        },
        error: {
            sistolica: 'O campo esta invalido',
            diastolica: 'O campo esta invalido',
        },
        validate: ({ value }) => {
            return value > 0 && value <= 300 && value !== '';
        },
        renderInput: () => (
            <>
                <FormField name="sistolica">
                    <InputText
                        placeholder="Sistólica (mmHg)"
                        className="field-input"
                        min="0"
                        name="sistolica"
                    />
                </FormField>
                <FormField name="diastolica">
                    <InputText
                        placeholder="Diastólica (mmHg)"
                        className="field-input"
                        min="0"
                        name="diastolica"
                    />
                </FormField>
            </>
        ),
    },
    {
        id: 7,
        title:
            'Você considera a sua pressão arterial alta, média ou baixa? (obrigatório)',
        renderInput: () => (
            <>
                <InputRadio
                    id="alta"
                    name="pressaoarterial"
                    label="Alta"
                    value="Alta"
                />
                <InputRadio
                    id="media"
                    name="pressaoarterial"
                    label="Média"
                    value="Media"
                />
                <InputRadio
                    id="baixa"
                    name="pressaoarterial"
                    label="Baixa"
                    value="Baixa"
                />
                <InputRadio
                    id="naosei"
                    name="pressaoarterial"
                    label="Não sei"
                    value="Não sei"
                />
            </>
        ),
    },
    {
        id: 8,
        title: 'Você sabe o seu nível de colesterol total? (obrigatório)',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="colesterol"
                        label="Sim"
                        onClick={() => {
                            setNextQuestion(9);
                        }}
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="colesterol"
                        label="Não"
                        onClick={() => {
                            setNextQuestion(10);
                        }}
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 9,
        title: 'Qual é o seu nível de colesterol total em mg/dL? (obrigatório)',
        isTouched: {
            colesterolMgdl: false,
        },
        error: {
            colesterolMgdl:
                'O campo deve ser preenchido com um inteiro de 24 a 430',
        },
        validate: ({ value }) => {
            return value > 0 && value <= 300 && value !== '';
        },
        renderInput: () => {
            return (
                <FormField name="colesterolMgdl">
                    <InputText
                        placeholder="Colesterol total (em mg/dL)"
                        className="field-input"
                        min="0"
                        name="colesterolMgdl"
                    />
                </FormField>
            );
        },
    },
    {
        id: 10,
        title:
            'Se você não tiver certeza, estime o seu nível de colesterol total como alto, médio ou baixo. (obrigatório)',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="alta"
                        name="colesterol"
                        label="Alta"
                        value="Alta"
                    />
                    <InputRadio
                        id="media"
                        name="colesterol"
                        label="Média"
                        value="Media"
                    />
                    <InputRadio
                        id="baixa"
                        name="colesterol"
                        label="Baixa"
                        value="Baixa"
                    />
                    <InputRadio
                        id="naosei"
                        name="colesterol"
                        label="Não sei"
                        value="Não sei"
                    />
                </>
            );
        },
    },
    {
        id: 11,
        title:
            'Você sabe o seu nível de colesterol HDL (considerado bom para saúde)? (obrigatório)',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="colesterol"
                        label="Sim"
                        onClick={() => {
                            setNextQuestion(12);
                        }}
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="colesterol"
                        label="Não"
                        onClick={() => {
                            setNextQuestion(13);
                        }}
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 12,
        title: 'Qual é o seu nível de colesterol HDL em mg/dL? (obrigatório)',
        isTouched: {
            colesterolHdl: false,
        },
        error: {
            colesterolHdl:
                'O campo deve ser preenchido com um inteiro entre 5 e 200',
        },
        validate: ({ value }) => {
            return value >= 5 && value <= 300 && value !== '';
        },
        renderInput: () => {
            return (
                <FormField name="colesterolHdl">
                    <InputText
                        placeholder="Colesterol HDL (em mg/dL)"
                        className="field-input"
                        min="0"
                        name="colesterolHdl"
                    />
                </FormField>
            );
        },
    },
    {
        id: 13,
        title:
            'Se você não tiver certeza, estime o seu nível de colesterol total como alto, médio ou baixo. (obrigatório)',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="alto"
                        name="colesterol"
                        label="Alta"
                        value="Alta"
                    />
                    <InputRadio
                        id="medio"
                        name="colesterol"
                        label="Média"
                        value="Media"
                    />
                    <InputRadio
                        id="baixo"
                        name="colesterol"
                        label="Baixa"
                        value="Baixa"
                    />
                    <InputRadio
                        id="naosei"
                        name="colesterol"
                        label="Não sei"
                        value="Não sei"
                    />
                </>
            );
        },
    },
    {
        id: 14,
        title:
            'Agora, pensando sobre a sua vida em geral, quanto você concorda ou discorda da frase a seguir? Minha saúde física é quase perfeita.',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="concordo"
                        name="pergunta"
                        label="Concordo"
                        value="Concordo"
                    />
                    <InputRadio
                        id="concordoparcialmente"
                        name="pergunta"
                        label="Concordo parcialmente"
                        value="Concordo parcialmente"
                    />
                    <InputRadio
                        id="discordo"
                        name="pergunta"
                        label="Discordo"
                        value="Discordo"
                    />
                </>
            );
        },
    },
    {
        id: 15,
        title:
            'Você tem algum problema de saúde que te impeça de fazer coisas que as pessoas da sua idade fazem normalmente? (obrigatório)',
        renderInput: () => {
            return (
                <>
                    <InputRadio id="sim" name="saude" label="Sim" value="Sim" />
                    <InputRadio
                        id="nao"
                        name="saude"
                        label="Não"
                        value="Não"
                        className="input-radio"
                    />
                    <InputRadio
                        id="naosei"
                        name="saude"
                        label="Não sei"
                        value="Não sei"
                        className="input-radio"
                    />
                </>
            );
        },
    },
    {
        id: 16,
        title: 'Você tem asma? (obrigatório)',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio id="sim" name="asma" label="Sim" value="Sim" />
                    <InputRadio
                        id="nao"
                        name="asma"
                        label="Não"
                        onClick={() => {
                            setNextQuestion(21);
                        }}
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 17,
        title:
            'No caso de você já ter feito o exame VEF1 (Volume Expiratório Forçado) medido, insira o resultado mais recente para o qual o seu médico não provocou asma usando um medicamento antes do teste.',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="baixo"
                        name="exame"
                        label="Abaixo 73% (grave)"
                        value="Abaixo 73% (grave)"
                    />
                    <InputRadio
                        id="moderado"
                        name="exame"
                        label="73 - 86% (moderado)"
                        value="73 - 86% (moderado)"
                    />
                    <InputRadio
                        id="leve"
                        name="exame"
                        label="87 - 96% (leve)"
                        value="87 - 96% (leve)"
                    />
                    <InputRadio
                        id="raro"
                        name="exame"
                        label="97 - 108% (raro)"
                        value="97 - 108% (raro)"
                    />
                    <InputRadio
                        id="acima"
                        name="exame"
                        label="Acima 108%"
                        value="Acima 108%"
                    />
                    <InputRadio
                        id="naosei"
                        name="exame"
                        label="Não sei"
                        value="Não sei"
                    />
                    <InputRadio
                        id="naofiz"
                        name="exame"
                        label="Não fiz"
                        value="Não fiz"
                    />
                </>
            );
        },
    },
    {
        id: 18,
        title:
            'Como você avaliaria a gravidade de seus sintomas de asma? (obrigatório)',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="grave"
                        name="asma"
                        label="Grave"
                        value="Grave"
                    />
                    <InputRadio
                        id="moderado"
                        name="asma"
                        label="Moderado"
                        value="Moderado"
                    />
                    <InputRadio
                        id="leve"
                        name="asma"
                        label="Leve"
                        value="Leve"
                    />
                    <InputRadio
                        id="raro"
                        name="asma"
                        label="Raro"
                        value="Raro"
                    />
                    <InputRadio
                        id="nenhum"
                        name="asma"
                        label="Nenhum"
                        value="Nenhum"
                    />
                </>
            );
        },
    },
    {
        id: 19,
        title:
            'Você sabe o seu débito expiratório máximo instantâneo? (obrigatório)',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="debito"
                        label="Sim"
                        onClick={() => {
                            setNextQuestion(20);
                        }}
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="debito"
                        label="Não"
                        value="Não"
                        onClick={() => {
                            setNextQuestion(21);
                        }}
                    />
                </>
            );
        },
    },
    {
        id: 20,
        title:
            'Qual o valor de seu débito expiratório máximo instantâneo mais recente?',
        isTouched: {
            expiratorio: false,
        },
        error: {
            expiratorio:
                'O campo deve ser preenchido com um inteiro entre 0 e 1000',
        },
        validate: ({ value }) => {
            return value > 0 && value <= 1000 && value !== '';
        },
        renderInput: () => {
            return (
                <FormField name="expiratorio">
                    <InputText
                        placeholder="Em L/min"
                        className="field-input"
                        min="0"
                        name="expiratorio"
                    />
                </FormField>
            );
        },
    },
    {
        id: 21,
        title: 'Você tem diabetes ?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        onClick={() => {
                            setNextQuestion(23);
                        }}
                        id="Sim1"
                        name="diabetes"
                        label="Sim, Diabetes tipo 1"
                        value="Sim, diabetes tipo 1"
                    />
                    <InputRadio
                        onClick={() => {
                            setNextQuestion(22);
                        }}
                        id="sim2"
                        name="diabetes"
                        label="Sim, Diabetes tipo 2"
                        value="Sim, Diabetes tipo 2"
                    />
                    <InputRadio
                        onClick={() => {
                            setNextQuestion(25);
                        }}
                        id="prediabetes"
                        name="diabetes"
                        label="Pré-diabetes"
                        value="Pre-diabetes"
                    />
                    <InputRadio
                        onClick={() => {
                            setNextQuestion(25);
                        }}
                        id="nao"
                        name="diabetes"
                        label="Não"
                        value="Nao"
                    />
                </>
            );
        },
    },
    {
        id: 22,
        title: 'Você toma insulina para o diabetes tipo 2?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="insulina"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="insulina"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 23,
        title: 'Em que ano você foi diagnosticado com diabetes?',
        selectNumbers: 1,
        options: () => generateOptionsYears(1996, 2020),
        renderInput: () => {
            return <QuestionnaireSelect name="Diabetes" id="diabetes" />;
        },
    },
    {
        id: 24,
        title: 'Qual é a media do seu nivel de hemóglobina glicada?',
        isTouched: {
            hemoglobina: false,
        },
        error: {
            hemoglobina:
                'O campo deve ser preenchido com um inteiro entre 300 e 700',
        },
        validate: ({ value }) => {
            return value > 0 && value <= 1000 && value !== '';
        },
        renderInput: ({ onClickCheck }) => {
            return (
                <>
                    <FormField name="hemoglobina">
                        <InputText
                            placeholder="Hemoglobina Glicada (em %)"
                            className="field-input"
                            min="0"
                            name="hemoglobina"
                        />
                    </FormField>
                    <InputCheck
                        id="naosei"
                        name="naosei"
                        label="Não sei"
                        value="Não sei"
                        className="mt-2"
                        onClick={onClickCheck}
                    />
                </>
            );
        },
    },
    {
        id: 25,
        title:
            'Você utiliza ou utilizou cigarro eletrônico, dispositivos vaporizadores ou produtos que contenham tabaco regularmente (por três meses ou mais)?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="cigarro"
                        label="Sim, sou fumante"
                        onClick={() => {
                            setNextQuestion(27);
                        }}
                        value="Sim, sou fumante"
                    />
                    <InputRadio
                        id="nao"
                        name="cigarro"
                        label="Não"
                        onClick={() => {
                            setNextQuestion(26);
                        }}
                        value="Não"
                    />
                    <InputRadio
                        id="simparei"
                        name="cigarro"
                        label="Sim, mas parei"
                        onClick={() => {
                            setNextQuestion(31);
                        }}
                        value="Sim, mas parei"
                    />
                </>
            );
        },
    },
    {
        id: 26,
        title:
            'Quanto tempo você diria que foi exposto à fumaça de cigarro/tabaco, em sua residência ou trabalho, até agora?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="nunca"
                        name="tempoexposto"
                        label="Nunca"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="Nunca"
                    />
                    <InputRadio
                        id="0a10"
                        name="tempoexposto"
                        label="0 a 10 anos"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="0 a 10 anos"
                    />
                    <InputRadio
                        id="11a19"
                        name="tempoexposto"
                        label="11 a 19 anos"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="11 a 19 anos"
                    />
                    <InputRadio
                        id="19a27"
                        name="tempoexposto"
                        label="19 a 27"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="19 a 27"
                    />
                    <InputRadio
                        id="acima"
                        name="tempoexposto"
                        label="Acima"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="Acima"
                    />
                </>
            );
        },
    },
    {
        id: 27,
        title: 'Que tipos de tabaco você usa atualmente?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputCheck
                        id="cigarro"
                        name="cigarro"
                        label="Cigarros"
                        value="Cigarros"
                    />
                    <InputCheck
                        id="cigarroeletro"
                        name="cigarroeletro"
                        label="Cigarros eletrônicos ou narguilé (ou similares)"
                        onClick={() => {
                            setNextQuestion(29);
                        }}
                        value="Cigarros eletrônicos ou narguilé (ou similares)"
                    />
                    <InputCheck
                        id="charuto"
                        name="charuto"
                        label="Charuto"
                        value="Charuto"
                    />
                    <InputCheck
                        id="cachimbo"
                        name="cachimbo"
                        label="Cachimbos"
                        value="Cachimbos"
                    />
                    <InputCheck
                        id="mascafumo"
                        name="mascafumo"
                        label="Masca fumo"
                        value="Masca fumo"
                    />
                </>
            );
        },
    },
    {
        id: 28,
        title:
            'Como você descreve o seu consumo diário de cigarros/charutos/cachimbos?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputCheck
                        id="menos"
                        name="menos"
                        label="menos de 1/2 maço"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="menos de 1/2 maço"
                    />
                    <InputCheck
                        id="1/2maço"
                        name="1/2maço"
                        label="1/2 maço"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="1/2 maço"
                    />
                    <InputCheck
                        id="1maço"
                        name="1maço"
                        label="1 maço"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="1 maço"
                    />
                    <InputCheck
                        id="11/2maço"
                        name="11/2maço"
                        label="1 1/2 maço"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="1 1/2 maço"
                    />
                    <InputCheck
                        id="2maço"
                        name="2maço"
                        label="2 maços"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="2 maços"
                    />
                    <InputCheck
                        id="3maço"
                        name="3maço"
                        label="3 ou mais maços"
                        onClick={() => {
                            setNextQuestion(37);
                        }}
                        value="3 ou mais maços"
                    />
                </>
            );
        },
    },
    {
        id: 29,
        title:
            'Você utiliza cigarro eletrônico ou outros dispositivos vaporizadores diariamente?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="cigarroeletronico"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="cigarroeletronico"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 30,
        title: 'Em que ano você começou a utilizar esses dispositivos?',
        options: () => generateOptionsYears(2000, 2020),
        selectNumbers: 1,
        renderInput: ({ setNextQuestion }) => {
            return (
                <QuestionnaireSelect
                    name="dispositivos"
                    id="dispositivos"
                    onClick={() => {
                        setNextQuestion(37);
                    }}
                />
            );
        },
    },
    {
        id: 31,
        title: 'Que tipos de tabaco você usava?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputCheck
                        id="cigarro"
                        name="tabaco"
                        label="Cigarros"
                        value="Cigarros"
                    />
                    <InputCheck
                        id="cigarroeletro"
                        name="tabaco"
                        label="Cigarros eletrônicos ou narguilé (ou similares)"
                        onClick={() => {
                            setNextQuestion(34);
                        }}
                        value="Cigarros eletrônicos ou narguilé (ou similares)"
                    />
                    <InputCheck
                        id="charuto"
                        name="tabaco"
                        label="Charuto"
                        value="Charuto"
                    />
                    <InputCheck
                        id="cachimbo"
                        name="tabaco"
                        label="Cachimbos"
                        value="Cachimbos"
                    />
                    <InputCheck
                        id="mascafumo"
                        name="tabaco"
                        label="Masca fumo"
                        value="Masca fumo"
                    />
                </>
            );
        },
    },
    {
        id: 32,
        title:
            'Quando você fumava, qual era o seu consumo diário de cigarros/charutos/cachimbos?',
        isTouched: {
            quantidade: false,
            cigarroTempo: false,
        },
        error: {
            quantidade:
                'O campo deve ser preenchido com um inteiro entre 0 e 1',
            cigarroTempo:
                'O campo deve ser preenchido com um inteiro entre 0 e 1',
        },
        validate: ({ value }) => {
            return value > 0 && value <= 1000 && value !== '';
        },
        renderInput: () => {
            return (
                <>
                    <FormField name="Quantidades:">
                        <InputText
                            placeholder="Menos que 1 ate 10"
                            className="field-input"
                            min="0"
                            name="quantidade"
                        />
                    </FormField>
                    <FormField name="Eu fumei por um total de:">
                        <InputText
                            placeholder="Numero inteiro"
                            className="field-input"
                            min="0"
                            name="cigarroTempo"
                        />
                    </FormField>
                </>
            );
        },
    },
    {
        id: 33,
        title: 'Quando você parou de fumar cigarros/charutos/cachimbos?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <InputDate
                    placeholder="Ano"
                    min="0"
                    name="tempocigarro"
                    type="date"
                    onClick={() => {
                        setNextQuestion(37);
                    }}
                />
            );
        },
    },
    {
        id: 34,
        title:
            'Você utilizava cigarro eletrônico ou outros dispositivos vaporizadores diariamente?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="cigarroeletronico"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="cigarroeletronico"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 35,
        title: 'Por quanto tempo utilizou esses dispositivos?',
        renderInput: () => {
            return (
                <InputDate
                    placeholder="Ano"
                    min="0"
                    name="tempoutilizou"
                    type="date"
                />
            );
        },
    },
    {
        id: 36,
        title: 'Quando Parou de utiizar esses dispositivos?',
        renderInput: () => {
            return (
                <InputDate
                    placeholder="Ano"
                    min="0"
                    name="tempoutilizou"
                    type="date"
                />
            );
        },
    },
    {
        id: 37,
        title:
            'Durante um dia normal, quantas doses de bebidas alcoólicas você consome?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="nunca"
                        name="bebidas"
                        label="Eu nunca consumi álcool ou costumava beber ocasionalmente, mas parei"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Eu nunca consumi álcool ou costumava beber ocasionalmente, mas parei"
                    />
                    <InputRadio
                        id="costumava"
                        name="bebidas"
                        label="Eu costumava consumir álcool em excesso ou sem limites, mas parei de beber"
                        onClick={() => {
                            setNextQuestion(39);
                        }}
                        value="Eu costumava consumir álcool em excesso ou sem limites, mas parei de beber"
                    />
                    <InputRadio
                        id="menode1"
                        name="bebidas"
                        label="Eu consumo menos de 1 dose por mês"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Eu consumo menos de 1 dose por mês"
                    />
                    <InputRadio
                        id="maisde1"
                        name="bebidas"
                        label="Eu consumo mais de 1 dose por mês, mas menos de 1 por dia"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Eu consumo mais de 1 dose por mês, mas menos de 1 por dia"
                    />
                    <InputRadio
                        id="1dose"
                        name="bebidas"
                        label="Eu consumo 1 dose por dia"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Eu consumo 1 dose por dia"
                    />
                    <InputRadio
                        id="2doses"
                        name="bebidas"
                        label="Eu consumo 2 doses por dia"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Eu consumo 2 doses por dia"
                    />
                    <InputRadio
                        id="3doses"
                        name="bebidas"
                        label="Eu consumo 3 doses por dia"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Eu consumo 3 doses por dia"
                    />
                    <InputRadio
                        id="4doses"
                        name="bebidas"
                        label="Eu consumo 4 doses por dia"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Eu consumo 4 doses por dia"
                    />
                    <InputRadio
                        id="5doses"
                        name="bebidas"
                        label="Eu consumo 5 doses por dia"
                        onClick={() => {
                            setNextQuestion(38);
                        }}
                        value="Eu consumo 5 doses por dia"
                    />
                    <InputRadio
                        id="6doses"
                        name="bebidas"
                        label="Eu consumo 6 ou mais doses por dia"
                        onClick={() => {
                            setNextQuestion(38);
                        }}
                        value="Eu consumo 6 ou mais doses por dia"
                    />
                    <InputRadio
                        id="regularmante"
                        name="bebidas"
                        label="Eu não consumo regularmente, mas quando o faço, quase sempre tomo mais de 5 doses"
                        onClick={() => {
                            setNextQuestion(38);
                        }}
                        value="Eu não consumo regularmente, mas quando o faço, quase sempre tomo mais de 5 doses"
                    />
                </>
            );
        },
    },
    {
        id: 38,
        title:
            'Com que frequência você consome 5 ou mais doses em uma única ocasião?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="menos"
                        name="frequencia"
                        label="Menos que 1 vez por mês"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Menos que 1 vez por mês"
                    />
                    <InputRadio
                        id="1a3"
                        name="frequencia"
                        label="1 a 3 vezes por mês"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="1 a 3 vezes por mês"
                    />
                    <InputRadio
                        id="menos1"
                        name="frequencia"
                        label="Ao menos 1 vez por semana"
                        onClick={() => {
                            setNextQuestion(40);
                        }}
                        value="Ao menos 1 vez por semana"
                    />
                </>
            );
        },
    },
    {
        id: 39,
        title: 'Em que ano voce parou de beber? (obrigatório)',
        options: () => generateOptionsYears(2000, 2020),
        selectNumbers: 1,
        renderInput: () => {
            return <QuestionnaireSelect name="beber" id="beber" />;
        },
    },
    {
        id: 40,
        title:
            'Você já foi diagnosticado com alguma das seguintes condições? Marque todos que se aplicam.',
        renderInput: ({ onClickCheck }) => {
            return (
                <>
                    <InputCheck
                        id="angila"
                        name="diagnostico"
                        label="Angila (Dor no peito)"
                        value="Angila (Dor no peito)"
                    />
                    <InputCheck
                        id="fibrilacao"
                        name="diagnostico"
                        label="Fibrilação atrial"
                        value="Fibrilação atrial"
                    />
                    <InputCheck
                        id="bronquite"
                        name="diagnostico"
                        label="Bronquite crônica"
                        value="Bronquite crônica"
                    />
                    <InputCheck
                        id="doenca"
                        name="diagnostico"
                        label="Doença pulmonar obstrutiva crônica (DPOC)"
                        value="Doença pulmonar obstrutiva crônica (DPOC)"
                    />
                    <InputCheck
                        id="insuficiencia"
                        name="diagnostico"
                        label="Insuficiência cardíaca congestiva (ICC)"
                        value="Insuficiência cardíaca congestiva (ICC)"
                    />
                    <InputCheck
                        id="prolapso"
                        name="diagnostico"
                        label="Prolapso da válvula mitral"
                        value="Prolapso da válvula mitral"
                    />
                    <InputCheck
                        id="sopro"
                        name="diagnostico"
                        label="Sopro, febre reumática ou disfunção da válvula"
                        value="Sopro, febre reumática ou disfunção da válvula"
                    />
                    <InputCheck
                        id="arritmia"
                        name="diagnostico"
                        label="Arritmia"
                        value="Arritmia"
                    />
                    <InputCheck
                        id="enfisema"
                        name="diagnostico"
                        label="Enfisema"
                        value="Enfisema"
                    />
                    <InputCheck
                        id="hipertensao"
                        name="diagnostico"
                        label="Hipertensão arterial sistêmica (pressão alta)"
                        value="Hipertensão arterial sistêmica (pressão alta)"
                    />
                    <InputCheck
                        id="colesterol"
                        name="diagnostico"
                        label="Colesterol Alto"
                        value="Colesterol Alto"
                    />
                    <InputCheck
                        id="triglicerides"
                        name="diagnostico"
                        label="Triglicérides elevado"
                        value="Triglicérides elevado"
                    />
                    <InputCheck
                        id="derrame"
                        name="diagnostico"
                        label="Derrame cerebral"
                        value="Derrame cerebral"
                    />
                    <InputCheck
                        id="ataque"
                        name="diagnostico"
                        label="Ataque cardíaco"
                        value="Ataque cardíaco"
                    />
                    <InputCheck
                        id="cancerprostata"
                        name="diagnostico"
                        label="Câncer de próstata"
                        value="Câncer de próstata"
                    />
                    <InputCheck
                        id="cancercolorretal"
                        name="diagnostico"
                        label="Câncer colorretal"
                        value="Câncer colorretal"
                    />
                    <InputCheck
                        id="leucemia"
                        name="diagnostico"
                        label="Leucemia"
                        value="Leucemia"
                    />
                    <InputCheck
                        id="canderpulmao"
                        name="diagnostico"
                        label="Câncer de pulmão"
                        value="Câncer de pulmão"
                    />
                    <InputCheck
                        id="melanoma"
                        name="diagnostico"
                        label="Melanoma"
                        value="Melanoma"
                    />
                    <InputCheck
                        id="linfoma"
                        name="diagnostico"
                        label="Linfoma não Hodgkin"
                        value="Linfoma não Hodgkin"
                    />
                    <InputCheck
                        id="cancerbexiga"
                        name="diagnostico"
                        label="Câncer de bexiga"
                        value="Câncer de bexiga"
                    />
                    <InputCheck
                        id="canderrim"
                        name="diagnostico"
                        label="Câncer de rim"
                        value="Câncer de rim"
                    />
                    <InputCheck
                        id="anemia"
                        name="diagnostico"
                        label="Anemia"
                        value="Anemia"
                    />
                    <InputCheck
                        id="trombose"
                        name="diagnostico"
                        label="Trombose venosa profunda (TVP)"
                        value="Trombose venosa profunda (TVP)"
                    />
                    <InputCheck
                        id="doencavascular"
                        name="diagnostico"
                        label="Doença vascular periférica"
                        value="Doença vascular periférica"
                    />
                    <InputCheck
                        id="doecacronh"
                        name="diagnostico"
                        label="Doença de Crohn"
                        value="Doença de Crohn"
                    />
                    <InputCheck
                        id="aziaourefluxo"
                        name="diagnostico"
                        label="Azia ou refluxo"
                        value="Azia ou refluxo"
                    />
                    <InputCheck
                        id="sindrome"
                        name="diagnostico"
                        label="Síndrome do intestino irritável"
                        value="Síndrome do intestino irritável"
                    />
                    <InputCheck
                        id="colite"
                        name="diagnostico"
                        label="Colite ulcerativa"
                        value="Colite ulcerativa"
                    />
                    <InputCheck
                        id="doencainflamatoria"
                        name="diagnostico"
                        label="Doença inflamatória intestinal (DII)"
                        value="Doença inflamatória intestinal (DII)"
                    />
                    <InputCheck
                        id="constipacao"
                        name="diagnostico"
                        label="Constipação crônica"
                        value="Constipação crônica"
                    />
                    <InputCheck
                        id="transtornoatenção"
                        name="diagnostico"
                        label="Transtorno de atenção / Hiperatividade"
                        value="Transtorno de atenção / Hiperatividade"
                    />
                    <InputCheck
                        id="ansiedade"
                        name="diagnostico"
                        label="Ansiedade"
                        value="Ansiedade"
                    />
                    <InputCheck
                        id="transtornobipolar"
                        name="diagnostico"
                        label="Transtorno bipolar"
                        value="Transtorno bipolar"
                    />
                    <InputCheck
                        id="depressao"
                        name="diagnostico"
                        label="Depressão"
                        value="Depressão"
                    />
                    <InputCheck
                        id="insonia"
                        name="diagnostico"
                        label="Insônia"
                        value="Insônia"
                    />
                    <InputCheck
                        id="disfuncaoeretil"
                        name="diagnostico"
                        label="Disfunção erétil"
                        value="Disfunção erétil"
                    />
                    <InputCheck
                        id="hiperplasia"
                        name="diagnostico"
                        label="Hiperplasia prostática"
                        value="Hiperplasia prostática"
                    />
                    <InputCheck
                        id="baixos"
                        name="diagnostico"
                        label="Baixos níveis de testosterona"
                        value="Baixos níveis de testosterona"
                    />
                    <InputCheck
                        id="doencarenal"
                        name="diagnostico"
                        label="Doença renal crônica"
                        value="Doença renal crônica"
                    />
                    <InputCheck
                        id="bexiga"
                        name="diagnostico"
                        label="Bexiga hiperativa ou incontinência urinária"
                        value="Bexiga hiperativa ou incontinência urinária"
                    />
                    <InputCheck
                        id="hepatitea"
                        name="diagnostico"
                        label="Hepatite A"
                        value="Hepatite A"
                    />
                    <InputCheck
                        id="hepatiteb"
                        name="diagnostico"
                        label="Hepatite B"
                        value="Hepatite B"
                    />
                    <InputCheck
                        id="hepatitec"
                        name="diagnostico"
                        label="Hepatite C"
                        value="Hepatite C"
                    />
                    <InputCheck
                        id="hodgkin"
                        name="diagnostico"
                        label="Doença de Hodgkin"
                        value="Doença de Hodgkin"
                    />
                    <InputCheck
                        id="disfuncaotireoide"
                        name="diagnostico"
                        label="Disfunção da tireoide"
                        value="Disfunção da tireoide"
                    />
                    <InputCheck
                        id="olhoseco"
                        name="diagnostico"
                        label="Olho seco"
                        value="Olho seco"
                    />
                    <InputCheck
                        id="catarata"
                        name="diagnostico"
                        label="Catarata"
                        value="Catarata"
                    />
                    <InputCheck
                        id="degeneracao"
                        name="diagnostico"
                        label="Degeneração macular"
                        value="Degeneração macular"
                    />
                    <InputCheck
                        id="glaucoma"
                        name="diagnostico"
                        label="Glaucoma"
                        value="Glaucoma"
                    />
                    <InputCheck
                        id="astigmatismo"
                        name="diagnostico"
                        label="Astigmatismo"
                        value="Astigmatismo"
                    />
                    <InputCheck
                        id="presbiopia"
                        name="diagnostico"
                        label="Presbiopia (vista cansada)"
                        value="Presbiopia (vista cansada)"
                    />
                    <InputCheck
                        id="perdaaudicao"
                        name="diagnostico"
                        label="Perda da audição"
                        value="Perda da audição"
                    />
                    <InputCheck
                        id="acne"
                        name="diagnostico"
                        label="Acne"
                        value="Acne"
                    />
                    <InputCheck
                        id="alopecia"
                        name="diagnostico"
                        label="Alopécia (perda de cabelo)"
                        value="Alopécia (perda de cabelo)"
                    />
                    <InputCheck
                        id="eczema"
                        name="diagnostico"
                        label="Eczema"
                        value="Eczema"
                    />
                    <InputCheck
                        id="rosacea"
                        name="diagnostico"
                        label="Rosácea"
                        value="Rosácea"
                    />
                    <InputCheck
                        id="psoriase"
                        name="diagnostico"
                        label="Psoríase"
                        value="Psoríase"
                    />
                    <InputCheck
                        id="artrite"
                        name="diagnostico"
                        label="Artrite psoriásica"
                        value="Artrite psoriásica"
                    />
                    <InputCheck
                        id="osteoartrite"
                        name="diagnostico"
                        label="Osteoartrite"
                        value="Osteoartrite"
                    />
                    <InputCheck
                        id="artrite"
                        name="diagnostico"
                        label="Artrite reumatoide"
                        value="Artrite reumatoide"
                    />
                    <InputCheck
                        id="dorouproblema"
                        name="diagnostico"
                        label="Dor ou problema lombar"
                        value="Dor ou problema lombar"
                    />
                    <InputCheck
                        id="dornojoelho"
                        name="diagnostico"
                        label="Dor no joelho"
                        value="Dor no joelho"
                    />
                    <InputCheck
                        id="dornoquabril"
                        name="diagnostico"
                        label="Dor no quadril"
                        value="Dor no quadril"
                    />
                    <InputCheck
                        id="doroutras"
                        name="diagnostico"
                        label="Dor em outras articulações"
                        value="Dor em outras articulações"
                    />
                    <InputCheck
                        id="osteoporose"
                        name="diagnostico"
                        label="Osteoporose"
                        value="Osteoporose"
                    />
                    <InputCheck
                        id="fibromialgia"
                        name="diagnostico"
                        label="FIbromialgia"
                        value="FIbromialgia"
                    />
                    <InputCheck
                        id="alergia"
                        name="diagnostico"
                        label="Alergia"
                        value="Alergia"
                    />
                    <InputCheck
                        id="sinusite"
                        name="diagnostico"
                        label="Sinusite"
                        value="Sinusite"
                    />
                    <InputCheck
                        id="migranea"
                        name="diagnostico"
                        label="Migrãnea (enxaqueca) ou dor de cabeça"
                        value="Migrãnea (enxaqueca) ou dor de cabeça"
                    />
                    <InputCheck
                        id="alzheimer"
                        name="diagnostico"
                        label="Doença de Alzheimer"
                        value="Doença de Alzheimer"
                    />
                    <InputCheck
                        id="epilepsia"
                        name="diagnostico"
                        label="Epilepsia"
                        value="Epilepsia"
                    />
                    <InputCheck
                        id="esclerose"
                        name="diagnostico"
                        label="Esclerose múltipla"
                        value="Esclerose múltipla"
                    />
                    <InputCheck
                        id="parkinson"
                        name="diagnostico"
                        label="Doença de Parkinson"
                        value="Doença de Parkinson"
                    />
                    <InputCheck
                        id="herpes"
                        name="diagnostico"
                        label="Herpes genital"
                        value="Herpes genital"
                    />
                    <InputCheck
                        id="naosei"
                        name="diagnostico"
                        label="Nenhuma se aplica"
                        value="Nenhuma se aplica"
                        onClick={onClickCheck}
                    />
                </>
            );
        },
    },
    {
        id: 41,
        title:
            'No momento, você recebe aconselhamento ou passa por terapia para a depressão?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="aconselhamento"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="aconselhamento"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 42,
        title:
            'Você sofreu alguma dor crônica ou recorrente nos últimos 12 meses?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="dorcronica"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="dorcronica"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 43,
        title: 'Você esteve em consulta com um dentista nos últimos 12 meses?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="consultadentista"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="consultadentista"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 44,
        title:
            'Você tomou a vacina DT (dupla adulto) contra difteria e tétano nos últimos 10 anos?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="vacinaDt"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="vacinaDt"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 45,
        title: 'Você passa em alguma especialidade medica?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="especialidade"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="especialidade"
                        label="Não"
                        value="Não"
                        onClick={() => setNextQuestion(47)}
                    />
                </>
            );
        },
    },
    {
        id: 46,
        title: 'Em quais especialidades você passa?',
        renderInput: () => {
            return (
                <>
                    <InputCheck
                        id="acumputura"
                        name="especialidades"
                        label="Acupuntura"
                        value="Acupuntura"
                    />
                    <InputCheck
                        id="alergologia"
                        name="especialidades"
                        label="Alergologia"
                        value="Alergologia"
                    />
                    <InputCheck
                        id="cardiologia"
                        name="especialidades"
                        label="Cardiologia"
                        value="Cardiologia"
                    />
                    <InputCheck
                        id="plastica"
                        name="especialidades"
                        label="Cirurgia Plástica"
                        value="Cirurgia Plástica"
                    />
                    <InputCheck
                        id="vascular"
                        name="especialidades"
                        label="Cirurgia Vascular"
                        value="Cirurgia Vascular"
                    />
                    <InputCheck
                        id="geral"
                        name="especialidades"
                        label="Clínica Geral"
                        value="Clínica Geral"
                    />
                    <InputCheck
                        id="dermatologia"
                        name="especialidades"
                        label="Dermatologia"
                        value="Dermatologia"
                    />
                    <InputCheck
                        id="endocrinologia"
                        name="especialidades"
                        label="Endocrinologia"
                        value="Endocrinologia"
                    />
                    <InputCheck
                        id="fisioterapia"
                        name="especialidades"
                        label="Fisioterapia"
                        value="Fisioterapia"
                    />
                    <InputCheck
                        id="fonoaudiologia"
                        name="especialidades"
                        label="Fonoaudiologia"
                        value="Fonoaudiologia"
                    />
                    <InputCheck
                        id="gastroenterologia"
                        name="especialidades"
                        label="Gastroenterologia"
                        value="Gastroenterologia"
                    />
                    <InputCheck
                        id="geriatria"
                        name="especialidades"
                        label="Geriatria"
                        value="Geriatria"
                    />
                    <InputCheck
                        id="ginecologia"
                        name="especialidades"
                        label="Ginecologia"
                        value="Ginecologia"
                    />
                    <InputCheck
                        id="hematologia"
                        name="especialidades"
                        label="Hematologia"
                        value="Hematologia"
                    />
                    <InputCheck
                        id="homeopatia"
                        name="especialidades"
                        label="Homeopatia"
                        value="Homeopatia"
                    />
                    <InputCheck
                        id="infectologia"
                        name="especialidades"
                        label="Infectologia"
                        value="Infectologia"
                    />
                    <InputCheck
                        id="mastologia"
                        name="especialidades"
                        label="Mastologia"
                        value="Mastologia"
                    />
                    <InputCheck
                        id="neurologia"
                        name="especialidades"
                        label="Neurologia"
                        value="Neurologia"
                    />
                    <InputCheck
                        id="nutricao"
                        name="especialidades"
                        label="Nutrição"
                        value="Nutrição"
                    />
                    <InputCheck
                        id="nutrilogia"
                        name="especialidades"
                        label="Nutrologia"
                        value="Nutrologia"
                    />
                    <InputCheck
                        id="oftalmoligia"
                        name="especialidades"
                        label="Oftalmologia"
                        value="Oftalmologia"
                    />
                    <InputCheck
                        id="ortopedia"
                        name="especialidades"
                        label="Ortopedia"
                        value="Ortopedia"
                    />
                    <InputCheck
                        id="otorrinolaringologia"
                        name="especialidades"
                        label="Otorrinolaringologia"
                        value="Otorrinolaringologia"
                    />
                    <InputCheck
                        id="pediatria"
                        name="especialidades"
                        label="Pediatria"
                        value="Pediatria"
                    />
                    <InputCheck
                        id="pneumologia"
                        name="especialidades"
                        label="Pneumologia"
                        value="Pneumologia"
                    />
                    <InputCheck
                        id="proctologia"
                        name="especialidades"
                        label="Proctologia"
                        value="Proctologia"
                    />
                    <InputCheck
                        id="psicologia"
                        name="especialidades"
                        label="Psicologia"
                        value="Psicologia"
                    />
                    <InputCheck
                        id="psiquiatria"
                        name="especialidades"
                        label="Psiquiatria"
                        value="Psiquiatria"
                    />
                    <InputCheck
                        id="reumatologia"
                        name="especialidades"
                        label="Reumatologia"
                        value="Reumatologia"
                    />
                    <InputCheck
                        id="urologia"
                        name="especialidades"
                        label="Urologia"
                        value="Urologia"
                    />
                </>
            );
        },
    },
    {
        id: 47,
        title: 'Você tomou a vacina contra a gripe nos últimos 12 meses?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="vacinagripe"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="vacinagripe"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 48,
        title:
            'Sem Kompa Saúde, como você faz quando precisa de uma consulta médica ?',
        textarea: true,
        name: 'assinasaude',
        isTouched: {
            assinasaude: false,
        },
        error: {
            assinasaude: 'Preencha com suas palavras..',
        },
        validate: ({ value }) => {
            return value !== '';
        },
        renderInput: () => (
            <FormField inputText="h-26" name="assinasaude">
                <Textarea
                    type="textarea"
                    placeholder="Preencha..."
                    className="field-input h-26"
                    rows="4"
                    cols="50"
                    name="assinasaude"
                />
            </FormField>
        ),
    },
    {
        id: 49,
        message: true,
        renderInput: () => (
            <>
                <p className="mt-2 text-xl">
                    Agora fique tranquilo, com a Kompa Saúde você pode falar
                    com seu medico dedicado de atenção primária a qualquer
                    momento e sem nenhum custo, direto no seu aplicativo. Sabia
                    que ele resolve até 80% das queixas mais comuns em
                    consultórios?.
                </p>
                <Message>
                    Mesmo assim, precisou de médicos especialistas? Você também
                    pode agendar e acionar direto do seu aplicativo, sem sair de
                    casa. E todos os nossos médicos de qualquer especialidade
                    ainda terão acesso a seu histórico de saúde.
                </Message>
            </>
        ),
    },
    {
        id: 50,
        title: 'O que você geralmente faz com o resultado dos seus exames ?',
        textarea: true,
        name: 'resultadoexames',
        isTouched: {
            resultadoexames: false,
        },
        error: {
            resultadoexames: 'Preencha com suas palavras..',
        },
        validate: ({ value }) => {
            return value !== '';
        },
        renderInput: () => (
            <FormField inputText="h-26" name="resultadoexames">
                <Textarea
                    type="textarea"
                    placeholder="Preencha..."
                    className="field-input h-26"
                    rows="4"
                    cols="50"
                    name="resultadoexames"
                />
            </FormField>
        ),
    },
    {
        id: 51,
        message: true,
        renderInput: () => (
            <Message>
                Agora, diretamente no seu aplicativo Kompa Saúde, você pode
                sempre baixar ou tirar uma foto de seus resultados e deixar
                armazenado no seu aplicativo. Chega de perder exames ou guardar
                um monte de papelada. E o melhor, todos os médicos de todas as
                especialidades sempre terão acesso a todos os seus exames
                realizados ao longo de toda a vida.
            </Message>
        ),
    },
    {
        id: 52,
        title: 'E quando precisa comprar medicamentos, o que você faz ?',
        textarea: true,
        name: 'medicamentos',
        isTouched: {
            medicamentos: false,
        },
        error: {
            medicamentos: 'Preencha com suas palavras..',
        },
        validate: ({ value }) => {
            return value !== '';
        },
        renderInput: () => (
            <FormField inputText="h-26" name="medicamentos">
                <Textarea
                    type="textarea"
                    placeholder="Preencha..."
                    className="field-input h-26"
                    rows="4"
                    cols="50"
                    name="medicamentos"
                />
            </FormField>
        ),
    },
    {
        id: 53,
        message: true,
        renderInput: () => (
            <Message>
                Mais uma maneira que encontramos para lhe ajudar a cuidar da
                saúde. Dentro do seu aplicativo Kompa Saúde, você tem acesso às
                farmácias mais próximas de você e que podem conceder algum tipo
                de desconto, basta você apresentar sua carteirinha virtual, que
                também está disponível na sua plataforma.
            </Message>
        ),
    },
    {
        id: 54,
        title: 'Você toma algum medicamento?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="medicamentos"
                        label="Sim"
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="medicamentos"
                        label="Não"
                        value="Não"
                        onClick={() => setNextQuestion(59)}
                    />
                </>
            );
        },
    },
    {
        id: 55,
        title: 'Qual medicamento você toma ?',
        textarea: true,
        name: 'medicamento',
        isTouched: {
            medicamento: false,
        },
        error: {
            medicamento:
                'Preencha com suas palavras os medicamentos que você toma e porque',
        },
        validate: ({ value }) => {
            return value !== '';
        },
        renderInput: () => (
            <FormField inputText="h-26" name="medicamento">
                <Textarea
                    type="textarea"
                    placeholder="Preencha..."
                    className="field-input h-26"
                    rows="4"
                    cols="50"
                    name="medicamento"
                />
            </FormField>
        ),
    },
    {
        id: 56,
        title:
            'Com que frequência você usa medicações que afetem seu humor ou te ajudam a relaxar?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="quasetodosdias"
                        name="frequencia"
                        label="Quase todos os dias"
                        value="Quase todos os dias"
                    />
                    <InputRadio
                        id="algumasvezes"
                        name="frequencia"
                        label="Algumas vezes"
                        value="Algumas vezes"
                    />
                    <InputRadio
                        id="raramente"
                        name="frequencia"
                        label="Raramente"
                        value="Raramente"
                    />
                    <InputRadio
                        id="nunca"
                        name="frequencia"
                        label="Nunca"
                        value="Nunca"
                    />
                </>
            );
        },
    },

    {
        id: 57,
        title: 'Em geral, como você classificaria sua saúde?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="excelente"
                        name="classificarsaude"
                        label="Excelente"
                        value="Excelente"
                    />
                    <InputRadio
                        id="muitoboa"
                        name="classificarsaude"
                        label="Muito boa"
                        value="Muito boa"
                    />
                    <InputRadio
                        id="boa"
                        name="classificarsaude"
                        label="Boa"
                        value="Boa"
                    />
                    <InputRadio
                        id="razoavel"
                        name="classificarsaude"
                        label="Razoável"
                        value="Razoável"
                    />
                    <InputRadio
                        id="ruim"
                        name="classificarsaude"
                        label="Ruim"
                        value="Ruim"
                    />
                </>
            );
        },
    },
    {
        id: 58,
        title: 'Qual o seu estado civil?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="casado"
                        name="estadocivil"
                        label="Casado(a) ou união estável"
                        value="Casado(a) ou união estável"
                        onClick={() => setNextQuestion(60)}
                    />
                    <InputRadio
                        id="solteiro"
                        name="estadocivil"
                        label="Solteiro(a)"
                        value="Solteiro(a)"
                    />
                    <InputRadio
                        id="divorciado"
                        name="estadocivil"
                        label="Divorciado(a)/ Separado(a)"
                        value="Divorciado(a)/ Separado(a)"
                    />
                    <InputRadio
                        id="viuvo"
                        name="estadocivil"
                        label="Viúvo(a)"
                        value="Viúvo(a)"
                    />
                </>
            );
        },
    },
    {
        id: 59,
        title: 'Você mora sozinho ou com outras pessoas?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="sozinho"
                        name="morasozinho"
                        label="Eu moro sozinho"
                        value="Eu moro sozinho"
                    />
                    <InputRadio
                        id="familiares"
                        name="morasozinho"
                        label="Moro com familiares ou com um(a) parceiro(a)"
                        value="Moro com familiares ou com um(a) parceiro(a)"
                    />
                    <InputRadio
                        id="colegas"
                        name="morasozinho"
                        label="Moro com colega(s)"
                        value="Moro com colega(s)"
                    />
                </>
            );
        },
    },
    {
        id: 60,
        title: 'Qual a sua ocupação?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="empregadointegral"
                        name="ocupacao"
                        label="Empregado em tempo integral"
                        value="Empregado em tempo integral"
                    />
                    <InputRadio
                        id="empregadointegralhorasextras"
                        name="ocupacao"
                        label="Empregado em tempo integral e faz horas extras"
                        value="Empregado em tempo integral e faz horas extras"
                    />
                    <InputRadio
                        id="integralemprego"
                        name="ocupacao"
                        label="Empregado em tempo integral e com outro emprego"
                        value="Empregado em tempo integral e com outro emprego"
                    />
                    <InputRadio
                        id="meioperiodo"
                        name="ocupacao"
                        label="Empregado meio período (não por opção)"
                        value="Empregado meio período (não por opção)"
                    />
                    <InputRadio
                        id="empregadoporpção"
                        name="ocupacao"
                        label="Empregado meio período (por opção)"
                        value="Empregado meio período (por opção)"
                    />
                    <InputRadio
                        id="estudanteempregado"
                        name="ocupacao"
                        label="Empregado e estudante"
                        value="Empregado e estudante"
                    />
                    <InputRadio
                        id="temporario"
                        name="ocupacao"
                        label="Empregado temporário (não por opção)"
                        value="Empregado temporário (não por opção)"
                    />
                    <InputRadio
                        id="temporarioporopcao"
                        name="ocupacao"
                        label="Empregado temporário (por opção)"
                        value="Empregado temporário (por opção)"
                    />
                    <InputRadio
                        id="donodecasa"
                        name="ocupacao"
                        label="Dona(o) de casa"
                        value="Dona(o) de casa"
                    />
                    <InputRadio
                        id="estudante"
                        name="ocupacao"
                        label="Estudante"
                        value="Estudante"
                    />
                    <InputRadio
                        id="aposentadoprecocemente"
                        name="ocupacao"
                        label="Aposentado precocemente"
                        value="Aposentado precocemente"
                    />
                    <InputRadio
                        id="aposentadocomformeesperado"
                        name="ocupacao"
                        label="Aposentado (conforme esperado)"
                        value="Aposentado (conforme esperado)"
                    />
                    <InputRadio
                        id="desempregado"
                        name="ocupacao"
                        label="Desempregado"
                        value="Desempregado"
                    />
                </>
            );
        },
    },
    {
        id: 61,
        title: 'Em média quantas horas por dia você dorme ?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="menos4"
                        name="horasdorme"
                        label="Menos de 4 horas"
                        value="Menos de 4 horas"
                    />
                    <InputRadio
                        id="quatro"
                        name="horasdorme"
                        label="4 horas"
                        value="4 horas"
                    />
                    <InputRadio
                        id="cinco"
                        name="horasdorme"
                        label="5 horas"
                        value="5 horas"
                    />
                    <InputRadio
                        id="seis"
                        name="horasdorme"
                        label="6 horas"
                        value="6 horas"
                    />
                    <InputRadio
                        id="sete"
                        name="horasdorme"
                        label="7 horas"
                        value="7 horas"
                    />
                    <InputRadio
                        id="oito"
                        name="horasdorme"
                        label="8 horas"
                        value="8 horas"
                    />
                    <InputRadio
                        id="nove"
                        name="horasdorme"
                        label="9 horas"
                        value="9 horas"
                    />
                    <InputRadio
                        id="mais9"
                        name="horasdorme"
                        label="Mais do que 9 horas"
                        value="Mais do que 9 horas"
                    />
                </>
            );
        },
    },
    {
        id: 62,
        title:
            'Com que frequência você passa por estresse em casa ou no trabalho?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="nunca"
                        name="estressecasa"
                        label="Nunca"
                        value="Nunca"
                    />
                    <InputRadio
                        id="alguns"
                        name="estressecasa"
                        label="Alguns períodos de estresse"
                        value="Alguns períodos de estresse"
                    />
                    <InputRadio
                        id="varios"
                        name="estressecasa"
                        label="Vários períodos de estresse"
                        value="Vários períodos de estresse"
                    />
                    <InputRadio
                        id="constante"
                        name="estressecasa"
                        label="Estresse constante"
                        value="Estresse constante"
                    />
                </>
            );
        },
    },
    {
        id: 63,
        title:
            'Em geral, quão satisfeito você está com sua vida (incluindo aspectos pessoais e profissionais)?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="completamentesatisfeito"
                        name="produtivotodosdias"
                        label="Completamente satisfeito"
                        value="Completamente satisfeito"
                    />
                    <InputRadio
                        id="muitosatisfeito"
                        name="produtivotodosdias"
                        label="Muito satisfeito"
                        value="Muito satisfeito"
                    />
                    <InputRadio
                        id="parcialmentesatisfeito"
                        name="produtivotodosdias"
                        label="Parcialmente satisfeito"
                        value="Parcialmente satisfeito"
                    />
                    <InputRadio
                        id="naoestou"
                        name="produtivotodosdias"
                        label="Não estou satisfeito"
                        value="Não estou satisfeito"
                    />
                </>
            );
        },
    },
    {
        id: 64,
        title:
            'Ao longo das duas últimas semanas, você se sentiu desanimado, deprimido ou sem esperança?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="Nunca"
                        name="deprimido"
                        label="Nunca"
                        value="Nunca"
                    />
                    <InputRadio
                        id="variosdias"
                        name="deprimido"
                        label="Vários dias"
                        value="Vários dias"
                    />
                    <InputRadio
                        id="maisdametadedotempo"
                        name="deprimido"
                        label="Mais da metade do tempo"
                        value="Mais da metade do tempo"
                    />
                    <InputRadio
                        id="quasetodososdias"
                        name="deprimido"
                        label="Quase todos os dias"
                        value="Quase todos os dias"
                    />
                </>
            );
        },
    },
    {
        id: 65,
        title: 'Você prática exercícios aeróbicos ou treinamento de força',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="nenhumdia"
                        name="exerciciosaerobicos"
                        label="Nenhum dia"
                        value="Nenhum dia"
                    />
                    <InputRadio
                        id="umdiaporsemana"
                        name="exerciciosaerobicos"
                        label="Um dia por semana"
                        value="Um dia por semana"
                    />
                    <InputRadio
                        id="doisdiaporsemana"
                        name="exerciciosaerobicos"
                        label="Dois dia por semana"
                        value="Dois dia por semana"
                    />
                    <InputRadio
                        id="quasetodososdias"
                        name="exerciciosaerobicos"
                        label="Três dias por semana"
                        value="Três dias por semana"
                    />
                    <InputRadio
                        id="quatrodias"
                        name="exerciciosaerobicos"
                        label="Quatro dias por semana"
                        value="Quatro dias por semana"
                    />
                    <InputRadio
                        id="cincodias"
                        name="exerciciosaerobicos"
                        label="Cinco dias por semana"
                        value="Cinco dias por semana"
                    />
                    <InputRadio
                        id="seisdias"
                        name="exerciciosaerobicos"
                        label="Seis dias por semana"
                        value="Seis dias por semana"
                    />
                    <InputRadio
                        id="todosdias"
                        name="exerciciosaerobicos"
                        label="Todos os dias"
                        value="Todos os dias"
                    />
                    <InputRadio
                        id="naosei"
                        name="exerciciosaerobicos"
                        label="Não sei"
                        value="Não sei"
                    />
                </>
            );
        },
    },
    {
        id: 66,
        title: 'Quantas porções de frutas você come por dia?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="nenhumpordia"
                        name="quantasfrutas"
                        label="Nenhuma"
                        value="Nenhuma"
                    />
                    <InputRadio
                        id="umapordia"
                        name="quantasfrutas"
                        label="Uma por dia"
                        value="Uma por dia"
                    />
                    <InputRadio
                        id="duaspordia"
                        name="quantasfrutas"
                        label="Duas por dia"
                        value="Duas por dia"
                    />
                    <InputRadio
                        id="trespodia"
                        name="quantasfrutas"
                        label="Três por dia"
                        value="Três por dia"
                    />
                    <InputRadio
                        id="quatropodia"
                        name="quantasfrutas"
                        label="Quatro por dia"
                        value="Quatro por dia"
                    />
                    <InputRadio
                        id="cincopodia"
                        name="quantasfrutas"
                        label="Cinco ou mais por dia"
                        value="Cinco ou mais por dia"
                    />
                </>
            );
        },
    },
    {
        id: 67,
        title: 'Quantas porções de vegetais você come por dia?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="nenhumpordia"
                        name="quantosvegetais"
                        label="Nenhuma"
                        value="Nenhuma"
                    />
                    <InputRadio
                        id="umapordia"
                        name="quantosvegetais"
                        label="Uma por dia"
                        value="Uma por dia"
                    />
                    <InputRadio
                        id="duaspordia"
                        name="quantosvegetais"
                        label="Duas por dia"
                        value="Duas por dia"
                    />
                    <InputRadio
                        id="trespodia"
                        name="quantosvegetais"
                        label="Três por dia"
                        value="Três por dia"
                    />
                    <InputRadio
                        id="quatropodia"
                        name="quantosvegetais"
                        label="Quatro por dia"
                        value="Quatro por dia"
                    />
                    <InputRadio
                        id="cincopodia"
                        name="quantosvegetais"
                        label="Cinco ou mais por dia"
                        value="Cinco ou mais por dia"
                    />
                </>
            );
        },
    },
    {
        id: 68,
        title:
            'Quantas porções de laticínios, como leite, queijo e iogurte, você consome por dia?',
        selectNumbers: 3,
        renderInput: () => {
            return (
                <>
                    <QuestionnaireSelect
                        name="Desnatado"
                        id="desnatado"
                        label="Desnatado"
                    >
                        <option value="Zero">0</option>
                        <option value="Um a cinco">1 a 5</option>
                        <option value="Mais de seis">Mais de 6</option>
                    </QuestionnaireSelect>
                    <QuestionnaireSelect
                        name="Semi-desnatado "
                        id="Semidesnatado "
                        label="Semi-desnatado"
                    >
                        <option value="Zero">0</option>
                        <option value="Um a cinco">1 a 5</option>
                        <option value="Mais de seis">Mais de 6</option>
                    </QuestionnaireSelect>
                    <QuestionnaireSelect
                        name="Integral"
                        id="integral"
                        label="Integral"
                    >
                        <option value="Zero">0</option>
                        <option value="Um a cinco">1 a 5</option>
                        <option value="Mais de seis">Mais de 6</option>
                    </QuestionnaireSelect>
                </>
            );
        },
    },
    {
        id: 69,
        title:
            'Quantas porções de leguminosas, como feijão, lentilhas ou grão de bico você come por dia?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="nenhumpordia"
                        name="quantosfeijao"
                        label="Nenhuma"
                        value="Nenhuma"
                    />
                    <InputRadio
                        id="umapordia"
                        name="quantosfeijao"
                        label="Uma ou menos por dia"
                        value="Uma ou menos por dia"
                    />
                    <InputRadio
                        id="duaspordia"
                        name="quantosfeijao"
                        label="Mais de uma porção por dia"
                        value="Mais de uma porção por dia"
                    />
                </>
            );
        },
    },
    {
        id: 70,
        title: 'Você é vegetariano ou vegano?',
        renderInput: ({ setNextQuestion }) => {
            return (
                <>
                    <InputRadio
                        id="sim"
                        name="vegetarianoouvegano"
                        label="Sim"
                        onClick={() => {
                            setNextQuestion(73);
                        }}
                        value="Sim"
                    />
                    <InputRadio
                        id="nao"
                        name="vegetarianoouvegano"
                        label="Não"
                        value="Não"
                    />
                </>
            );
        },
    },
    {
        id: 71,
        title:
            'Quantas porções de cada uma das carnes a seguir você consome por semana?',
        selectNumbers: 3,
        renderInput: () => {
            return (
                <>
                    <QuestionnaireSelect
                        name="Carne Vermelha"
                        id="carnevermelha"
                        label="Carne Vermelha"
                    >
                        <option value="Zero a três">0 a 3</option>
                        <option value="Quatro a seis">4 a 6</option>
                        <option value="Sete a dez">7 a 10</option>
                        <option value="Onze a dezessete">11 a 17</option>
                        <option value="Mais de dezoito">mais de 18</option>
                    </QuestionnaireSelect>
                    <QuestionnaireSelect
                        name="Carne Processada"
                        id="carneprocessada "
                        label="Carne Processada"
                    >
                        <option value="Zero a três">0 a 3</option>
                        <option value="Quatro a seis">4 a 6</option>
                        <option value="Sete a dez">7 a 10</option>
                        <option value="Onze a dezessete">11 a 17</option>
                        <option value="Mais de dezoito">mais de 18</option>
                    </QuestionnaireSelect>
                    <QuestionnaireSelect
                        name="Galináceos"
                        id="galinaceos"
                        label="Galináceos"
                    >
                        <option value="Zero a três">0 a 3</option>
                        <option value="Quatro a seis">4 a 6</option>
                        <option value="Sete a dez">7 a 10</option>
                        <option value="Onze a dezessete">11 a 17</option>
                        <option value="Mais de dezoito">mais de 18</option>
                    </QuestionnaireSelect>
                </>
            );
        },
    },
    {
        id: 72,
        title:
            'Quantas porções de peixe (incluindo mariscos e crustáceos) você consome por semana?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="nenhumporsemana"
                        name="quantasporcoesdepeixe"
                        label="Nenhuma"
                        value="Nenhuma"
                    />
                    <InputRadio
                        id="umapeixe"
                        name="quantasporcoesdepeixe"
                        label="Uma"
                        value="Uma"
                    />
                    <InputRadio
                        id="duaspeixe"
                        name="quantasporcoesdepeixe"
                        label="Duas"
                        value="Duas"
                    />
                    <InputRadio
                        id="trespeixe"
                        name="quantasporcoesdepeixe"
                        label="Três"
                        value="Três"
                    />
                    <InputRadio
                        id="quatropeixe"
                        name="quantasporcoesdepeixe"
                        label="Quatro"
                        value="Quatro"
                    />
                    <InputRadio
                        id="cincooumaispeixe"
                        name="quantasporcoesdepeixe"
                        label="Mais de cinco"
                        value="Mais de cinco"
                    />
                </>
            );
        },
    },
    {
        id: 73,
        title:
            'Você está planejando fazer quaisquer mudanças para se manter saudável ou melhorar a sua saúde nos próximos seis meses?',
        renderInput: () => {
            return (
                <>
                    <InputCheck
                        id="iniciarfisica"
                        name="iniciarfisica"
                        label="Iniciar atividade física"
                        value="Iniciar atividade física"
                    />
                    <InputCheck
                        id="perderpeso"
                        name="perderpeso"
                        label="Perder peso"
                        value="Perder peso"
                    />
                    <InputCheck
                        id="reduzirouparar"
                        name="reduzirouparar"
                        label="Reduzir ou parar de fumar"
                        value="Reduzir ou parar de fumar"
                    />
                    <InputCheck
                        id="comerfrutaseverduras"
                        name="comerfrutaseverduras"
                        label="Comer frutas e verduras frescas"
                        value="Comer frutas e verduras frescas"
                    />
                    <InputCheck
                        id="lidarmelhorcomoestresse"
                        name="lidarmelhorcomoestresse"
                        label="Lidar melhor com o estresse"
                        value="Lidar melhor com o estresse"
                    />
                    <InputCheck
                        id="outros"
                        name="outros"
                        label="Outros"
                        value="Outros"
                    />
                </>
            );
        },
    },
    {
        id: 74,
        lastQuestion: true,
        title: 'Qual idioma você prefere falar?',
        renderInput: () => {
            return (
                <>
                    <InputRadio
                        id="portugues"
                        name="quallinguagem"
                        label="Português"
                        value="Português"
                    />
                    <InputRadio
                        id="ingles"
                        name="quallinguagem"
                        label="Inglês"
                        value="Inglês"
                    />
                    <InputRadio
                        id="espanhol"
                        name="quallinguagem"
                        label="Espanhol"
                        value="Espanhol"
                    />
                    <InputRadio
                        id="chines"
                        name="quallinguagem"
                        label="Chinês"
                        value="Chinês"
                    />
                    <InputRadio
                        id="japones"
                        name="quallinguagem"
                        label="Japonês"
                        value="Japonês"
                    />
                    <InputRadio
                        id="italiano"
                        name="quallinguagem"
                        label="Italiano"
                        value="Italiano"
                    />
                    <InputRadio
                        id="frances"
                        name="quallinguagem"
                        label="Francês"
                        value="Francês"
                    />
                    <InputRadio
                        id="outros"
                        name="quallinguagem"
                        label="Outros"
                        value="Outros"
                    />
                </>
            );
        },
    },
];

export default questions;

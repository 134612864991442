export default [
    {
        type: 'kg',
        name: 'Peso',
        nickname: 'peso',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'cm',
        name: 'Altura',
        nickname: 'altura',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'cm',
        name: 'Circunferência Abdominal',
        nickname: 'circunferencia_abdominal',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'mmHg',
        name: 'Pressão Sistólica',
        nickname: 'pressao_sistolica',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'mmHg',
        name: 'Pressão Diastólica',
        nickname: 'pressao_diastolica',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'mg/dL',
        name: 'Colesterol total',
        nickname: 'colesterol_total',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'mg/dL',
        name: 'Colesterol HDL',
        nickname: 'colesterol_hdl',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'mg/dL',
        name: 'Colesterol LDL',
        nickname: 'colesterol_ldl',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'mg/dL',
        name: 'Triglicerideos',
        nickname: 'triglicerideos',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
    {
        type: 'mg/dL',
        name: 'Glicemia Jejum',
        nickname: 'glicemia_jejum',
        howTo:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi ducimus libero earum inventore repellendus veritatis velit dolor veniam expedita natus.',
    },
];

/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { usePostHogContext } from 'posthog-js/react';

import { GlobalContext } from '../../../contexts/GlobalContext';

import SelectAppointmentType from '../SelectAppointmentType';
import SelectMedicalUnit from '../SelectMedicalUnit';
import SelectProfessional from '../SelectProfessional';
import BookingOverview from '../BookingOverview';
import SelectPayment from '../SelectPayment';
import BookingConfirm from '../BookingConfirm';
import FAB from '../../ui/FAB';

import { APP_URLS } from '../../../urls';
import { getParameterByName, whatsappMessage } from '../../../utils';
import { SUPPORT_WHATSAPP } from '../../../constants';
import { useModalContext } from '../../../contexts/ModalContext';

import IsUnlimitedPrompt from '../IsUnlimitedPrompt';

const BookingView = () => {
    const history = useHistory();
    const location = useLocation();

    const { user, isFree, isUnlimited } = useContext(GlobalContext);
    const { handleModal, notIgnorable } = useModalContext();

    const [bookingOptions, setBookingOptions] = useState(null);

    const [priceToPay, setPriceToPay] = useState(null);

    const posthogCtx = usePostHogContext();

    useEffect(() => {
        const banner = document.getElementById('banner-booking-online');

        if (banner) {
            banner.style.display = 'none';
        }

        if (process.env.APP_ENV === 'production') {
            posthogCtx.client.capture('[AGENDAMENTO] pagina inicial');
        }

        if (isUnlimited()) {
            notIgnorable();
            handleModal(<IsUnlimitedPrompt />);
        }
    }, []);

    const addOptions = (value) =>
        setBookingOptions({ ...bookingOptions, ...value });

    return (
        <>
            <div className="w-full bg-pink-600 rounded-b-md">
                <div className="container text-center py-8 text-white">
                    <h1 className="font-medium text-2xl">Agendar consulta</h1>
                    <h2 className="text-lg lg:text-lg xl:text-xl mt-2">
                        Agende sua consulta por telemedicina ou presencialmente
                    </h2>

                    {getParameterByName('name') && (
                        <h2 className="text-xl mt-4 font-medium underline ">
                            Agendando para: {getParameterByName('name')}
                        </h2>
                    )}
                </div>
            </div>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route exact path={APP_URLS.BOOKING}>
                        <SelectAppointmentType
                            browserHistory={history}
                            bookingOptions={bookingOptions}
                            setPriceToPay={setPriceToPay}
                            isFree={isFree}
                            isUnlimited={isUnlimited}
                            addOptions={addOptions}
                            user={user}
                        />
                    </Route>
                    <Route path={APP_URLS.BOOKING_CHOOSE_UNIT}>
                        <SelectMedicalUnit
                            browserHistory={history}
                            bookingOptions={bookingOptions}
                            addOptions={addOptions}
                        />
                    </Route>
                    <Route path={APP_URLS.BOOKING_CHOOSE_TIME}>
                        <SelectProfessional
                            browserHistory={history}
                            bookingOptions={bookingOptions}
                            addOptions={addOptions}
                        />
                    </Route>
                    <Route path={APP_URLS.BOOKING_OVERVIEW}>
                        <BookingOverview
                            browserHistory={history}
                            bookingOptions={bookingOptions}
                            addOptions={addOptions}
                            user={user}
                            priceToPay={priceToPay}
                        />
                    </Route>
                    <Route path={APP_URLS.BOOKING_PAYMENT}>
                        <SelectPayment
                            browserHistory={history}
                            bookingOptions={bookingOptions}
                            priceToPay={priceToPay}
                            addOptions={addOptions}
                            isFree={isFree}
                            user={user}
                        />
                    </Route>
                    <Route path={APP_URLS.BOOKING_CONFIRM}>
                        <BookingConfirm
                            browserHistory={history}
                            bookingOptions={bookingOptions}
                            priceToPay={priceToPay}
                            user={user}
                        />
                    </Route>
                </Switch>
            </AnimatePresence>
            <FAB
                href={whatsappMessage(
                    SUPPORT_WHATSAPP,
                    'Olá, preciso de ajuda para agendar minha consulta',
                )}
                id="whatsapp-agendar-ajuda"
            />
        </>
    );
};

export default BookingView;

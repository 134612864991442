import React from 'react';
import Select from 'react-select/creatable';

const CustomStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: '#eaeaea',
        border: 0,
        boxShadow: 'none',
        padding: '5px',
    }),

    placeholder: (styles) => {
        return {
            ...styles,
            fill: '#a4a4a4',
            fontSize: '15px',
        };
    },
};

const customSelectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary25: '#B3DDDD',
        primary: '#008f8f',
    },
});

const SelectInput = ({ options, onCreate, ...rest }) => {
    return (
        <Select
            options={options}
            components={{
                IndicatorSeparator: () => null,
            }}
            className="w-full"
            isSearchable={false}
            styles={CustomStyles}
            theme={customSelectTheme}
            {...rest}
        />
    );
};

export default SelectInput;

/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import RoundButton from './RoundButton';
import { Icon, ShineEffect } from '../../ui';

import { Container, Title } from './Styles';

import Details from './Partials/Details';
import History from './Partials/History';
import Success from './Partials/Success';
import HowTo from './Partials/HowTo';
import AddRecord from './Partials/AddRecord';

import { getType } from '../../../containers/HealthMonitoring/HealthMonitoring';

const Skeleton = () => (
    <div className="container">
        <div className="my-6 h-56">
            <ShineEffect round="20px" />
        </div>
        <div className="my-6 h-32">
            <ShineEffect round="20px" />
        </div>
        <div className="my-6 h-32">
            <ShineEffect round="20px" />
        </div>
    </div>
);

export default ({
    subject,
    resetSubject,
    showAddRecord,
    setShowAddRecord,
    measureTypes,
    width,
    subjectData,
    addHistory,
    hasNoRecords,
    loading,
}) => {
    const [index, setIndex] = useState();
    const [fixed, setFixed] = useState(false);

    // Views states
    const [showHistory, setShowHistory] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showHowTo, setShowHowTo] = useState(false);

    const type = getType(subject);

    useState(() => {
        setIndex(measureTypes.findIndex((item) => item.name === subject));
    }, []);

    const seeHistory = () => {
        setFixed(true);
        setShowHistory(true);
    };

    const resetAll = () => {
        setShowHowTo(false);
        setShowSuccess(false);
        setShowAddRecord(false);
    };

    const handleAddHistory = (data) => {
        setShowSuccess(true);
        addHistory(data);
    };

    return (
        <>
            {showHowTo ? (
                <HowTo
                    backToBeggining={resetAll}
                    subject={subject}
                    measureTypes={measureTypes}
                    width={width}
                />
            ) : showSuccess ? (
                <Success backToBeggining={resetAll} width={width} />
            ) : showAddRecord ? (
                <AddRecord
                    subject={subject}
                    resetSubject={resetAll}
                    addHistory={handleAddHistory}
                    setHowTo={() => setShowHowTo(true)}
                    width={width}
                    type={type}
                />
            ) : (
                <Container index={index} width={width}>
                    <div className="flex justify-between pb-4 items-center pt-6">
                        <Title>{subject}</Title>
                        <Icon
                            name="arrow-left"
                            className="stroke-current text-gray-100"
                            style={{ strokeWidth: '3px' }}
                            onClick={() => resetSubject()}
                        />
                    </div>
                    {loading ? (
                        <Skeleton />
                    ) : hasNoRecords ? (
                        <div className="flex flex-col items-center pt-20 mx-6 text-center text-gray-100 text-xl font-medium">
                            <span>
                                Você não tem nenhum registro nesta categoria :(
                            </span>
                            <span className="my-6">
                                Adicione clicando no botao abaixo!
                            </span>
                        </div>
                    ) : showHistory ? (
                        <History
                            historyItems={subjectData.data}
                            width={width}
                            type={type}
                        />
                    ) : !subjectData ? null : (
                        <Details
                            seeHistory={seeHistory}
                            subjectData={subjectData}
                            subject={subject}
                        />
                    )}
                    <div className="flex justify-center">
                        <RoundButton
                            index={index}
                            fixed={fixed}
                            onClick={() => setShowAddRecord(true)}
                        >
                            <Icon name="plus" />
                        </RoundButton>
                    </div>
                </Container>
            )}
        </>
    );
};

import React from 'react';

import IconPhone from '../../../assets/svg/phone.svg';
import Comment from '../../../assets/svg/comment-alt.svg';
import Video from '../../../assets/svg/video.svg';
import Star from '../../../assets/svg/icon_star.svg';
import Check from '../../../assets/svg/icon_check.svg';
import AngleDown from '../../../assets/svg/icon_angle-down.svg';
import AngleUp from '../../../assets/svg/icon_angle-up.svg';
import Upload from '../../../assets/svg/file-upload.svg';
import CheckCircle from '../../../assets/svg/check-circle.svg';
import ClearCircle from '../../../assets/svg/clear-circle.svg';
import PDFFile from '../../../assets/svg/pdf-file.svg';
import CommentQuestion from '../../../assets/svg/icon_comment-question.svg';
import ExclamationTriangle from '../../../assets/svg/icon_exclamation-triangle.svg';
import ArrowDown from '../../../assets/svg/arrow-down.svg';
import TrashCan from '../../../assets/svg/delete-outline.svg';
import Pencil from '../../../assets/svg/pencil-outline.svg';
import ArrowLeft from '../../../assets/svg/arrow-left.svg';
import ArrowRight from '../../../assets/svg/arrow-right.svg';
import Plus from '../../../assets/svg/plus.svg';
import PlusCircle from '../../../assets/svg/plus-circle-outline.svg';
import Clock from '../../../assets/svg/clock-time-four-outline.svg';
import Back from '../../../assets/svg/back.svg';
import Dropdown from '../../../assets/svg/dropdown.svg';
import Map from '../../../assets/svg/map.svg';
import MapMarker from '../../../assets/svg/map-marker-outline.svg';
import Save from '../../../assets/svg/save.svg';
import Drag from '../../../assets/svg/drag-hand.svg';
import DirectionalLeft from '../../../assets/svg/directional-left.svg';
import DirectionalRight from '../../../assets/svg/directional-right.svg';
import Eye from '../../../assets/svg/eye.svg';
import MagnifyPlus from '../../../assets/svg/magnify-plus.svg';
import MagnifyMinus from '../../../assets/svg/magnify-minus.svg';
import AlertCircle from '../../../assets/svg/alert-circle.svg';
import QuestionMarkCircle from '../../../assets/svg/question-mark-circle.svg';
import Cog from '../../../assets/svg/cog.svg';
import ShieldDone from '../../../assets/svg/shield-done.svg';
import ShieldError from '../../../assets/svg/shield-error.svg';
import Profile from '../../../assets/svg/profile.svg';
import CreditCard from '../../../assets/svg/credit-card.svg';
import Calendar from '../../../assets/svg/calendar.svg';
import CalendarDate from '../../../assets/svg/calendar-date.svg';
import Lock from '../../../assets/svg/lock.svg';
import Download from '../../../assets/svg/download.svg';
import Whatsapp from '../../../assets/svg/whatsapp.svg';
import WhatsappFilled from '../../../assets/svg/whatsapp-filled.svg';
import FacebookFilled from '../../../assets/svg/facebook-filled.svg';
import Copy from '../../../assets/svg/copy.svg';
import Email from '../../../assets/svg/email.svg';
import Alert from '../../../assets/svg/alert-outline.svg';
import TreeDots from '../../../assets/svg/three-dots.svg';
import MinusRounded from '../../../assets/svg/minus-rounded.svg';
import TrashCanRounded from '../../../assets/svg/trash-can-rounded.svg';
import ProfileOutlined from '../../../assets/svg/profile-outlined.svg';
import UploadCloud from '../../../assets/svg/upload-cloud.svg';
import Paper from '../../../assets/svg/Paper.svg';
import PaperGreen from '../../../assets/svg/PaperGreen.svg';
import PaperPink from '../../../assets/svg/PaperPink.svg';
import PaperRounded from '../../../assets/svg/PaperRounded.svg';
import Folder from '../../../assets/svg/Folder.svg';
import FolderGreen from '../../../assets/svg/FolderGreen.svg';
import FolderPink from '../../../assets/svg/FolderPink.svg';
import Show from '../../../assets/svg/Show.svg';
import MoreVertical from '../../../assets/svg/more-vertical.svg';
import ImageRounded from '../../../assets/svg/image-rounded.svg';
import VideoRounded from '../../../assets/svg/video-rounded.svg';
import Edit from '../../../assets/svg/Edit.svg';
import Delete from '../../../assets/svg/Delete.svg';
import CloseSquare from '../../../assets/svg/close-square.svg';
import TickSquare from '../../../assets/svg/tick-square.svg';
import ZoomIn from '../../../assets/svg/zoom-in.svg';
import ZoomOut from '../../../assets/svg/zoom-out.svg';
import ThreeDotsPink from '../../../assets/svg/three-dots-pink.svg';
import CloseRounded from '../../../assets/svg/close-rounded.svg';
import Chat from '../../../assets/svg/chat.svg';
import ImagePink from '../../../assets/svg/ImagePink.svg';
import VideoPink from '../../../assets/svg/VideoPink.svg';
import ArrowLeftNew from '../../../assets/svg/ArrowLeft.svg';
import ArrowRightNew from '../../../assets/svg/ArrowRight.svg';
import ZoomInNew from '../../../assets/svg/zoom-in-new.svg';
import ZoomOutNew from '../../../assets/svg/zoom-out-new.svg';

const BaseComponent = (Component) => ({ size, className, ...rest }) => (
    <Component width={size} height={size} className={className} {...rest} />
);

// TODO: remove repeated icons like arrow-down

const ICONS = {
    phone: {
        component: BaseComponent(IconPhone),
    },
    comment: {
        component: BaseComponent(Comment),
    },
    video: {
        component: BaseComponent(Video),
    },
    star: {
        component: BaseComponent(Star),
    },
    check: {
        component: BaseComponent(Check),
    },
    'angle-down': {
        component: BaseComponent(AngleDown),
    },
    'angle-up': {
        component: BaseComponent(AngleUp),
    },
    upload: {
        component: BaseComponent(Upload),
    },
    'upload-cloud': {
        component: BaseComponent(UploadCloud),
    },
    check_circle: {
        component: BaseComponent(CheckCircle),
    },
    clear_circle: {
        component: BaseComponent(ClearCircle),
    },
    pdf_file: {
        component: BaseComponent(PDFFile),
    },
    'comment-question': {
        component: BaseComponent(CommentQuestion),
    },
    'exclamation-triangle': {
        component: BaseComponent(ExclamationTriangle),
    },
    'arrow-down': {
        component: BaseComponent(ArrowDown),
    },
    'arrow-left': {
        component: BaseComponent(ArrowLeft),
    },
    'arrow-right': {
        component: BaseComponent(ArrowRight),
    },
    'trash-can': {
        component: BaseComponent(TrashCan),
    },
    pencil: {
        component: BaseComponent(Pencil),
    },
    plus: {
        component: BaseComponent(Plus),
    },
    plus_circle: {
        component: BaseComponent(PlusCircle),
    },
    clock: {
        component: BaseComponent(Clock),
    },
    back: {
        component: BaseComponent(Back),
    },
    dropdown: {
        component: BaseComponent(Dropdown),
    },
    map: {
        component: BaseComponent(Map),
    },
    'map-marker': {
        component: BaseComponent(MapMarker),
    },
    save: {
        component: BaseComponent(Save),
    },
    drag_hand: {
        component: BaseComponent(Drag),
    },
    directional_left: {
        component: BaseComponent(DirectionalLeft),
    },
    directional_right: {
        component: BaseComponent(DirectionalRight),
    },
    eye: {
        component: BaseComponent(Eye),
    },
    magnify_plus: {
        component: BaseComponent(MagnifyPlus),
    },
    magnify_minus: {
        component: BaseComponent(MagnifyMinus),
    },
    alert_circle: {
        component: BaseComponent(AlertCircle),
    },
    question_mark_circle: {
        component: BaseComponent(QuestionMarkCircle),
    },
    cog: {
        component: BaseComponent(Cog),
    },
    shield_done: {
        component: BaseComponent(ShieldDone),
    },
    shield_error: {
        component: BaseComponent(ShieldError),
    },
    profile: {
        component: BaseComponent(Profile),
    },
    'credit-card': {
        component: BaseComponent(CreditCard),
    },
    calendar: {
        component: BaseComponent(Calendar),
    },
    'Calendar-date': {
        component: BaseComponent(CalendarDate),
    },
    lock: {
        component: BaseComponent(Lock),
    },
    download: {
        component: BaseComponent(Download),
    },
    whatsapp: {
        component: BaseComponent(Whatsapp),
    },
    'whatsapp-filled': {
        component: BaseComponent(WhatsappFilled),
    },
    'facebook-filled': {
        component: BaseComponent(FacebookFilled),
    },
    copy: {
        component: BaseComponent(Copy),
    },
    email: {
        component: BaseComponent(Email),
    },
    'three-dots': {
        component: BaseComponent(TreeDots),
    },
    'alert-outline': {
        component: BaseComponent(Alert),
    },
    'minus-rounded': {
        component: BaseComponent(MinusRounded),
    },
    'trash-can-rounded': {
        component: BaseComponent(TrashCanRounded),
    },
    'profile-outlined': {
        component: BaseComponent(ProfileOutlined),
    },
    folder: {
        component: BaseComponent(Folder),
    },
    'folder-pink': {
        component: BaseComponent(FolderPink),
    },
    'folder-green': {
        component: BaseComponent(FolderGreen),
    },
    paper: {
        component: BaseComponent(Paper),
    },
    'paper-green': {
        component: BaseComponent(PaperGreen),
    },
    'paper-pink': {
        component: BaseComponent(PaperPink),
    },
    'paper-rounded': {
        component: BaseComponent(PaperRounded),
    },
    show: {
        component: BaseComponent(Show),
    },
    'more-vertical': {
        component: BaseComponent(MoreVertical),
    },
    'image-rounded': {
        component: BaseComponent(ImageRounded),
    },
    'video-rounded': {
        component: BaseComponent(VideoRounded),
    },
    edit: {
        component: BaseComponent(Edit),
    },
    delete: {
        component: BaseComponent(Delete),
    },
    'close-square': {
        component: BaseComponent(CloseSquare),
    },
    'tick-square': {
        component: BaseComponent(TickSquare),
    },
    'zoom-in': {
        component: BaseComponent(ZoomIn),
    },
    'zoom-out': {
        component: BaseComponent(ZoomOut),
    },
    'three-dots-pink': {
        component: BaseComponent(ThreeDotsPink),
    },
    'close-rounded': {
        component: BaseComponent(CloseRounded),
    },
    chat: {
        component: BaseComponent(Chat),
    },
    'image-pink': {
        component: BaseComponent(ImagePink),
    },
    'video-pink': {
        component: BaseComponent(VideoPink),
    },
    'arrow-left-new': {
        component: BaseComponent(ArrowLeftNew),
    },
    'arrow-right-new': {
        component: BaseComponent(ArrowRightNew),
    },
    'zoom-in-new': {
        component: BaseComponent(ZoomInNew),
    },
    'zoom-out-new': {
        component: BaseComponent(ZoomOutNew),
    },
};

const Icon = ({ name, size = 24, className, ...rest }) => {
    if (!ICONS[name])
        throw new Error(`The icon with name ${name} could not be found!`);

    return ICONS[name].component({ size, className, ...rest });
};

export default Icon;

import React from 'react';
import Component from 'react-calendar';

const Calendar = ({ date, onChangeDate, ...rest }) => {
    return (
        <Component
            onChange={onChangeDate}
            value={date}
            locale="pt-BR"
            {...rest}
        />
    );
};

export default Calendar;

import React from 'react';
import { BACKEND_URLS } from '../../urls';

import { Icon, ReactModal as Modal, Button } from '../ui';

const WelcomeModal = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            className="max-w-lg p-8"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <h1 className="text-2xl text-pink-600 font-medium">
                Bem vindo à Kompa!
            </h1>

            <h2 className="text-xl text-pink-600 font-base mt-4 mb-6">
                Parabéns, sua assinatura foi concluída com sucesso!
            </h2>

            <p className="text-lg text-gray-800 font-base mt-4 mb-6">
                Para iniciarmos sua jornada na busca pela saúde, vamos começar
                com uma avaliação de saúde gratuita junto da{' '}
                <span className="font-medium">Karmen</span>, nossa Enfermeira
                Virtual.
            </p>

            <h2 className="text-xl text-gray-900 font-medium m-4 text-center">
                É rápidinho!
            </h2>

            <Button
                onClick={() => {
                    onRequestClose();
                    window.open(BACKEND_URLS.CHAT, '_self');
                }}
                className="px-4 w-full"
            >
                <Icon name="chat" className="fill-current text-white mr-4" />{' '}
                Clique aqui
            </Button>
        </Modal>
    );
};

export default WelcomeModal;

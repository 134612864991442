import * as React from 'react';

import { MenuHeader } from './Styles';

import MonitoringMenuButton from '../MonitoringMenuButton';

export default ({ changeSubject, measureTypes, setShowAddRecord }) => (
    <>
        <div className="overflow-auto bg-white px-4">
            <MenuHeader className="mt-4 mx-4 flex flex-col justify-center">
                <div className="w-56">
                    <h2 className="text-xl font-light text-pink-800">
                        <span>Sua evolução de Saúde</span>
                    </h2>
                    <span className="text-2xl font-medium text-gray-900 leading-7">
                        O que você quer acompanhar?
                    </span>
                </div>
            </MenuHeader>
        </div>

        <div>
            {measureTypes.map((item, index) => (
                <MonitoringMenuButton
                    key={item.name}
                    name={item.name}
                    index={index}
                    changeSubject={changeSubject}
                    setShowAddRecord={setShowAddRecord}
                />
            ))}
        </div>
    </>
);

import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import SelectStyles, { SelectOutside } from '../utils/SelectStyles';

const Select = ({
    className,
    label,
    name,
    placeholder,
    id,
    value,
    isMulti,
    options,
    onChange,
    blurDiv,
    isClearable,
    backgroundColor,
    disabled = false,
}) => {
    const changeBackground = () => {
        SelectStyles.control = (styles) => ({
            ...styles,
            backgroundColor,
            minHeight: '54px',
            border: 0,
            boxShadow: 'none',
            padding: '5px',
            '&:focus-within': {
                border: '1px solid #e05685',
                boxShadow: '0 0 1px 2.5px #f2b7cb',
            },
        });
        return SelectStyles;
    };

    return (
        <SelectOutside onBlurCapture={blurDiv}>
            {label && (
                <label htmlFor={id} className="input-label">
                    {label}
                </label>
            )}

            <ReactSelect
                options={options}
                isMulti={isMulti}
                className={`select ${className}`}
                styles={backgroundColor ? changeBackground() : SelectStyles}
                placeholder={placeholder}
                name={name}
                id={id}
                onChange={onChange}
                value={value}
                isClearable={isClearable}
                components={{
                    NoOptionsMessage: () => 'Nenhuma opção encontrada',
                    LoadingMessage: () => 'Carregando',
                    IndicatorSeparator: () => null,
                }}
                isDisabled={disabled}
            />
        </SelectOutside>
    );
};

Select.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    isMulti: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string,
        }),
    ),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                label: PropTypes.string,
            }),
        ),
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string,
        }),
    ]),
    blurDiv: PropTypes.func,
    isClearable: PropTypes.bool,
};

Select.defaultProps = {
    className: '',
    label: '',
    name: '',
    placeholder: '',
    id: '',
    isMulti: false,
    options: [],
    onChange: null,
    value: [],
    blurDiv: null,
    isClearable: false,
};

export default Select;

import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Badge from '../Badge';

const MenuItem = ({
    to,
    title,
    href,
    target,
    background,
    onClick,
    comingSoon,
    className,
    exclusive,
    checkBefore,
    id,
}) => {
    const containerClasses = cn({
        'bg-white rounded h-40 bg-right-bottom bg-no-repeat flex flex-col border border-gray-500': true,
        'cursor-pointer hover:bg-teal-600 hover:text-white transition-all transition duration-150 ease-in-out': !comingSoon,
        [className]: className,
    });

    const TagName = to ? Link : 'a';

    return (
        <TagName
            to={to}
            href={href}
            target={target}
            className={containerClasses}
            style={{
                backgroundImage: `url(${background})`,
                backgroundPositionY: '1000%',
            }}
            onClick={onClick || checkBefore}
            id={id}
        >
            <div className="p-2 font-medium pr-8">{title}</div>
            <div className="flex-grow" />
            {comingSoon && <Badge title="Em breve" className="m-2 mr-auto" />}
            {exclusive && (
                <Badge
                    title="Assinantes"
                    iconName="star"
                    className="m-2 mr-auto"
                    color="pink"
                />
            )}
        </TagName>
    );
};

export default MenuItem;

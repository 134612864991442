import React, { useContext } from 'react';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';
import { Button } from '../../ui';

const ButtonQuestion = ({ type, children, className, ...others }) => {
    const { isValidButton, promiseLoading, question } = useContext(
        HealthContext,
    );
    return (
        <Button
            type={
                type ||
                (question.message
                    ? 'submit'
                    : isValidButton
                    ? 'submit'
                    : 'button')
            }
            disabled={question.message ? false : !isValidButton}
            loading={promiseLoading}
            className={className}
            {...others}
        >
            {children}
        </Button>
    );
};

export default ButtonQuestion;

import styled from 'styled-components';

const SelectStyle = styled.select`
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent
        url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
        no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    padding: 0 25px 0 0.5rem;
    display: block;
    width: 100%;
    overflow: hidden;
`;

export default SelectStyle;

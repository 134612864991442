import React from 'react';
import styled from 'styled-components';
import { Div } from '../../styles/global';

const Span = styled.span`
    font-weight: bold;
`;

const ContractTerms = () => {
    return (
        <Div className="overflow-auto" notHeight>
            <h1 className="text-center font-bold text-2xl m-4">
                Contrato Programa de Assistência Contínua em Saúde{' '}
            </h1>
            <p className="px-6 mt-2">
                <Span>1ª.</Span> De um lado&nbsp;
                <Span>ASSINA TECNOLOGIA GESTAO EM SAUDE LTDA</Span>, sediada na
                Rua Nossa Senhora da Lapa, nº 375, sala 6b, Bairro Lapa, São
                Paulo/SP, regularmente inscrita no CNPJ/MF n°
                35.521.757/0001-05, aqui doravante denominada simplesmente&nbsp;
                <Span>CONTRATADA</Span>, e como <Span>CONTRATANTE</Span> a
                pessoa física ou jurídica identificada no anverso deste contrato
                e representante legal dos usuários sob a sua responsabilidade
                doravante denominada <Span>CONTRATANTE</Span> .
            </p>
            <p className="px-6 mt-2">
                <Span>2ª</Span>. A <Span>CONTRATADA</Span> disponibiliza ao{' '}
                <Span>CONTRATANTE</Span> e dependentes identificados
                exclusivamente nesse instrumento, acesso a consultas
                ambulatoriais com Profissionais de Saúde por telemedicina, em
                plataformas de atendimento indicadas exclusivamente pela{' '}
                <Span>CONTRATADA</Span>, bem como realização de consultas
                presenciais e exames em redes de clínicas e laboratórios
                credenciados e informados pela <Span>CONTRATADA</Span> .{' '}
            </p>
            <p className="px-6 mt-2">
                <Span>3ª</Span>. O acesso aos serviços pelo{' '}
                <Span>CONTRATANTE</Span> deverá ser feito sempre mediante
                agendamento via Central de Atendimento e deve-se levar em
                consideração: 1) o Programa de Assistência escolhido e
                contratado neste instrumento; 2) a disponibilidade de horários e
                de Profissionais de Saúde, Prestadores de Serviços e Parceiros
                da <Span>CONTRATADA</Span>; e 3) o tipo de serviço a ser
                agendado. Dependendo do serviço desejado, os agendamentos
                poderão ser feitos via site www.assinasaude.com.br ou telefone
                (11) 2774.2035.
            </p>
            <p className="px-6 mt-2">
                <Span>4ª</Span>.{' '}
                <Span>
                    Esse contrato permanecerá válido por prazo indeterminado,
                    podendo ser terminado e os serviços cancelados a qualquer
                    tempo e sem ônus
                </Span>
                ; pelo <Span>CONTRATANTE</Span>, mediante pedido de cancelamento
                simples por escrito; pela <Span>CONTRATADA</Span>, mediante
                aviso prévio com pelo menos 30 dias de antecedência. Eventual
                parcela vincendas dentro deste período será automaticamente
                antecipada, podendo a <Span>CONTRATADA</Span> usufruir dos
                benefícios do PROGRAMA até o final do prazo de 30 dias a partir
                da notificação.
            </p>
            <p className="px-6 mt-2">
                <Span>5ª</Span>. <Span>A CONTRATADA NÃO É</Span> plano de saúde,
                operadora, administradora, seguradora de saúde, encontrando-se
                regulamente constituída como prestadora de serviço de atividades
                de apoio à gestão de saúde, mantendo tão somente um{' '}
                <Span>
                    {' '}
                    Programa de Assinatura focado em MEDICINA PREVENTIVA,
                    chamado PROGRAMA DE ASSISTÊNCIA CONTÍNUA EM SAÚDE
                </Span>
                , de modo que possa oferecer maior acesso a diferentes serviços
                de saúde aos seus usuários visando seu bem-estar, prevenção e
                controle de doenças.
            </p>
            <p className="px-6 mt-2">
                <Span>6ª</Span>. Entende-se como DEPENDENTES: no caso de&nbsp;
                <Span>CONTRATANTE Titular</Span> Pessoa Física, qualquer outra
                pessoa física à escolha do Titular e seus descendentes diretos,
                solteiros até 21 anos e os definidos pela legislação vigente; no
                caso de&nbsp;
                <Span>CONTRATANTE Titular</Span> Pessoa Jurídica, funcionários
                registrados como colaboradores do Titular e seus descendentes
                diretos, solteiros até 21 anos e os definidos pela legislação
                vigente.
            </p>
            <p className="px-6 mt-2">
                <Span>7ª</Span>. Por fazer parte de um{' '}
                <Span>PROGRAMA DE ASSISTÊNCIA CONTÍNUA EM SAÚDE</Span>, a{' '}
                <Span>CONTRATADA</Span> concede ao&nbsp;
                <Span>CONTRATANTE</Span> uma tabela de preços exclusiva e com
                descontos para realização de consultas e exames em
                estabelecimentos credenciados parceiros, além de outros
                benefícios.
            </p>
            <p className="px-6 mt-2">
                <Span>7.1ª</Span> Percentuais de desconto podem variar de acordo
                com a clínica ou laboratório credenciado escolhido para consulta
                ou exame, tipo de especialidade, tipo de exame, procedimento ou
                cirurgia e{' '}
                <Span>
                    todo e qualquer serviço deve ser pago sempre à época da sua
                    realização.
                </Span>
            </p>
            <p className="px-6 mt-2">
                <Span>7.2ª</Span> Não há qualquer tipo de carência para
                utilização dos benefícios do Programa escolhido, podendo ser
                utilizado imediatamente após pagamento da primeira mensalidade,
                com exceção aos serviços de atendimento por ambulância
                (emergência) que, quando contratado, deverá ser obedecido o
                prazo mínimo de 3 meses de carência a partir da data da
                contratação para direito ao uso deste serviço.
            </p>
            <p className="px-6 mt-2">
                <Span>7.3ª</Span> Por ser apenas um{' '}
                <Span>PROGRAMA DE ASSISTÊNCIA CONTÍNUA EM SAÚDE</Span>,
                diferentemente de uma Seguradora ou Plano de Saúde,{' '}
                <Span>nenhum dos Programas</Span> contemplam serviços
                relacionados à internação, despesas hospitalares ou quaisquer
                materiais ou produtos que envolvam qualquer tipo de procedimento
                cirúrgico.
            </p>
            <p className="px-6 mt-2">
                <Span>8ª</Span>. A escolha do Programa é de inteira e total
                responsabilidade da <Span>CONTRATANTE</Span>, devendo ser
                escolhido de acordo com suas características e objetivos
                pessoais e/ou familiares e/ou empresariais.
            </p>
            <p className="px-6 mt-2">
                <Span>9ª</Span>. Uma vez definido o Programa, a{' '}
                <Span>CONTRATANTE</Span> deverá pagar uma mensalidade de acordo
                com o valor e forma de pagamento descrito no anverso deste
                Contrato. <Span>A ausência ou atraso</Span> em qualquer
                pagamento acarretará a interrupção automática dos serviços
                prestados pela <Span>CONTRATADA</Span> até que os pagamentos
                pendentes sejam devidamente quitados.
            </p>
            <p className="px-6 mt-2">
                <Span>10ª</Span>. De tempos em tempos, a <Span>CONTRATADA</Span>{' '}
                poderá alterar seus Programas de Assinatura e ​os preços dos
                serviços​. Os valores de assinatura poderão ser revisados
                periodicamente para melhor adequação e aprimoramento do serviço
                oferecido pela&nbsp;
                <Span>CONTRATADA</Span> mediante comunicação prévia de 30 dias.
                Valores de assinatura sofrerão reajustes anualmente de acordo
                com a inflação estabelecida pelo Índice Geral de Preços do
                Mercado (IGP-M), publicado pela Fundação Getúlio Vargas, ou
                outro índice equivalente aplicável, independente de qualquer
                aviso prévio.
            </p>
            <p className="px-6 mt-2">
                <Span>11ª</Span>. A confirmação do credenciamento ao Programa
                contratado dar-se-á com a efetivação do pagamento da primeira
                mensalidade, proporcionando ao <Span>CONTRATANTE</Span> um
                CARTÃO DE IDENTIFICAÇÃO, físico ou digital, qual terá a validade
                disposta na clausul a 4ª deste contrato.
            </p>
            <p className="px-6 mt-2">
                <Span>12ª</Span>. Para o uso dos serviços aqui contratados, a
                &nbsp;
                <Span>CONTRATANTE e/ou DEPENDENTES</Span> portadores do CARTÃO
                DE INDENTIFICAÇÃO deverão apresentá-lo na recepção das clínicas
                credenciadas, devidamente acompanhado de documento com foto,
                emitido por Órgão Oficial, dito como carteira de identidade.
                Esta comprovação é obrigatória antes da utilização de qualquer
                serviço da <Span>CONTRATADA</Span> ou de seus credenciados.
            </p>
            <p className="px-6 mt-2">
                <Span>14ª</Span>. Para o perfeito uso dos serviços aqui
                contratados, a <Span>CONTRATANTE</Span> e/ou DEPENDENTE deverão
                manter seus dados cadastrais atualizados, e em caso de perda do
                CARTÃO DE IDENTIFICAÇÃO, COMUNICAR IMEDIATAMENTE a{' '}
                <Span>CONTRATADA</Span> para a adoção de medidas cabíveis.
            </p>
            <p className="px-6 mt-2">
                <Span>15ª</Span>. A <Span>CONTRATADA</Span> disponibilizará
                consultas e/ou orientações médicas através de diferentes canais
                de atendimento e plataformas tecnológicas, chamadas em conjunto
                de <Span>TELEMEDICINA</Span>, como plataformas de vídeo
                conferência, telefone, e-mails ou aplicativos de mensagens,
                respeitando sempre as normas vigentes à época e determinadas
                pelo Ministério da Saúde e Conselho Federal de Medicina (CFM).{' '}
            </p>
            <p className="px-6 mt-2">
                <Span>15.1ª</Span> A <Span>CONTRATANTE</Span> declara estar
                ciente de todas as características e limitações e autoriza
                expressamente a receber orientações e/ou consultas via&nbsp;
                <Span>TELEMEDICINA</Span>.{' '}
            </p>
            <p className="px-6 mt-2">
                <Span>15.2ª</Span> A <Span>CONTRATANTE</Span> declara estar
                ciente de que orientações e consultas via TELEMEDICINA não
                substituem consultas presenciais com seu médico.
            </p>
            <p className="px-6 mt-2">
                <Span>16ª</Span>. Se qualquer disposição ou disposições
                desse&nbsp;
                <Span>CONTRATO</Span> forem consideradas inválidas, ilegais ou
                não aplicáveis, a validade, legalidade e aplicabilidade das
                demais disposições devem permanecer em pleno vigor.
            </p>
            <p className="px-6 mt-2">
                <Span>17ª</Span> A <Span>CONTRATADA</Span> poderá alterar as
                cláusulas deste contrato periodicamente, mediante notificação
                com pelo menos 30 dias de antecedência antes que as alterações
                se apliquem à <Span>CONTRATANTE</Span>, que pode recusar
                qualquer alteração mediante pedido de cancelamento deste
                instrumento.{' '}
            </p>
            <p className="px-6 mt-2">
                <Span>18ª</Span> A qualquer momento, a <Span>CONTRATADA</Span>{' '}
                poderá ceder ou transferir este <Span>CONTRATO</Span>, inclusive
                direitos e obrigações associados. A <Span>CONTRATANTE</Span>{' '}
                concorda em cooperar com a <Span>CONTRATADA</Span>&nbsp; nessas
                cessões ou transferências.
            </p>
            <p className="px-6 mt-2">
                <Span>19ª</Span>. Para dirimir qualquer divergência oriunda
                deste contrato, elege-se o Foro de São Paulo, Estado de São
                Paulo.
            </p>
            <p className="px-6 mt-2 mb-2">
                <Span>20ª</Span>. E por entender justo e contratado o&nbsp;
                <Span>CONTRATANTE</Span> declara que leu, entendeu e aceita
                todas as cláusulas do presente instrumento.
            </p>
        </Div>
    );
};

export default ContractTerms;

/* eslint-disable no-restricted-syntax */
import './index.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import App from './containers/App/App';

Modal.setAppElement('#menuRoot');

const menuRoot = document.getElementById('menuRoot');

Bugsnag.start({
    apiKey: '79e68d95783f0194526026baf6e505cd',
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ['production'],
});

// The /atendimento/consultas/ page constantly refreshes, generating tons of pageview events for posthog.
const isStaffInWaitingRoom =
    (window.__USER.isStaff || window.__USER.isMedico) &&
    window.location.pathname === '/atendimento/consultas/';

if (process.env.APP_ENV === 'production' && !isStaffInWaitingRoom) {
    posthog.init('zA7FgdjF1EEhZWx99Ak9w-ARURZTgdtWNWq7ZQKoctY', {
        api_host: 'https://app.posthog.com',
        loaded() {
            if (window.__USER.id) {
                posthog.identify(window.__USER.id, window.__USER);
                // Nasty workaround to make feature flags work with our setup.
                // Only does anything for the first time the user logs in, since after
                // the first call the feature flags are already cached.
                setTimeout(() => posthog.reloadFeatureFlags(), 200);
            } else {
                posthog.reset();
            }
        },
    });
}
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

if (menuRoot)
    ReactDOM.render(
        <ErrorBoundary>
            <PostHogProvider client={posthog}>
                <App user={window.__USER} />
            </PostHogProvider>
        </ErrorBoundary>,
        menuRoot,
    );

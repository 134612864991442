import React from 'react';
import styled from 'styled-components';

import { MonitoringBg } from './MonitoringMenuItem';

import ClockIcon from '../../../../assets/svg/clock-time-four-outline.svg';

const Clock = styled(ClockIcon)`
    zoom: 3;
    fill: ${(props) => props.theme.colors.primary};
`;

export default ({ onClick, height }) => (
    <MonitoringBg onClick={onClick} className="my-2" hover height={height}>
        <div className="h-full w-full flex flex-col justify-center">
            <Clock />

            <h1 className="text-xl font-medium text-pink-600 tracking-wide">
                Seu <br /> Histórico
            </h1>
        </div>
    </MonitoringBg>
);

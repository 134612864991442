import React from 'react';
import cn from 'classnames';

import { Icon } from '../../../ui';

const Pagination = ({ nextPage, previousPage, currentPage, totalPages }) => {
    return (
        <div className="flex items-center">
            <button
                onClick={previousPage}
                type="button"
                className={cn(
                    'rounded-full p-2 assina-shadow-sm',
                    currentPage === 1
                        ? 'bg-gray-600 cursor-default'
                        : 'bg-pink-600 hover:bg-pink-700 cursor-pointer',
                )}
            >
                <Icon
                    name="back"
                    className="fill-current text-white"
                    size={20}
                />
            </button>
            <p className="text-lg text-gray-800 px-2 sm:px-8">
                Página {currentPage} de {totalPages}
            </p>
            <button
                onClick={nextPage}
                type="button"
                className={cn(
                    'rounded-full p-2 assina-shadow-sm',
                    currentPage === totalPages
                        ? 'bg-gray-600 cursor-default'
                        : 'bg-pink-600 hover:bg-pink-700 cursor-pointer',
                )}
            >
                <Icon
                    name="back"
                    className="fill-current text-white"
                    size={20}
                    style={{ transform: 'scale(-1, -1)' }}
                />
            </button>
        </div>
    );
};

export default Pagination;

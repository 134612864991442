import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { Button, Slider, Icon } from '../../../ui';

const AddRecordContainer = styled.div`
    background: ${(props) => props.theme.colors.white1};

    padding: 25px;

    h1 {
        color: ${(props) => props.theme.colors.gray1};
        font-weight: 500;
        font-size: 25px;
    }
`;

const SliderCounter = styled.div`
    margin: 30px 0 122px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export default ({ subject, resetSubject, addHistory, width, type }) => {
    const [value, setValue] = useState(0);
    const [error, setError] = useState();

    const handleSave = () => {
        if (value === 0) {
            return setError('O novo valor não pode ser igual a zero (0)');
        }

        addHistory(value);
    };

    return (
        <AddRecordContainer>
            {width < 769 ? (
                <div className="flex justify-between pb-4 items-center">
                    <h1>{subject}</h1>
                    <Icon
                        name="arrow-left"
                        style={{ strokeWidth: '3px' }}
                        className="stroke-current text-gray-900"
                        onClick={resetSubject}
                    />
                </div>
            ) : (
                <h3 className="text-center font-light text-gray-900 text-3xl pb-6 pt-16">
                    Adicionar novo valor
                </h3>
            )}
            <SliderCounter>
                <div className="flex items-center justify-center">
                    <div className="flex items-center justify-center bg-gray-400 rounded-lg px-8 mb-8 w-56 shadow-lg">
                        <NumberFormat
                            className="font-semibold text-4xl text-pink-600 bg-transparent"
                            type="text"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            decimalSeparator=","
                            style={{
                                width: `${value.toString().length}ch`,
                            }}
                        />
                        <span className="text-3xl text-pink-600">{type}</span>
                    </div>
                </div>
                <div className="max-w-md w-full">
                    <Slider
                        value={String(value).replace(',', '.')}
                        onChange={(e) => setValue(e)}
                    />
                    <div
                        className={`flex justify-between mt-4 font-light text-gray-700 text-md ${
                            error ? 'mb-6' : 'mb-10'
                        }`}
                    >
                        <span>0</span>
                        <span>300+</span>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center">
                    {error && (
                        <span className="text-red-800 mb-2 text-xl">
                            {error}
                        </span>
                    )}
                    <Button
                        color="pink"
                        className="rounded-full px-10"
                        onClick={handleSave}
                    >
                        Adicionar
                    </Button>
                    {/* <span
                        className="tracking-widest text-xl text-pink-600 mt-6 cursor-pointer"
                        onClick={setHowTo}
                    >
                        Como coletar?
                    </span> */}
                </div>
            </SliderCounter>
        </AddRecordContainer>
    );
};

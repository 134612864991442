import React from 'react';
import styled from 'styled-components';

import check_circle from '../../../../assets/svg/check-circle.svg';
import { SITE_URLS } from '../../../../urls';

const GreenButton = styled.button(({ theme: { colors } }) => ({
    width: '100%',
    borderRadius: '6px',
    border: `2px solid ${colors.green2}`,
    color: `${colors.green2}`,
    background: `${colors.white1}`,
    padding: '8px 12px',
    fontSize: '18px',
    fontWeight: '500',
    marginTop: '14px',
    letterSpacing: '3px',
}));

const CheckIcon = styled(check_circle)`
    min-height: 50px;
    min-width: 50px;
    height: 50px;
    width: 50px;

    fill: ${({ theme: { colors } }) => colors.white1};

    background: #7bd09d;
    border-radius: 50%;
`;

const FreeAppointmentCard = ({ isFree, freeAppointment }) => {
    return (
        freeAppointment && (
            <div className="bg-green-400 p-4 rounded-md assina-shadow-sm mb-4">
                <div className="flex">
                    <CheckIcon />
                    <div className="text-white ml-4">
                        {isFree() ? (
                            <>
                                <h1 className="font-medium text-lg">
                                    1ª consulta grátis para novos assinantes.
                                </h1>
                                <p className="mt-2">
                                    Somente para atendimentos por telemedicina.
                                </p>
                            </>
                        ) : (
                            <>
                                <h1 className="font-medium text-lg">
                                    Sua 1ª consulta grátis!
                                </h1>
                                <p className="mt-2">
                                    Somente para atendimentos por telemedicina.
                                </p>
                            </>
                        )}
                    </div>
                </div>
                {isFree() && (
                    <GreenButton
                        onClick={() => window.open(SITE_URLS.PLANS, '_blank')}
                    >
                        Assinar agora
                    </GreenButton>
                )}
            </div>
        )
    );
};

export default FreeAppointmentCard;

import React from 'react';
import cn from 'classnames';

import { AnimatedDiv, Section, Icon } from '../../../ui';
import SectionTitle from '../../SectionTitle';
import { Parent, Item } from './Animate';

import { defaultDateFormat } from '../../../../utils/Dates';
import { statusTypes } from '../BillingUtils';

const BillingHistory = ({
    userBillHistory,
    maxOffset,
    offset,
    setOffset,
    loadUserBills,
}) => {
    const increaseOffset = () => {
        if (offset >= maxOffset) return;
        setOffset(offset + 1);
        loadUserBills(offset + 1);
    };

    const decreaseOffset = () => {
        if (offset <= 2) return;
        setOffset(offset - 1);
        userBillHistory.pop();
    };

    const hover = 'hover:text-pink-700 hover:bg-gray-500 focus:outline-none';

    return (
        <>
            <SectionTitle
                title="Faturas"
                description="Suas últimas faturas"
                className="pt-4"
            />

            <AnimatedDiv layout variants={Parent}>
                {userBillHistory.map(
                    ({
                        period: { cycle, billing_at, start_at, end_at },
                        status,
                        id,
                    }) => (
                        <AnimatedDiv
                            layout
                            variants={Item}
                            key={id}
                            transition={{
                                type: 'spring',
                                bounce: 0,
                                damping: 15,
                            }}
                        >
                            <Section
                                className="max-w-xl rounded-md text-center px-4 my-4"
                                title="Faturas"
                                description="Suas últimas faturas"
                            >
                                <div className="flex justify-between mx-auto text-lg">
                                    <span className="text-gray-900">
                                        Período
                                    </span>
                                    <span className="text-pink-700 font-medium">
                                        {defaultDateFormat(start_at)} -{' '}
                                        {defaultDateFormat(end_at)}
                                    </span>
                                </div>

                                <div className="flex justify-between mx-auto mt-2 text-lg">
                                    <span className="text-gray-900">
                                        Cobrança
                                    </span>
                                    <span className="text-pink-700 font-medium">
                                        {defaultDateFormat(billing_at)}
                                    </span>
                                </div>

                                <div className="flex justify-between mx-auto mt-2 text-lg">
                                    <span className="text-gray-900">
                                        Mensalidade
                                    </span>
                                    <span className="text-pink-700 font-medium">
                                        {cycle}°
                                    </span>
                                </div>

                                <div className="flex justify-between mx-auto mt-2 text-lg">
                                    <span className="text-gray-900">
                                        Status
                                    </span>
                                    <span
                                        className={`${
                                            status === 'paid'
                                                ? 'text-green-700'
                                                : 'text-pink-700'
                                        } font-medium`}
                                    >
                                        {statusTypes[status].label}
                                    </span>
                                </div>
                            </Section>
                        </AnimatedDiv>
                    ),
                )}
            </AnimatedDiv>

            <div className="container flex justify-between align-center max-w-xl text-pink-600 font-medium text-lg">
                <button
                    type="button"
                    className={cn(
                        'flex cursor-pointer px-2',
                        hover,
                        offset <= 2 && 'invisible',
                    )}
                    onClick={decreaseOffset}
                >
                    <span>Ver menos</span>
                    <Icon name="angle-up" className="fill-current" size={30} />
                </button>
                <button
                    type="button"
                    className={cn(
                        'flex cursor-pointer px-2',
                        hover,
                        offset >= maxOffset ? 'invisible' : '',
                    )}
                    onClick={increaseOffset}
                >
                    <span>Ver mais</span>
                    <Icon
                        name="angle-down"
                        className="fill-current"
                        size={30}
                    />
                </button>
            </div>
        </>
    );
};

export default BillingHistory;

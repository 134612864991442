import React, { createContext, useState, useEffect, useContext } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import useModal from '../hooks/useModal';

export const ModalContext = createContext({});

const modalRoot = document.querySelector('#modal-root');

export const ModalProvider = ({ children }) => {
    const { isOpen, setOpen, handleModal, modalContent } = useModal();
    const [isBottom, setIsBottom] = useState(false);
    const [closeButton, setCloseButton] = useState(true);

    const [closeOnClickOut, setCloseOnClickOut] = useState(true);

    const notIgnorable = () => {
        setCloseButton(false);
        setCloseOnClickOut(false);
    };

    const resetModal = () => {
        handleModal();
    };

    useEffect(() => {
        if (isOpen) {
            disableBodyScroll(modalRoot);
        } else {
            enableBodyScroll(modalRoot);
        }
    }, [isOpen]);

    return (
        <ModalContext.Provider
            value={{
                isOpen,
                setOpen,
                handleModal,
                modalContent,
                setIsBottom,
                setCloseButton,
                isBottom,
                closeButton,
                closeOnClickOut,
                setCloseOnClickOut,
                notIgnorable,
                resetModal,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error(`useModalContext must be used within a ModalProvider`);
    }
    return context;
};

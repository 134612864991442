import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { APP_URLS } from '../../../urls';

import EmergencyCareView from '../EmergencyCare';

const ServicesView = () => {
    return (
        <Switch>
            <Route exact path={APP_URLS.SERVICES}>
                <></>
            </Route>
            <Route path={APP_URLS.SERVICES_EMERGENCY_CARE}>
                <EmergencyCareView />
            </Route>
        </Switch>
    );
};

export default ServicesView;

/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    localStorageBackQuestion,
    saveOnlocalStorage,
} from '../../utils/localStorage';
import questions from '../../utils/questions';
import { HealthQuestionnaire as Component } from '../../components/HealthQuestionnaire';
import { APP_URLS } from '../../urls';
import { getParameterByName } from '../../utils';

export const HealthContext = createContext({
    isWrong: {},
    question: {},
    history: () => null,
    percente: null,
    onChangeText: () => null,
    valueInput: {},
    onChangeRadio: () => null,
    setNextQuestion: () => null,
    handlebackQuestion: () => null,
    handleNextQuestion: () => null,
    setLastMessage: () => null,
    lastMessage: null,
    setQuestion: () => null,
    onChangeCheck: () => null,
    onChangeSelect: () => null,
    checkBoxObj: [],
    onChangeDate: () => null,
    onlynumber: () => null,
    isValidButton: false,
    something: () => null,
    isDisabled: false,
    onClickCheck: () => null,
    setPromiseLoading: () => null,
    promiseLoading: false,
    isOpenError: () => null,
    openError: false,
    msgError: null,
    genericError: () => null,
    messageNurse: false,
});

const HealthQuestionnaire = () => {
    const { questionId } = useParams();
    const history = useHistory();

    // cria um objeto novo para setar os inputs com valor de uma string vazia
    const createObjectTheInputsValue = () => {
        const questionEscopo = questions.find(
            (question) => String(question.id) === String(questionId),
        );
        let objectValue = {};
        if (questionEscopo.isTouched) {
            const listKeysQuestion = Object.keys(questionEscopo.isTouched);
            for (const index in listKeysQuestion) {
                objectValue = { ...objectValue, [listKeysQuestion[index]]: '' };
            }
        }
        return objectValue;
    };

    // estados
    const [question, setQuestion] = useState(null);
    const [percente, SetPercente] = useState(null);
    const [nextQuestion, setNextQuestion] = useState(null);
    const [valueInput, setValueInput] = useState(createObjectTheInputsValue());
    const [isWrong, setIsWrong] = useState({});
    const [lastMessage, setLastMessage] = useState(null);
    const [checkBoxObj, setCheckBoxObj] = useState([]);
    const [isValidButton, setIsValidButton] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [promiseLoading, setPromiseLoading] = useState(false);
    const [isOpenError, openError] = useState(false);
    const [msgError, setMsgError] = useState(null);
    const [messageNurse, setMessageNurse] = useState(false);
    const [idInputDisabled, setIdInputDisabled] = useState(false);

    const genericError = (error) => {
        openError(true);

        setMsgError(
            'Ocorreu um erro desconhecido. Por favor, entre em contato com o nosso suporte através do WhatsApp (011) 99223-0894.',
        );

        throw new Error(error);
    };

    // retorna o objecto isTouched para sua versão original, com todos os valores falsos
    const returnNewObjIsTouched = (obj) => {
        const keysObject = Object.keys(obj);
        for (const object in keysObject) {
            obj[keysObject[object]] = false;
        }
        return obj;
    };

    // verifica se os inputs estao todos validos e retorna um bool equivalente
    const verifiedTouchedAndWrong = (wrong) => {
        const verifiedwrong = Object.keys(wrong).filter((key) => {
            return wrong[key] === question.error[key];
        });
        const verifiedToched = Object.keys(question.isTouched).filter((key) => {
            return question.isTouched[key] === false;
        });
        return verifiedToched.length === 0 && verifiedwrong.length === 0;
    };

    const pushNextQuestion = (id) => {
        const avanceQuestion = Number(questionId) + 1;
        nextQuestion
            ? history.push(`/questionario/${nextQuestion}`)
            : history.push(`/questionario/${id || avanceQuestion}`);
        setNextQuestion(null);
    };

    const sendReply = (value, pushNextQuestion) => {
        saveOnlocalStorage({
            value,
            question,
            setLastMessage,
            history,
            setPromiseLoading,
            genericError,
            promiseLoading,
            pushNextQuestion,
        });
    };

    // cuida de mudar de questão
    const handleNextQuestion = (e) => {
        if (promiseLoading) return;
        if (e) e.preventDefault();
        if (question.message) return pushNextQuestion();
        if (!isValidButton && checkBoxObj.length === 0) return;
        if (checkBoxObj.length >= 1) {
            createArrayCheckBox();
            setCheckBoxObj([]);
            if (isDisabled) return pushNextQuestion();
        }
        if (!question.validate && !question.selectNumbers)
            return pushNextQuestion();
        if (question.isTouched)
            question.isTouched = returnNewObjIsTouched(question.isTouched);
        sendReply(valueInput, pushNextQuestion);
    };

    // cuida de retornar para a questao anterior
    const handlebackQuestion = () => {
        if (question.isTouched)
            question.isTouched = returnNewObjIsTouched(question.isTouched);
        if (Number(questionId) === 1) {
            return history.push(APP_URLS.QUESTIONNAIRE_WELCOME);
        }
        const id = localStorageBackQuestion(question.id);
        pushNextQuestion(id);
    };

    // onChange do input text responsavel por setar os estados que usaremos para salvar e validar o campo
    const onChangeText = (e) => {
        const { value } = e.target;
        const { name } = e.target;
        question.isTouched[name] = true;
        const validated = question.validate({
            value,
        });
        if (validated) {
            setIsWrong({ ...isWrong, [name]: '' });
            const validButton = verifiedTouchedAndWrong({
                ...isWrong,
                [name]: '',
            });
            setIsValidButton(validButton);
        } else {
            setIsWrong({ ...isWrong, [name]: question.error[name] });
            setIsValidButton(false);
        }
        setValueInput({ ...valueInput, [name]: value });
    };

    // onClick do input radio, responsavel por salvar o valor no localStorage
    const onChangeRadio = (e) => {
        const value = e.currentTarget.getAttribute('value');
        const name = e.currentTarget.getAttribute('name');
        setIsValidButton(true);
        setValueInput({ [name]: value });
        sendReply({ value });
    };

    //  salva o array de check box
    const createArrayCheckBox = () => {
        const value = idInputDisabled
            ? checkBoxObj.filter((item) => {
                  return item.name === idInputDisabled;
              })[0].name
            : checkBoxObj.map((item) => {
                  return item.name;
              });
        console.log(checkBoxObj);
        sendReply({ value });
    };

    // cuida de salvar os input check selecionados e salvar em um estado
    const onChangeCheck = (e) => {
        const { value } = e.target;
        const { id } = e.target;
        let newArray = [];
        for (const i in checkBoxObj) {
            if (checkBoxObj[i].name === value) {
                newArray = checkBoxObj.filter((item) => {
                    return item.name !== value;
                });
                setValueInput({ ...valueInput, [id]: false });
                newArray.length < 1
                    ? setIsValidButton(false)
                    : setIsValidButton(true);
                return setCheckBoxObj(newArray);
            }
        }
        setIsValidButton(true);
        const arrayCheck = [...checkBoxObj, { name: value }];
        setValueInput({ ...valueInput, [id]: value });
        return setCheckBoxObj(arrayCheck);
    };

    // cuida de salvar o valor do input select
    const onChangeSelect = (e) => {
        const { value } = e.target;
        const { name } = e.target;
        const values = { ...valueInput, [name]: value };
        question.selectNumbers === 1
            ? setValueInput(value)
            : setValueInput(values);
        const isValid = Object.keys(values).map((key) => {
            return key;
        });
        setIsValidButton(isValid.length === question.selectNumbers);
    };

    // Cuida de salvar o valor do input date
    const onChangeDate = (e) => {
        const { value } = e.target;
        setIsValidButton(true);
        sendReply({ value });
    };

    const onClickCheck = (evt) => {
        setIdInputDisabled(evt.target.value);
        setIsDisabled(!isDisabled);
    };

    // cuida da progress bar
    const handlePercenteForLineProgress = () => {
        const percentOfEach = 100 / questions.length;
        const nowPercente = percentOfEach * questionId;
        SetPercente(nowPercente);
    };

    const onlynumber = (evt) => {
        const theEvent = evt || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        const regex = /^[0-9.,]+$/;
        // const regex = /^[0-9]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    };

    const something = (event) => {
        if (event.keyCode === 13) {
            handleNextQuestion();
        }
    };

    // troca a questão em relação ao string params questionId, e retorna os estados
    useEffect(() => {
        setQuestion(
            questions.find(
                (question) => String(question.id) === String(questionId),
            ),
        );
        handlePercenteForLineProgress();
        setValueInput(createObjectTheInputsValue());
        setNextQuestion(null);
        setIsValidButton(false);
        setIsDisabled(false);
        setIdInputDisabled(false);
    }, [questionId]);

    useEffect(() => {
        if ((getParameterByName('cpf'), getParameterByName('nome'))) {
            setMessageNurse({
                cpf: getParameterByName('cpf'),
                nome: getParameterByName('nome'),
            });
        } else {
            setMessageNurse(false);
        }
    }, []);

    const providerValue = {
        isWrong,
        question,
        history,
        percente,
        onChangeText,
        valueInput,
        onChangeRadio,
        setNextQuestion,
        handlebackQuestion,
        handleNextQuestion,
        setLastMessage,
        lastMessage,
        setQuestion,
        onChangeCheck,
        onChangeSelect,
        checkBoxObj,
        onChangeDate,
        onlynumber,
        isValidButton,
        something,
        isDisabled,
        onClickCheck,
        setPromiseLoading,
        promiseLoading,
        isOpenError,
        openError,
        msgError,
        genericError,
        messageNurse,
    };

    return (
        <HealthContext.Provider value={providerValue}>
            <Component />
        </HealthContext.Provider>
    );
};

export default HealthQuestionnaire;

import React from 'react';
import { Div, DivChildrensBorderTop } from '../../styles/global';
import { Icon } from '../ui';
import moment from 'moment';
import { range } from '../../utils';
import Loader_ from 'react-loader-spinner';

const GoBackButton = ({ onClick }) => {
    return (
        <button
            className="flex text-gray-600 hover:text-gray-800 gap-2 items-center justify-center"
            type="button"
            onClick={onClick}
        >
            <Icon
                name="arrow-left"
                size={18}
                className="cursor-pointer stroke-current text-gray-800"
                style={{
                    strokeWidth: '3px',
                }}
            />
            Voltar
        </button>
    );
};

const Prescriptions = ({
    history,
    prescriptions = [],
    showPrescription,
    setShowPrescription,
    page,
    setPage,
    totalPages,
    loading,
}) => {
    return (
        <Div color="#E5E5E5">
            <header className="h-32 btn-primary flex align-center justify-center items-center text-center">
                <div>
                    <h1 className="text-2xl text-center font-medium">
                        Minhas Prescrições e pedidos de exames
                    </h1>
                </div>
            </header>

            <div
                style={{
                    minHeight: 'calc(100vh - var(--height-header-footer))',
                }}
                className="w-full flex flex-col items-center justify-between pb-10"
            >
                <div className="p-6 max-w-5xl w-full">
                    <GoBackButton
                        onClick={() =>
                            !showPrescription
                                ? history.goBack()
                                : setShowPrescription(null)
                        }
                    />

                    {prescriptions && prescriptions.length > 0 ? (
                        !showPrescription ? (
                            <div className=" flex flex-col gap-2 mt-6 font-medium">
                                <h2 className="text-teal-600 mb-2">
                                    Minhas Prescrições
                                </h2>
                                {!loading ? (
                                    prescriptions.map((elm) => {
                                        return (
                                            <div
                                                key={elm.id}
                                                className="w-full bg-white p-4 rounded shadow-sm"
                                            >
                                                {Object.keys(
                                                    elm.prescription_data,
                                                ).length === 0 ? null : (
                                                    <div>
                                                        <h3 className="text-pink-600 font-medium">
                                                            <p>
                                                                Dr (a). &nbsp;
                                                                {elm
                                                                    .prescription_data
                                                                    .prescricao
                                                                    ? elm
                                                                          .prescription_data
                                                                          .prescricao
                                                                          .nome_medico
                                                                    : ''}
                                                            </p>
                                                        </h3>

                                                        <p className="mt-2 flex gap-2">
                                                            <Icon
                                                                name="Calendar-date"
                                                                size={20}
                                                                className="text-pink-600"
                                                            />
                                                            <span className="text-gray-800">
                                                                {moment(
                                                                    elm.data_cadastro,
                                                                    'DD/MM/YYYY HH:mm',
                                                                ).format(
                                                                    'llll',
                                                                )}
                                                            </span>
                                                        </p>

                                                        <button
                                                            className="tracking-wider rounded mt-2 bg-gray-300 flex items-center justify-center gap-2 text-pink-600 font-medium w-full py-2"
                                                            type="button"
                                                            onClick={() =>
                                                                setShowPrescription(
                                                                    elm,
                                                                )
                                                            }
                                                            id="ver-prescricao"
                                                        >
                                                            Ver
                                                            <Icon
                                                                name="directional_right"
                                                                size={15}
                                                                className="fill-current text-pink-600"
                                                                style={{
                                                                    strokeWidth:
                                                                        '.5px',
                                                                }}
                                                            />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center">
                                        <Loader_
                                            type="Oval"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                            timeout={60000}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className=" flex flex-col gap-2 mt-6 font-medium">
                                <h2 className="text-teal-600 mb-2">
                                    Prescrição médica
                                </h2>
                                <div className="w-full bg-white p-4 rounded shadow-sm">
                                    <h3 className="text-pink-600 font-medium">
                                        <p>
                                            Dr (a). &nbsp;
                                            {
                                                showPrescription
                                                    .prescription_data
                                                    .prescricao.nome_medico
                                            }
                                        </p>
                                    </h3>

                                    <p className="mt-2 flex gap-2">
                                        <Icon
                                            name="Calendar-date"
                                            size={20}
                                            className="text-pink-600"
                                        />
                                        <span className="text-gray-800">
                                            {moment(
                                                showPrescription.data_cadastro,
                                                'DD/MM/YYYY HH:mm',
                                            ).format('llll')}
                                        </span>
                                    </p>

                                    <DivChildrensBorderTop className="mt-10">
                                        {showPrescription.prescription_data.prescricao.medicamentos.map(
                                            (elm, index) => {
                                                return (
                                                    <div
                                                        className={`text-base pt-2 ${
                                                            index > 0
                                                                ? 'border-top'
                                                                : ''
                                                        }`}
                                                        key={elm.id}
                                                    >
                                                        <h2 className="font-medium ">
                                                            {elm.nome}
                                                        </h2>
                                                        <div
                                                            className="text-gray-800 mt-1"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    elm.posologia,
                                                            }}
                                                        ></div>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </DivChildrensBorderTop>
                                    <div class="mt-4 text-center text-gray-800 text-sm ">
                                        Digitos para desbloquear os dados da
                                        prescrição:
                                    </div>
                                    <div class="text-xl text-teal-600 font-mono text-center tracking-widest">
                                        {showPrescription.prescription_digits}
                                    </div>
                                    <a
                                        className="rounded mt-4 bg-pink-600 flex items-center justify-center gap-2 text-white font-medium w-full py-2"
                                        href={
                                            showPrescription.prescription_link
                                        }
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        id="ver-memed"
                                    >
                                        Ver receita
                                    </a>
                                </div>
                            </div>
                        )
                    ) : loading ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <Loader_
                                type="Oval"
                                color="#00BFFF"
                                height={50}
                                width={50}
                                timeout={60000}
                            />
                        </div>
                    ) : (
                        <div className="mt-4">
                            Nenhuma prescrição cadastrada em nossa plataforma no
                            momento. <br />
                            Caso tenha alguma prescrição gerada no passado,
                            entre em contato com o nosso suporte.
                        </div>
                    )}
                </div>

                {!showPrescription ? (
                    <div className="flex gap-4 text-gray-700">
                        {range(1, totalPages + 1).map((elm) => {
                            return (
                                <div
                                    className={`cursor-pointer py-1 px-3 tracking-wider rounded-lg ${
                                        page === elm
                                            ? 'bg-pink-600 text-white shadow-md '
                                            : ''
                                    }`}
                                    onClick={() => {
                                        setPage(elm);
                                    }}
                                >
                                    <p className="font-medium">{elm}</p>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </Div>
    );
};

export default Prescriptions;

/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AnimatedDiv, Section, Icon } from '../../../ui';
import { Parent, Children } from './Animations';
import ChangePaymentForm from '../ChangePaymentForm';
import MessagePrompt from '../../MessagePrompt';

import { GlobalContext } from '../../../../contexts/GlobalContext';
import { ModalContext } from '../../../../contexts/ModalContext';

import { subscribeCreditCard } from '../../../../services/vindi';
import { APP_URLS } from '../../../../urls';

const PaymentView = ({ userSubscriptions, browserHistory }) => {
    const { setLoading } = useContext(GlobalContext);
    const { handleModal, setCloseButton } = useContext(ModalContext);

    const { id } = useParams();

    const submitPaymentChange = async (data) => {
        setLoading(true);
        setCloseButton(false);

        try {
            const { card_company, card_expiration, card_number } = data;
            const [month, year] = card_expiration.split('/');

            const vindiData = {
                ...data,
                card_number: card_number.replace(/\s/g, ''),
                customer_id: userSubscriptions[0].customer.id,
                subscriptions_id: id,
                card_expiration: `${month}/20${year}`,
                payment_method_code: 'credit_card',
                payment_company_code: card_company.value,
            };

            const res = await subscribeCreditCard(vindiData);

            setLoading(false);

            if (!res.ok) {
                handleModal(
                    <MessagePrompt
                        error
                        message="Ocorreu um erro durante a validação de seu cartão de crédito. Verifique as informações e tente novamente."
                    />,
                );
            } else {
                handleModal(
                    <MessagePrompt
                        message="Método de pagamento atualizado!"
                        completeAction={() => {
                            browserHistory.push(APP_URLS.PROFILE);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                            window.location.reload();
                        }}
                    />,
                );
            }
        } catch (error) {
            setLoading(false);
            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro ao cadastrar seu cartão de crédito. Verifique as informações e tente novamente."
                />,
            );
            throw new Error(error);
        }
    };

    return (
        <AnimatedDiv
            className="my-8 px-4 mx-auto max-w-xl"
            variants={Parent}
            initial="hidden"
            animate="show"
        >
            <AnimatedDiv variants={Children}>
                <Section className="rounded-md shadow-md text-center flex items-center justify-center p-14 border-green-400 border-2">
                    <div className="m-2 md:m-4">
                        <Icon name="shield_done" size={55} />
                    </div>
                    <div className="py-4 pl-4 text-left">
                        <h1 className="text-2xl font-medium text-green-600">
                            Super-seguro!
                        </h1>
                        <p>
                            Usamos criptografia de ponta para proteger todas as
                            suas informações.
                        </p>
                    </div>
                </Section>
            </AnimatedDiv>
            <AnimatedDiv variants={Children}>
                <ChangePaymentForm onSubmit={submitPaymentChange} />
            </AnimatedDiv>
        </AnimatedDiv>
    );
};

export default PaymentView;

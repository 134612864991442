import React from 'react';
import styled from 'styled-components';

import Select from '../../commons/components/Select';
import { Button } from '../ui';

import Star from '../../assets/svg/star.svg';

const Div = styled.div`
    width: 100%;
    background: #f2f2f2;
    min-height: calc(100vh - var(--height-header-footer));
`;

const Container = styled.div`
    max-width: 600px;
`;

const H2 = styled.h2`
    text-indent: 12px;
    font-size: 21px;
    color: ${({ color }) => (color ? '#5E5E5E' : '#a4a4a4')};
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${(props) => (props.aboutSpecialty ? '' : '200px')};
    width: 100%;
    background: #fbfbfb;
    box-shadow: 0px 2.65625px 5.3125px 0.53125px rgba(0, 0, 0, 0.05);
    background-color: #f7fafc;
    border-radius: 5px;
`;

const DivValues = styled.div`
    display: flex;
    gap: 1rem;
    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

const Pvalue = styled.p`
    margin-top: 0.5rem;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #008f8f;
`;

const MonthlyPaymentDiv = styled.div`
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background: ${(props) => (props.withouPlan ? '#5E5E5E' : '#008f8f')};
    font-weight: normal;
    font-size: 18px;
    border-radius: 0px 0px 5px 5px;
`;

const TelemedicineValues = ({
    selectValue,
    setSelectValue,
    options,
    getValues,
    aboutSpecialty,
    handleBooking,
}) => {
    return (
        <Div>
            <header className="h-32 btn-primary font-medium text-3xl flex justify-center items-center text-center">
                <h1>
                    Valores das consultas <br /> por telemedicina
                </h1>
            </header>

            <main className="mt-4">
                <Container className="container">
                    <H2>Buscar especialidade</H2>

                    <Select
                        options={options}
                        onChange={(evt) => {
                            getValues(evt.value);
                            setSelectValue(evt);
                        }}
                        value={selectValue}
                        placeholder="Selecione..."
                        className="selectSpecialty"
                        backgroundColor="#fbfbfb"
                    />

                    <div>
                        {aboutSpecialty ? (
                            <div className="mt-4">
                                <Info className="p-5" aboutSpecialty>
                                    <h1 className="text-teal-600 font-medium text-xl">
                                        {aboutSpecialty.name}
                                    </h1>
                                    <p className="text-base text-gray-700 mt-2">
                                        {aboutSpecialty.info}
                                    </p>
                                </Info>
                                <H2 color className="my-4">
                                    Valores
                                </H2>
                                <DivValues>
                                    <Info>
                                        <div className="flex items-center justify-center text-center flex-col h-full">
                                            <Star
                                                width="1.3rem"
                                                height="1.3rem"
                                            />
                                            <p className="text-base text-gray-700 mt-2">
                                                Para assinantes da <br />
                                                <span className="text-xl text-pink-600 font-medium">
                                                    Kompa Saúde
                                                </span>
                                            </p>
                                            <Pvalue>
                                                {aboutSpecialty.valueWithPlan}
                                            </Pvalue>
                                        </div>
                                        <MonthlyPaymentDiv>
                                            <p>Mensalidade</p> <p>R$ 49.90</p>
                                        </MonthlyPaymentDiv>
                                    </Info>
                                    <Info>
                                        <div className="flex items-center justify-center text-center flex-col h-full">
                                            <h1 className="text-lg text-pink-600 font-medium">
                                                Plano particular
                                            </h1>
                                            <p className="text-base text-gray-700 mt-2">
                                                Para não assinantes <br /> da
                                                Kompa Saúde
                                            </p>
                                            <Pvalue>
                                                {aboutSpecialty.valueWithouPlan}
                                            </Pvalue>
                                        </div>
                                        <MonthlyPaymentDiv withouPlan>
                                            <p>Mensalidade</p> <p>R$ 0</p>
                                        </MonthlyPaymentDiv>
                                    </Info>
                                </DivValues>
                                <Button
                                    as="a"
                                    target="_blank"
                                    onClick={handleBooking}
                                    className="w-full my-4"
                                >
                                    Fazer agendamento
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </Container>
            </main>
        </Div>
    );
};

export default TelemedicineValues;

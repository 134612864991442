import React, { useContext } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { AnimatedDiv, Section, Check } from '../../../ui';
import SectionTitle from '../../SectionTitle';
import Subscription from '../Subscription';
import { GlobalContext } from '../../../../contexts/GlobalContext';

import { Parent, Item } from './Animate';
import Skeleton from './Skeleton';

import { PremiumCard } from '../../../Plans';

const FreePlan = {
    plan: 'Assinatura Free',
    product_items: [{ status: 'active' }],
    price_with_discount: 0,
    id: 'Free',
};

const BillingView = ({
    browserHistory,
    userSubscriptions,
    freeUser,
    holder,
}) => {
    const {
        user: { name },
    } = useContext(GlobalContext);

    return (
        <AnimatedDiv
            layout
            variants={Parent}
            initial="hidden"
            animate="show"
            className="max-w-xl mx-auto"
        >
            {holder !== 'None' ? (
                <div className="text-center px-6">
                    <h1 className="text-2xl text-pink-700">
                        Para ter acesso às informações sobre o seu plano acesse
                        como o usuário titular!
                    </h1>
                </div>
            ) : freeUser ? (
                <AnimatedDiv layout variants={Item}>
                    <SectionTitle
                        title="Planos"
                        description="Seus planos contratados"
                        className="pb-4"
                    />
                    <Subscription
                        details={FreePlan}
                        browserHistory={browserHistory}
                    />
                    <Section className="rounded-md">
                        <H1 className="font-semibold text-xl text-center">
                            Plano Gratuito
                        </H1>
                        <Check className="text-gray-800">
                            Apoio Agendamento de consultas por telemedicina
                        </Check>
                        <Check className="text-gray-800">
                            Agendamento de consultas presenciais
                        </Check>
                        <Check className="text-gray-800">
                            Receitas médicas online
                        </Check>
                        <Check className="text-gray-800">
                            Pedidos de exames online
                        </Check>
                        <Check className="text-gray-800">
                            Historico de saúde online
                        </Check>
                        <Check className="text-gray-800">
                            Alerta de medicamentos
                        </Check>
                    </Section>
                    <PremiumCard className="mt-4 rounded-md" full />
                </AnimatedDiv>
            ) : userSubscriptions ? (
                <>
                    <AnimatedDiv layout variants={Item}>
                        <Section className="rounded-md text-center">
                            <h1 className="text-xl text-teal-600">{name}</h1>
                            <div className="flex justify-between mx-auto max-w-xs mt-4 px-4 text-lg text-gray-900">
                                <span>Assinante desde:</span>
                                <span>
                                    {format(
                                        new Date(userSubscriptions[0].start_at),
                                        'dd/MM/yyyy',
                                    )}
                                </span>
                            </div>
                        </Section>
                    </AnimatedDiv>

                    <AnimatedDiv layout variants={Item}>
                        <SectionTitle
                            title="Planos"
                            description="Seus planos contratados"
                            className="pt-6 pb-4"
                        />
                        <AnimatedDiv layout variants={Parent}>
                            {userSubscriptions.map((item) => (
                                <AnimatedDiv
                                    layout
                                    variants={Item}
                                    key={item.id}
                                >
                                    <Subscription
                                        details={item}
                                        browserHistory={browserHistory}
                                        seeDetails
                                    />
                                </AnimatedDiv>
                            ))}
                        </AnimatedDiv>
                    </AnimatedDiv>
                </>
            ) : (
                <Skeleton />
            )}
        </AnimatedDiv>
    );
};

export default BillingView;

const H1 = styled.div`
    color: #540620;
`;

import React from 'react';

import { Button, ReactModal as Modal } from '../../ui';

const ModalQuestionnaire = ({
    isOpen,
    setOpen,
    label,
    buttonYes,
    buttonNot,
    title,
    content,
    msgError,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            contentLabel={label}
        >
            <div>
                <div className="pt-0 text-2xl">
                    <h1 className="text-teal-600">{title}</h1>
                </div>
                <div className="max-w-lg mt-4">
                    <p>{content}</p>
                </div>
                <footer className="gap-1 flex justify-between p-0 mt-4">
                    {msgError ? (
                        <Button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="w-full flex-shrink-0"
                        >
                            OK
                        </Button>
                    ) : (
                        <>
                            <Button
                                type="button"
                                color="white"
                                onClick={buttonNot.onClick}
                                className="w-1/2 flex-shrink-0"
                            >
                                {buttonNot.label}
                            </Button>
                            <Button
                                type="button"
                                onClick={buttonYes.onClick}
                                className="w-1/2 flex-shrink-0"
                            >
                                {buttonYes.label}
                            </Button>
                        </>
                    )}
                </footer>
            </div>
        </Modal>
    );
};

export default ModalQuestionnaire;

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { add } from 'date-fns';
import styled from 'styled-components';

import { APP_URLS } from '../../../urls';
import { formatDate } from '../../../utils/Dates';
import { AnimatedDiv, Button, Icon } from '../../ui';

import BookingCalendar from '../BookingCalendar';
import Schedule from './Schedule';

import MessagePrompt from '../../Profile/MessagePrompt';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { ModalContext } from '../../../contexts/ModalContext';

import { appointmentsService } from '../../../services/booking';
import { openSupportWhatsApp } from '../BookingUtils';
import { FilterButtons } from './styles';
import { ScheduleItem, ScheduleParent, Wrapper } from './Animate';

import ClearCircle from '../../../assets/svg/clear-circle.svg';

const ClearIcon = styled(ClearCircle)`
    min-height: 50px;
    min-width: 50px;
    height: 50px;
    width: 50px;

    fill: #9a9a9a;
`;

const buildQuery = (query, specialty_id, unit_id, others) => {
    const defaultQuery = {
        specialty_id,
        query: 'format_by_date',
        offset: 'all',
        ...others,
    };

    switch (query) {
        case 'telemedicina': {
            return {
                is_telemedicine: 'True',
                ...defaultQuery,
            };
        }
        case 'presencial': {
            return {
                is_in_person: 'True',
                unit_id,
                ...defaultQuery,
            };
        }
        default:
    }
};

const ChooseProfessional = ({ browserHistory, bookingOptions, addOptions }) => {

    const { handleModal, setCloseButton } = useContext(ModalContext);
    const { setLoading } = useContext(GlobalContext);

    // Date states and values
    const [bookData, setBookData] = useState(new Date());
    const minDate = new Date();

    const [schedules, setSchedules] = useState(null);
    const [empty, setEmpty] = useState(false);

    const { type, specialty_id, unit_id } = useParams();

    const loadAvailableSchedules = async (query) => {
        setLoading(true);
        setCloseButton(false);

        try {
            const res = await appointmentsService(query);
            setSchedules(res);
            setLoading(false);

            if (res.length === 0) setEmpty(true);
        } catch (error) {
            setLoading(false);
            handleModal(
                <MessagePrompt
                    error
                    message="Ocorreu um erro durante a sua requisição."
                    errorAction={() => window.location.reload()}
                />,
            );

            throw new Error(error);
        }
    };

    useEffect(() => {
        if (!bookingOptions) return browserHistory.replace(APP_URLS.BOOKING);
        const query = buildQuery(type, specialty_id, unit_id, {
            date_range: `${formatDate(bookData, 'yyyy-MM-dd')} ${formatDate(
                add(bookData, { days: 30 }),
                'yyyy-MM-dd',
            )}`,
        });
        loadAvailableSchedules(query);
    }, []);

    const changeDate = (date) => {
        setBookData(date);
        const query = buildQuery(type, specialty_id, unit_id, {
            date_range: `${formatDate(date, 'yyyy-MM-dd')} ${formatDate(
                add(date, { days: 30 }),
                'yyyy-MM-dd',
            )}`,
        });

        setSchedules([]);
        loadAvailableSchedules(query);
    };

    const selectTime = (item) => {
        addOptions({
            selectedOption: item,
        });

        browserHistory.push(APP_URLS.BOOKING_OVERVIEW);
    };

    const openCalendar = () => {
        setCloseButton(false);

        handleModal(
            <BookingCalendar
                bookData={bookData}
                minDate={minDate}
                changeDate={changeDate}
            />,
        );
    };

    return (
        <AnimatedDiv
            variants={Wrapper}
            initial="initial"
            animate="in"
            exit="exit"
            className="container py-8 max-w-xl mx-auto"
        >
            <h1 className="text-2xl text-pink-600 font-medium text-center">
                Selecione o horário e profissional
            </h1>

            <AnimatedDiv>
                <div className="mt-4">
                    <p className="text-xl text-gray-800 text-center mb-2">
                        Não encontrou a data desejada?
                    </p>
                    <FilterButtons>
                        <Button className="w-full" onClick={openCalendar}>
                            Filtrar por data
                            <Icon
                                name="calendar"
                                size={20}
                                className="fill-current text-white ml-2"
                            />
                        </Button>
                        <Button
                            className="w-full"
                            onClick={openSupportWhatsApp}
                        >
                            Entrar em contato
                        </Button>
                    </FilterButtons>
                </div>

                {schedules && schedules.length !== 0 && (
                    <>
                        <h3 className="text-lg text-center text-gray-900 my-8">
                            Datas a partir de{' '}
                            {formatDate(bookData, 'dd/MM/yyyy') ===
                            formatDate(new Date(), 'dd/MM/yyyy')
                                ? 'hoje'
                                : `${formatDate(bookData, "dd 'de' MMMM")}`}
                            :
                        </h3>

                        <AnimatedDiv
                            variants={ScheduleParent}
                            initial="initial"
                            animate="in"
                            exit="exit"
                            layout
                        >
                            {schedules.map((item) => (
                                <AnimatedDiv
                                    key={item.date}
                                    variants={ScheduleItem}
                                    transition={{
                                        type: 'spring',
                                        bounce: 0,
                                        damping: 15,
                                    }}
                                >
                                    <Schedule
                                        data={item}
                                        selectTime={selectTime}
                                        specialty={bookingOptions.specialty}
                                    />
                                </AnimatedDiv>
                            ))}
                        </AnimatedDiv>
                    </>
                )}

                {empty && (
                    <div className="pt-8 pb-4 flex flex-col justify-center items-center">
                        <ClearIcon />
                        <h1 className="text-xl text-gray-800 mt-4">
                            Nenhuma agenda encontrada para a especialidade
                            selecionada
                        </h1>
                    </div>
                )}
            </AnimatedDiv>
        </AnimatedDiv>
    );
};

export default ChooseProfessional;

import { AnimatePresence } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import { ModalContext } from '../../../contexts/ModalContext';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { getUserSubscriptions } from '../../../services/vindi';

import { APP_URLS } from '../../../urls';

import { BillingView, DetailsView } from '../Billing';
import FeatureTitle from '../FeatureTitle';
import MessagePrompt from '../MessagePrompt';
import { PaymentView } from '../Payment';

const ProfileView = () => {
    const { handleModal, setCloseButton } = useContext(ModalContext);
    const { isFree, holder } = useContext(GlobalContext);

    const location = useLocation();
    const history = useHistory();

    const [userSubscriptions, setUserSubscriptions] = useState();

    const loadUserSubscriptions = async () => {
        setCloseButton(false);
        try {
            const res = await getUserSubscriptions();
            setUserSubscriptions(res);
        } catch (error) {
            if (error.response) {
                if (
                    error.response.data.data.errors &&
                    error.response.data.data.errors.length > 0
                ) {
                    handleModal(
                        <MessagePrompt
                            error
                            message={error.response.data.data.errors
                                .map((m) => Object.values(m).join(' '))
                                .join(',')}
                            errorAction={() => window.location.reload()}
                        />,
                    );
                } else {
                    handleModal(
                        <MessagePrompt
                            error
                            message={error.response.data.data}
                            errorAction={() => window.location.reload()}
                        />,
                    );
                }
            } else {
                handleModal(
                    <MessagePrompt
                        error
                        message="Ocorreu um erro durante a sua requisição."
                        errorAction={() => window.location.reload()}
                    />,
                );
            }
        }
    };

    useEffect(() => {
        if (isFree() || holder !== 'None') return;
        loadUserSubscriptions();
    }, []);

    return (
        <>
            <FeatureTitle feature={location.pathname} />
            <AnimatePresence exitBeforeEnter>
                <div className="my-8 mx-4">
                    <Switch>
                        <Route exact path={APP_URLS.PROFILE}>
                            <BillingView
                                freeUser={isFree()}
                                holder={holder}
                                browserHistory={history}
                                userSubscriptions={userSubscriptions}
                            />
                        </Route>
                        <Route path={APP_URLS.BILLING_DETAILS}>
                            <DetailsView
                                browserHistory={history}
                                userSubscriptions={userSubscriptions}
                            />
                        </Route>
                        <Route path={APP_URLS.CHANGE_PAYMENT_METHOD}>
                            <PaymentView
                                userSubscriptions={userSubscriptions}
                                browserHistory={history}
                            />
                        </Route>
                    </Switch>
                </div>
            </AnimatePresence>
        </>
    );
};

export default ProfileView;

import React, { useContext } from 'react';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';

const InputDate = ({ className, ...others }) => {
    const { onChangeDate } = useContext(HealthContext);
    return (
        <input
            {...others}
            className="px-2 block w-full bg-transparent overflow-hidden py-2 bg-gray-300 rounded-2xl"
            onChange={onChangeDate}
        />
    );
};

export default InputDate;

import React, { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';

import { SvgIconInputPassword } from '../../TemporaryPassword/Styles';

const LabelStyle = styled.label`
    color: #5e5e5e;
    width: ${(props) => (props.validation ? '100%' : '70%')};
`;

const InputStyle = styled.input`
    :focus {
        outline: none;
    }
`;

const InputPassword = ({
    classNameInput,
    classNameLabel,
    label,
    idInput,
    placeholder,
    validation,
    children,
    useRef,
    ...others
}) => {
    const [visiblePassword, setVisiblePassword] = useState(false);
    return (
        <LabelStyle
            validation={validation}
            htmlFor={idInput}
            className={cn(classNameLabel, 'block text-lg leading-6')}
        >
            <p className="mt-2">&nbsp;{label}</p>
            <div className=" bg-gray-300 flex items-center justify-between p-2 mt-1 rounded-md w-full">
                <InputStyle
                    id={idInput}
                    placeholder={placeholder}
                    className={cn(classNameInput, 'bg-transparent w-32')}
                    type={visiblePassword ? 'text' : 'password'}
                    ref={useRef}
                    {...others}
                />
                <div
                    onClick={() => {
                        setVisiblePassword(!visiblePassword);
                    }}
                >
                    {visiblePassword ? (
                        <SvgIconInputPassword red />
                    ) : (
                        <SvgIconInputPassword />
                    )}
                </div>
            </div>
            {children}
        </LabelStyle>
    );
};

export default InputPassword;

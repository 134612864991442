import React from 'react';

import AmericanExpress from './AmericanExpress';
import DinersClub from './DinersClub';
import Elo from './Elo';
import Hipercard from './Hipercard';
import JCB from './JCB';
import Mastercard from './Mastercard';
import Visa from './Visa';
import New from './New';

const Base = (Component) => ({ size, ...rest }) => ( <
    Component size = { size } {...rest }
    />
);

const FlagIcons = {
    american_express: Base(AmericanExpress),
    diners_club: Base(DinersClub),
    elo: Base(Elo),
    hipercard: Base(Hipercard),
    jcb: Base(JCB),
    mastercard: Base(Mastercard),
    visa: Base(Visa),
    new: Base(New)
};

const Companies = [{
        label: 'American Express',
        value: 'american_express',
        active: true
    },
    {
        label: 'Diners Club',
        value: 'diners_club',
        active: true
    },
    {
        label: 'Elo',
        value: 'elo',
        active: true
    },
    {
        label: 'Hipercard',
        value: 'hipercard',
        active: true
    },
    {
        label: 'JCB',
        value: 'jcb',
        active: true
    },
    {
        label: 'Mastercard',
        value: 'mastercard',
        active: true
    },
    {
        label: 'Visa',
        value: 'visa',
        active: true
    },
    {
        label: 'New',
        value: 'new',
        active: false
    },
];

export { FlagIcons, Companies };
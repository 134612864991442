import React, { useContext } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import { BACKGROUNDS } from '../../urls';
import ContractTerms from '../ContractTerms/ContractTerms';

const SupportPage = () => {
    const { handleModal } = useContext(ModalContext);
    return (
        <div className="bg-gray-300 p-4">
            <div className="mt-4 p-4 bg-white relative rounded">
                <div
                    className="bg-right-bottom bg-no-repeat absolute opacity-50 w-full h-full top-0 left-0"
                    style={{ backgroundImage: `url(${BACKGROUNDS.SUPPORT})` }}
                />
                <div className="relative">
                    <h1 className="text-xl font-medium">
                        INFORMAÇÕES, RECLAMAÇÕES E SUGESTÕES
                    </h1>
                    <h3 className="text-sm font-medium">
                        Estamos sempre disponíveis para lhe ajudar.
                    </h3>
                    <p className="mt-4 pr-20">
                        Para quaisquer informações sobre o seu plano, dúvidas,
                        reclamações ou sugestões, envie um e-mail para{' '}
                        <a
                            className="font-medium text-pink-600"
                            href="mailto:ouvidoria@assinasaude.com.br"
                        >
                            ouvidoria@assinasaude.com.br
                        </a>{' '}
                        ou, se preferir, ligue para nossa central de atendimento
                        pelo número{' '}
                        <a
                            href="tel:+5501127742035"
                            className="text-pink-600 font-medium"
                        >
                            (11) 2774-2035
                        </a>
                        , de segunda à sexta, das 08hs às 18hs.
                    </p>
                    <ul>
                        <li className="mt-2">
                            <a
                                href="https://forms.gle/LhByUHunzc1No85r8"
                                target="_blank"
                                rel="noreferrer"
                                className="text-pink-600 font-medium py-2"
                            >
                                Registrar Reclamação
                            </a>
                        </li>
                        <li className="mt-2">
                            <a
                                href="https://forms.gle/UQ7dinGG8BA8wikQ8"
                                target="_blank"
                                rel="noreferrer"
                                className="text-pink-600 font-medium py-2 mt-2"
                            >
                                Pesquisa de Satisfação / Sugestões
                            </a>
                        </li>
                        <li className="mt-2">
                            <button
                                type="button"
                                className="text-pink-600 font-medium"
                                onClick={() => handleModal(<ContractTerms />)}
                            >
                                Termos do contrato
                            </button>
                        </li>
                        <li className="mt-2">
                            <a
                                href="https://forms.gle/3aoJy5zu3Tc4ZJRE6"
                                target="_blank"
                                rel="noreferrer"
                                className="text-pink-600 font-medium py-2 mt-2"
                            >
                                Solicitar Cancelamento
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default SupportPage;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BACKGROUNDS, APP_URLS, BACKEND_URLS, SITE_URLS } from '../../urls';
import { FEATURES, GlobalContext } from '../../contexts/GlobalContext';

import { Icon } from '../ui';
import FirstSteps from '../../containers/FirstSteps/FirstSteps';
import MenuItem from './MenuItem';
import CardServiceChannels from './CardServiceChannels';
import CardInviteFriends from './CardInviteFriends';
import CardSignPlan from './CardSignPlan/CardSignPlan';
import Schedule from './Schedule';
import { ModalContext } from '../../contexts/ModalContext';
import { NotificationPrompt } from '../Notification';
import { checkUserKarmenData } from '../../services/api';
import { useNotification } from '../../contexts/NotificationContext';
import { getUserNotifications } from '../../services/Notifications';
import { isProduction, SUPPORT_WHATSAPP } from '../../constants';
import { openWhatsApp } from '../../utils';

const Home = () => {
    const { handleModal, setCloseButton } = useContext(ModalContext);
    const {
        isLoggedIn,
        navigateToFeature,
        isFree,
        user,
        isUnlimited,
    } = useContext(GlobalContext);

    const { push_enabled, push_status } = useNotification();

    const [firstStepsStatus, setFirstStepsStatus] = useState({
        onboarding: '',
        karmen: '',
        push: '',
    });

    const [showFirstSteps, setShowFirstSteps] = useState(false);

    const checkFirstSteps = async () => {
        try {
            const karmenResponse = await checkUserKarmenData(user.cpf);

            const onboarding =
                user.onboarding_done === 'None' ? 'pending' : 'finished';
            const karmen = karmenResponse.sessionId ? true : false;
            const push = push_status === 'granted' && push_enabled;

            setFirstStepsStatus({
                onboarding,
                karmen,
                push,
            });
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(() => {
        if (isProduction) {
            if (push_status !== 'none' && push_enabled !== 'none')
                return checkFirstSteps();
        } else {
            return checkFirstSteps();
        }
    }, [push_status, push_enabled]);

    useEffect(() => {
        const { onboarding, karmen, push } = firstStepsStatus;

        /*
            If values are not default
        */
        if (onboarding !== '' && karmen !== '' && push !== '') {
            /*
                If any step is not finished
            */
            if (onboarding !== 'finished' || !karmen || !push)
                return setShowFirstSteps(true);
        }

        return setShowFirstSteps(false);
    }, [firstStepsStatus]);

    const [notifications, setNotifications] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const loadUserNotifications = async () => {
        const params = {
            page: currentPage,
        };

        const res = await getUserNotifications(user.id, params);
        setTotalPages(res.total_pages);

        /*
            Group by created_at date
        */
        const out = res.data.reduce((acc, v) => {
            if (!acc[v.created_at.split(' ')[0]])
                acc[v.created_at.split(' ')[0]] = [];
            acc[v.created_at.split(' ')[0]].push(v);
            return acc;
        }, {});

        /*
            Set the entrie values as array, to organize the array into [date, notifications]
        */
        setNotifications(Object.entries(out));
    };

    useEffect(() => {
        loadUserNotifications();
    }, [currentPage]);

    const history = useHistory();

    return (
        <div style={{ maxWidth: '1280px' }} className="mx-auto">
            <div className="my-4 text-xl text-pink-900 mx-6 sm:mx-10 xl:mx-auto flex items-center">
                <div
                    style={{ width: '47px', height: '47px' }}
                    className="flex justify-center items-center mr-4"
                >
                    <Icon name="profile-outlined" size={47} />
                </div>
                <h1>
                    <span className="text-pink-600">
                        {user.name.split(' ')[0]}
                    </span>
                    , é bom ter você aqui :)
                </h1>
            </div>
            <div className="mx-6 sm:mx-10 xl:mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-12 mb-8 gap-6">
                    <div className="col-span-1 lg:col-span-7">
                        {showFirstSteps && (
                            <div className="mb-4 flex justify-center">
                                <FirstSteps
                                    firstStepsStatus={firstStepsStatus}
                                />
                            </div>
                        )}
                        {notifications && notifications.length !== 0 && (
                            <div className="inline lg:hidden">
                                <div className="text-gray-600 font-medium rounded px-4 mb-4 lg:mt-0 select-none">
                                    SUAS NOTIFICAÇÕES
                                </div>
                                <Schedule
                                    data={notifications}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPages={totalPages}
                                />
                            </div>
                        )}
                        <div className="text-gray-600 font-medium rounded ml-4 select-none">
                            RECOMENDADOS PARA VOCÊ
                        </div>
                        <CardServiceChannels />
                        <div className="text-gray-600 font-medium rounded px-4 py-4 select-none">
                            DESCONTOS
                        </div>
                        {isFree() ? (
                            <CardSignPlan />
                        ) : (
                            <CardInviteFriends isLoggedIn={isLoggedIn()} />
                        )}
                        <div className="bg-gray-300 pt-4">
                            <div className="text-gray-600 font-medium rounded px-4 select-none">
                                DESTAQUES
                            </div>
                            <div className="mt-4 grid grid-cols-2 gap-2">
                                <MenuItem
                                    title="Consultório virtual"
                                    background={BACKGROUNDS.TELEMEDICINE}
                                    href={BACKEND_URLS.TELEMEDICINE}
                                />
                                <MenuItem
                                    className="booking"
                                    title="Agendar consulta"
                                    background={BACKGROUNDS.ACCREDITED_NETWORK}
                                    onClick={() => {
                                        if (isUnlimited()) {
                                            return openWhatsApp(
                                                SUPPORT_WHATSAPP,
                                                'Olá, quero agendar uma consulta',
                                            );
                                        }
                                        history.push(APP_URLS.BOOKING);
                                    }}
                                />
                                <MenuItem
                                    href={BACKEND_URLS.CHAT}
                                    title="Avaliação de Saúde Online"
                                    background={BACKGROUNDS.KARMEN}
                                />
                                <MenuItem
                                    onClick={() =>
                                        navigateToFeature(
                                            FEATURES.ORIENTATION_24H,
                                        )
                                    }
                                    title="Orientação 24 horas"
                                    background={BACKGROUNDS.CALL0800}
                                    exclusive={isFree()}
                                />
                                <MenuItem
                                    title="Minhas Prescrições"
                                    background={BACKGROUNDS.PRESCRIPTIONS}
                                    onClick={() =>
                                        navigateToFeature(
                                            FEATURES.PRESCRIPTIONS,
                                        )
                                    }
                                />
                                <MenuItem
                                    title="Meus exames"
                                    background={BACKGROUNDS.EXAMES}
                                    onClick={() =>
                                        navigateToFeature(FEATURES.EXAMS)
                                    }
                                />

                                <MenuItem
                                    onClick={() =>
                                        navigateToFeature(
                                            FEATURES.HEALTH_MONITORING,
                                        )
                                    }
                                    title="Acompanhamento de Saúde"
                                    background={BACKGROUNDS.MONITORING}
                                />
                                <MenuItem
                                    onClick={() =>
                                        navigateToFeature(FEATURES.DIGITAL_CARD)
                                    }
                                    title="Carteirinha virtual"
                                    background={BACKGROUNDS.DIGITAL_CARD}
                                    exclusive={isFree()}
                                />
                                <MenuItem
                                    title="Conteúdos para minha saúde"
                                    background={BACKGROUNDS.BLOG}
                                    onClick={() =>
                                        window.open(SITE_URLS.BLOG, '_blank')
                                    }
                                />
                                <MenuItem
                                    title="Valores das consultas por telemedicina"
                                    background={BACKGROUNDS.TELEMEDICINE_VALUES}
                                    onClick={() =>
                                        navigateToFeature(
                                            FEATURES.TELEMEDICINE_VALUES,
                                        )
                                    }
                                />
                                <MenuItem
                                    onClick={() =>
                                        navigateToFeature(FEATURES.DRUGSTORES)
                                    }
                                    title="Farmácias com desconto"
                                    background={BACKGROUNDS.DRUGSTORES}
                                    exclusive={isFree()}
                                />
                                <MenuItem
                                    to={APP_URLS.SUPPORT}
                                    title="Informações, reclamações e sugestões"
                                    background={BACKGROUNDS.SUPPORT}
                                />
                            </div>
                        </div>
                        <div className="bg-gray-300 py-4">
                            <div className="text-gray-600 font-medium rounded px-4 select-none">
                                MAIS SERVIÇOS
                            </div>
                            <div className="sm:w-1/2 rounded bg-white border border-gray-500 flex flex-col">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setCloseButton(false);
                                        handleModal(<NotificationPrompt />);
                                    }}
                                    className="flex flex-grow p-4 hover:bg-gray-200 text-pink-600 tracking-widest font-semibold"
                                >
                                    <Icon
                                        name="alert-outline"
                                        size={20}
                                        className="fill-current text-pink-600"
                                    />
                                    <div className="ml-2">
                                        Alterar preferências de notificação
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:inline lg:col-span-5">
                        <div className="text-gray-600 font-medium rounded px-4 select-none">
                            SUAS NOTIFICAÇÕES
                        </div>
                        <Schedule
                            data={notifications}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

import React from "react";

function Elo({ size }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 71 71"
        >
            <g clipPath="url(#clip0)">
                <g clipPath="url(#clip1)">
                    <path fill="#000" d="M.01 19h70.57v33.346H.01V19z"></path>
                    <path
                        fill="#FFCB05"
                        d="M14.55 29.215a6.802 6.802 0 012.154-.347 6.801 6.801 0 016.665 5.442l4.664-.95c-1.069-5.277-5.735-9.25-11.329-9.25-1.283 0-2.511.209-3.664.595l1.51 4.51z"
                    ></path>
                    <path
                        fill="#00A4E0"
                        d="M9.046 44.326l3.155-3.565a6.79 6.79 0 01-2.294-5.096c0-2.025.887-3.845 2.294-5.089l-3.155-3.568a11.54 11.54 0 00-3.9 8.657c0 3.448 1.51 6.542 3.9 8.66z"
                    ></path>
                    <path
                        fill="#EF4123"
                        d="M23.37 37.03a6.8 6.8 0 01-8.82 5.086l-1.51 4.516a11.6 11.6 0 003.667.591c5.588 0 10.251-3.966 11.326-9.236l-4.664-.957z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M32.111 40.909a8.255 8.255 0 01-.486-.936c-.74-1.715-.773-3.49-.15-5.192.684-1.868 1.994-3.296 3.688-4.027 2.128-.914 4.48-.736 6.52.474 1.295.743 2.212 1.89 2.91 3.514.087.208.166.428.244.619l-12.726 5.548zm4.25-7.399c-1.512.65-2.291 2.068-2.128 3.728l6.4-2.756c-1.102-1.292-2.533-1.72-4.272-.972zm5.068 5.995c-.003 0-.003.003-.003.003l-.133-.09c-.383.618-.978 1.122-1.73 1.448-1.43.622-2.755.462-3.709-.374l-.087.133s0-.003-.004-.003l-1.623 2.43c.404.28.836.516 1.288.706 1.793.746 3.629.71 5.437-.075 1.307-.568 2.333-1.431 3.033-2.527l-2.469-1.651zm7.854-12.325v13.505l2.101.851-1.192 2.783-2.318-.965a2.623 2.623 0 01-1.141-.96c-.257-.396-.447-.942-.447-1.673v-13.54h2.997zm5.44 10.067c0-1.15.51-2.182 1.313-2.883l-2.155-2.402a7.029 7.029 0 00-2.382 5.28 7.03 7.03 0 002.376 5.285l2.152-2.403a3.817 3.817 0 01-1.304-2.877zm3.827 3.83c-.422 0-.833-.072-1.213-.199l-1.03 3.058a7.063 7.063 0 009.158-5.267l-3.163-.646a3.825 3.825 0 01-3.752 3.055zm.01-10.884c-.782 0-1.534.127-2.237.359l1.02 3.06a3.828 3.828 0 014.962 2.874l3.164-.64a7.059 7.059 0 00-6.91-5.653z"
                    ></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        d="M0 0H70.588V70.588H0z"
                        transform="translate(0 .176)"
                    ></path>
                </clipPath>
                <clipPath id="clip1">
                    <path
                        fill="#fff"
                        d="M0 0H70.588V33.346H0z"
                        transform="translate(0 19)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Elo;

import React, { useEffect } from 'react';
import { usePostHogContext } from 'posthog-js/react';

import { APP_URLS } from '../../../urls';
import { getParameterByName } from '../../../utils';
import { AnimatedDiv, Button, Icon, Section } from '../../ui';
import { Wrapper } from '../BookingOverview/Animate';
import Overview from '../Overview';

import { CorrectCircle, ErrortCircle } from './styles';

const BookingConfirm = ({
    browserHistory,
    bookingOptions,
    priceToPay,
    user: { name, isStaff },
}) => {
    const posthogCtx = usePostHogContext();

    useEffect(() => {
        if (!bookingOptions) {
            return browserHistory.replace(APP_URLS.BOOKING);
        }

        if (process.env.APP_ENV === 'production') {
            if (bookingOptions.status === 'requested') {
                return posthogCtx.client.capture('[AGENDAMENTO] concluido');
            }

            posthogCtx.client.capture('[AGENDAMENTO] Algo de errado');
        }
    }, []);

    return (
        bookingOptions && (
            <AnimatedDiv
                variants={Wrapper}
                initial="initial"
                animate="in"
                exit="exit"
                className="max-w-xl mx-auto container py-8"
            >
                <Section as="div">
                    <div className="flex flex-col justify-center items-center">
                        {bookingOptions.status === 'requested' ? (
                            <>
                                <CorrectCircle />
                                <h1 className="text-3xl text-green-600 font-medium my-4">
                                    Tudo certo!
                                </h1>
                                <h3 className="text-center text-gray-800 text-xl mb-8">
                                    Nossa equipe irá entrar em contato com você
                                    para confirmar o seu agendamento!
                                </h3>
                            </>
                        ) : (
                            <>
                                <ErrortCircle />
                                <h1 className="text-3xl text-red-600 font-medium my-4">
                                    Algo deu errado...
                                </h1>
                                <h3 className="text-center text-gray-800 text-xl mb-8">
                                    Não foi possível agendar a sua consulta. Por
                                    favor, tente novamente
                                </h3>
                            </>
                        )}
                    </div>

                    <Overview
                        bookingOptions={bookingOptions}
                        priceToPay={priceToPay}
                        username={name}
                        isStaff={isStaff}
                    />
                </Section>

                {bookingOptions.paymentMethod === 'bank_slip' &&
                    bookingOptions.status !== 'error' && (
                        <Button
                            color="outlined"
                            className="w-full my-4"
                            onClick={() =>
                                window.open(
                                    bookingOptions.bookedResponse.bill
                                        .charges[0].print_url,
                                    '_blank',
                                )
                            }
                        >
                            <Icon
                                className="stroke-current text-pink-600 mr-4"
                                name="download"
                                size={30}
                            />
                            Imprimir boleto
                        </Button>
                    )}

                {bookingOptions.status === 'error' ? (
                    <Button
                        className="w-full mt-4"
                        onClick={() => {
                            browserHistory.push(APP_URLS.BOOKING);
                            window.location.reload();
                        }}
                    >
                        Tentar novamente
                    </Button>
                ) : isStaff === 'True' && getParameterByName('patient_id') ? (
                    <Button
                        className="w-full mt-4"
                        onClick={() => browserHistory.push(APP_URLS.HOME)}
                    >
                        Finalizar
                    </Button>
                ) : (
                    <Button
                        className="w-full mt-4"
                        onClick={() =>
                            window.open(
                                'https://app.assinasaude.com.br/atendimento/consultas/',
                            )
                        }
                    >
                        Visualizar minhas consultas
                    </Button>
                )}
            </AnimatedDiv>
        )
    );
};

export default BookingConfirm;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

const Container = styled.div`
    display: ${({ hidden }) => (hidden ? 'none' : 'inline-flex')};
    width: 100%;
    flex-direction: column;

    label {
        color: ${({ theme }) => theme.colors.gray2};
        font-size: 18px;
    }

    ${({ notAnimated }) =>
        notAnimated
            ? null
            : css`
                  animation: fadeIn 0.7s ease-in both;
              `}

    position: relative;

    ${({ haveInput }) =>
        haveInput &&
        css`
            z-index: 1;
        `}

    ${({ focused }) =>
        focused &&
        css`
            z-index: 2;
        `}

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translate3d(0, -20%, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
`;

const InputWhrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 15px;

    ${({ wrapperVariant, theme }) => {
        switch (wrapperVariant) {
            case 'white':
                return css`
                    background-color: ${theme.colors.gray6};
                    border: 2px solid ${theme.colors.gray6};
                `;
            default:
                return css`
                    background-color: ${theme.colors.gray5};
                    border: 2px solid ${theme.colors.gray5};
                `;
        }
    }}

    svg:first-child,
    span:first-child {
        ${({ strokeSvg, theme }) =>
            strokeSvg
                ? css`
                      stroke: ${theme.colors.gray3};
                  `
                : css`
                      fill: ${theme.colors.gray3};
                      color: ${theme.colors.gray3};
                  `}
    }

    transition: all 0.1s ease;

    ${({ invalid, strokeSvg, theme }) =>
        invalid &&
        css`
            border: 2px solid ${theme.colors.red3};
            background-color: ${theme.colors.red6};

            svg:first-child,
            span:first-child {
                ${strokeSvg
                    ? css`
                          stroke: ${theme.colors.red3};
                      `
                    : css`
                          fill: ${theme.colors.red3};
                          color: ${theme.colors.red3};
                      `}
            }
        `}

    &:focus-within {
        ${({ primary, invalid, strokeSvg, theme }) =>
            primary
                ? invalid
                    ? css`
                          border: 2px solid ${darken(0.02, theme.colors.red3)};
                          background-color: ${darken(0.02, theme.colors.red6)};

                          svg:first-child,
                          span:first-child {
                              ${strokeSvg
                                  ? css`
                                        stroke: ${darken(
                                            0.02,
                                            theme.colors.red3,
                                        )};
                                    `
                                  : css`
                                        fill: ${darken(
                                            0.02,
                                            theme.colors.red3,
                                        )};
                                        color: ${darken(
                                            0.02,
                                            theme.colors.red3,
                                        )};
                                    `}
                          }
                      `
                    : css`
                          border: 2px solid ${theme.colors.primaryPink3};

                          svg:first-child,
                          span:first-child {
                              ${strokeSvg
                                  ? css`
                                        stroke: ${theme.colors.primaryPink3};
                                    `
                                  : css`
                                        fill: ${theme.colors.primaryPink3};
                                        color: ${theme.colors.primaryPink3};
                                    `}
                          }
                      `
                : invalid
                ? css`
                      border: 2px solid ${darken(0.02, theme.colors.red3)};
                      background-color: ${darken(0.02, theme.colors.red6)};

                      svg:first-child,
                      span:first-child {
                          ${strokeSvg
                              ? css`
                                    stroke: ${darken(0.02, theme.colors.red3)};
                                `
                              : css`
                                    fill: ${darken(0.02, theme.colors.red3)};
                                    color: ${darken(0.02, theme.colors.red3)};
                                `}
                      }
                  `
                : css`
                      border: 2px solid ${lighten(0.07, theme.colors.teal4)};

                      svg:first-child,
                      span:first-child {
                          ${strokeSvg
                              ? css`
                                    stroke: ${lighten(
                                        0.07,
                                        theme.colors.teal4,
                                    )};
                                `
                              : css`
                                    fill: ${lighten(0.07, theme.colors.teal4)};
                                    color: ${lighten(0.07, theme.colors.teal4)};
                                `}
                      }
                  `}
    }

    ${({ theme }) => theme.mixins.round_light}

    input {
        font-size: 18px;
        width: 100%;

        background: none;

        ::placeholder {
            fill: ${(props) => props.theme.colors.gray3};
        }

        &:focus {
            outline: none;
        }

        /* Remove arrows from number input */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }
    }
`;

const FormInput = ({
    children,
    label,
    htmlFor,
    haveInput,
    hidden,
    className,
    primary,
    invalid,
    strokeSvg,
    wrapperStyle,
    wrapperVariant,
    notAnimated,
}) => {
    const [focused, setFocused] = useState(false);

    return (
        <Container
            hidden={hidden}
            haveInput={haveInput}
            onFocus={() => {
                setFocused(true);
            }}
            onBlur={() => {
                setTimeout(() => {
                    setFocused(false);
                }, 200);
            }}
            focused={focused}
            notAnimated={notAnimated}
            className={className}
        >
            {label && <label htmlFor={htmlFor}>{label}</label>}
            {haveInput ? (
                <InputWhrapper
                    strokeSvg={strokeSvg}
                    primary={primary}
                    invalid={invalid}
                    style={wrapperStyle}
                    wrapperVariant={wrapperVariant}
                >
                    {children}
                </InputWhrapper>
            ) : (
                <div id={label}>{children}</div>
            )}
        </Container>
    );
};

export default FormInput;

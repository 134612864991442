import React from 'react';
import cn from 'classnames';
import { SelectComponent as Select, Textarea } from '../../ui';

const AttachFormExamType = ({OnAttachDescriptionChange = ()=>{}}) => {
    return (
        <div className="flex flex-col">
            <span className="text-base font-normal mt-5">
                Observações:
            </span>
                <Textarea
                    placeholder=""
                    className={cn(
                        'block mt-5 w-full border px-4 py-2, ',
                        'pr-8 rounded shadow leading-tight h-24',
                        'bg-gray-400 border-gray-400', 
                        'focus:outline-none focus:shadow-outline',
                    )}
                    onChange={(e) => OnAttachDescriptionChange(e.target.value)}
                />
        </div>
    );
};

export default AttachFormExamType;

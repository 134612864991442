import React from 'react';

import { capitalizeWords, getParameterByName } from '../../../utils';
import { formatDate, splitAndParseDate } from '../../../utils/Dates';
import { formatValue } from '../BookingUtils';

const Overview = ({
    bookingOptions: {
        specialty: { label },
        free_booking,
        type,
        unitInfo,
        selectedOption: {
            data_agenda,
            hora_agenda,
            medico: { nome, crm },
        },
    },
    username,
    isStaff,
    priceToPay,
}) => {
    return (
        <>
            <h2 className="text-lg font-medium text-gray-700">
                Especialidade:
            </h2>
            <span className="text-lg text-gray-700">{label}</span>

            <hr className="bg-gray-100 my-4" />

            <h2 className="text-lg font-medium text-gray-700">Data:</h2>
            <span className="text-lg text-gray-700">
                {formatDate(
                    splitAndParseDate(data_agenda),
                    "eeee, dd 'de' MMMM'",
                )}{' '}
                - {hora_agenda.slice(0, -3)}
            </span>

            <hr className="bg-gray-100 my-4" />

            {type === 'presencial' && (
                <>
                    <h2 className="text-lg font-medium text-gray-700">
                        Local:
                    </h2>
                    <span className="text-lg text-gray-700">
                        Globalmed {capitalizeWords(unitInfo.nome_fantasia)}{' '}
                        <br />
                        {capitalizeWords(unitInfo.logradouro)},{' '}
                        {unitInfo.numero} <br />
                        {capitalizeWords(unitInfo.bairro)}
                    </span>

                    <hr className="bg-gray-100 my-4" />
                </>
            )}

            <h2 className="text-lg font-medium text-gray-700">Profissional:</h2>
            <span className="text-lg text-gray-700">
                Dr (a) {nome} - CRM {crm}
            </span>

            <hr className="bg-gray-100 my-4" />

            <h2 className="text-lg font-medium text-gray-700">Paciente:</h2>
            <span className="text-lg text-gray-700">
                {isStaff === 'True' && getParameterByName('name')
                    ? getParameterByName('name')
                    : username}
            </span>

            <hr className="bg-gray-100 my-4" />

            <h2 className="text-lg font-medium text-gray-700">Valor:</h2>
            <span className="text-lg text-gray-700">
                {free_booking ? 'Grátis' : formatValue(priceToPay)}
            </span>
        </>
    );
};

export default Overview;

import React, { useState, useContext } from 'react';
import InputMask from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';

import { Button, FormInput, Spinner } from '../ui';
import { fetchApi } from '../../services/api';
import { ModalContext } from '../../contexts/ModalContext';
import { clearingPhoneNumber } from '../../utils';

import { Container } from '../../styles/global';
import PuppetAlertIcon from '../../assets/svg/puppetAlert.svg';
import EmailInputIcon from '../../assets/svg/emailBlack.svg';
import PhoneInputIcon from '../../assets/svg/smartphone.svg';

const UpdateUserInfos = () => {
    const { setOpen } = useContext(ModalContext);
    const { register, handleSubmit, control, errors, setError } = useForm();

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        const cleanPhone = clearingPhoneNumber(data.celular, '()- _');
        if (!cleanPhone) {
            setError('celular');
        }
        if (cleanPhone.length < 14) {
            setError('celular');
        }

        setLoading(true);
        try {
            await fetchApi(`/adm/user/update_user_infos/`, 'post', {
                ...data,
                celular: cleanPhone,
            });
            setLoading(false);
            setOpen(false);
        } catch (error) {
            setLoading(false);
            setOpen(false);
            throw new Error(error);
        }
    };

    return (
        <Container className="px-6 py-4 rounded-b-md">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="rounded-lg flex items-center justify-center w-full bg-pink-600 h-18">
                    <PuppetAlertIcon width="50" height="80" />
                </div>
                <div className="p-3 mt-3">
                    <h1 className="text-pink-600 text-xl text-center font-medium">
                        Confirme suas informações
                    </h1>
                    <h2 className="text-pink-700 text-lg mt-2">
                        Veja se está tudo certo com suas informações cadastrais.
                    </h2>
                    <p className="text-black text-base mt-2">
                        Mantenha seu cadastro atualizado para não perder nenhuma
                        informação sobre suas consultas e novidades.
                    </p>
                </div>

                <div className="mt-3 p-3 pt-6 border-t-2 border-gray-500">
                    <FormInput strokeSvg label="E-mail:" haveInput>
                        <EmailInputIcon width="23" height="23" />
                        <input
                            type="email"
                            id="email"
                            placeholder="digite..."
                            name="email"
                            ref={register}
                            className="pl-4 text-black"
                            required
                        />
                    </FormInput>
                    <FormInput
                        strokeSvg
                        className="mt-4"
                        label="Celular:"
                        haveInput
                    >
                        <PhoneInputIcon width="23" height="25" />
                        <Controller
                            as={InputMask}
                            control={control}
                            placeholder="+55 (11) 99999-9999"
                            name="celular"
                            mask="+99 (99) 99999-9999"
                            className="pl-4 text-black"
                            rules={{ required: true, minLength: 19 }}
                        />
                    </FormInput>

                    <p>
                        {errors.celular && (
                            <span className="text-red-600 text-xl text-center font-medium">
                                O campo Celular é obrigatorio e deve ter o
                                formato: +99 (99) 99999-9999
                            </span>
                        )}
                    </p>
                </div>

                <Button type="submit" className="w-full mt-3">
                    {loading ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <Spinner color="teal" />
                        </div>
                    ) : (
                        'Salvar'
                    )}
                </Button>
            </form>
        </Container>
    );
};

export default UpdateUserInfos;

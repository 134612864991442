import React, { useContext } from 'react';
import cn from 'classnames';

import { Input } from '../../ui';
import { HealthContext } from '../../../containers/HealthQuestionnaire/HealthQuestionnaire';

const InputCheck = ({
    label,
    id,
    value,
    name,
    className,
    onClick,
    ...others
}) => {
    const { onChangeCheck, valueInput, isDisabled } = useContext(HealthContext);
    return (
        <label
            className={cn(
                'flex items-center h-12 text-lg px-2 mt-2 rounded',
                onClick
                    ? `hover:bg-gray-400 cursor-pointer ${className}`
                    : isDisabled
                    ? 'field-input-disable flex placeholder-white'
                    : `hover:bg-gray-400 cursor-pointer ${className}`,
            )}
            htmlFor={id}
        >
            <Input
                onChange={onChangeCheck}
                id={id}
                type="checkbox"
                value={value}
                checked={valueInput[id] === value}
                disabled={onClick ? false : isDisabled}
                onClick={onClick}
                {...others}
            />
            <p className="pl-4">{label}</p>
        </label>
    );
};

export default InputCheck;

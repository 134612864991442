import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { APP_URLS } from '../../../urls';
import { Button, Icon } from '../../ui';

const HealthQuestionnaireFinished = () => {
    const history = useHistory();
    return (
        <div className="bg-white max-w-lg mx-auto p-4 sm:my-4 sm:rounded border border-gray-500">
            <h1 className="text-2xl font-medium text-teal-600">
                Parabéns por completar o questionário!
            </h1>
            <div className="mt-2 text-lg font-medium">
                Viu como é fácil se cuidar com a Kompa Saúde?
            </div>
            <div>
                As suas respostas já foram encaminhadas para a sua equipe de
                saúde. Isso significa que:
            </div>
            <div>
                <div className="mt-2 flex">
                    <Icon
                        name="check"
                        size={24}
                        className="fill-current text-teal-600 flex-shrink-0"
                    />
                    <div className="ml-2">
                        <span className="font-medium text-teal-600">
                            Você ganhou mais tempo
                        </span>
                        : Todos os médicos com quem você se consultar terão
                        acesso às suas informações.
                    </div>
                </div>
                <div className="mt-2 flex">
                    <Icon
                        name="check"
                        size={24}
                        className="fill-current text-teal-600 flex-shrink-0"
                    />
                    <div className="ml-2">
                        <span className="font-medium text-teal-600">
                            Você está mais protegido
                        </span>
                        : A sua equipe de saúde dedicada usará as suas respostas
                        para melhor lhe orientar e acompanhar o seu quadro de
                        saúde.
                    </div>
                </div>
                <div className="mt-2 flex">
                    <Icon
                        name="check"
                        size={24}
                        className="fill-current text-teal-600 flex-shrink-0"
                    />
                    <div className="ml-2">
                        Ficou mais fácil chamar um taxi com UBER, mais fácil
                        pedir comida com IFOOD e agora vai ficar mais fácil você
                        cuidar de sua saúde com a Kompa Saúde. Aproveite!
                    </div>
                </div>
            </div>
            <Button
                className="w-full flex-shrink-0 mt-4 px-2"
                type="button"
                onClick={() => {
                    history.replace(APP_URLS.HOME);
                }}
            >
                Finalizar
            </Button>
        </div>
    );
};

export default HealthQuestionnaireFinished;

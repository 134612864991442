import React from 'react';
import { format } from 'date-fns';

import { Section } from '../../../ui';
import SectionTitle from '../../SectionTitle';
import SettingsSelect from '../SettingsSelect';
import { CardFlag } from '../../../CreditCard';

const PaymentMethod = ({
    browserHistory,
    paymentInfo: {
        id,
        payment_method: { code },
        payment_profile,
    },
}) => {
    return (
        <>
            <SectionTitle
                title="Método de pagamento"
                description="Método de pagamento escolhido para esta assinatura"
                className="pb-4"
            />

            <Section className="max-w-xl rounded-md text-center px-4">
                {code === 'credit_card' ? (
                    payment_profile ? (
                        <>
                            <div className="flex justify-between">
                                <div className="text-lg">
                                    <div className="text-left">
                                        <span className="text-gray-800">
                                            **** **** ****{' '}
                                            {
                                                payment_profile.card_number_last_four
                                            }
                                        </span>
                                    </div>

                                    <div className="flex mx-auto text-lg">
                                        <span className="text-gray-700 mr-4">
                                            Validade
                                        </span>
                                        <span className="text-gray-800 font-medium">
                                            {format(
                                                new Date(
                                                    payment_profile.card_expiration,
                                                ),
                                                'MM/yy',
                                            )}
                                        </span>
                                    </div>

                                    <div className="flex justify-between mx-auto mt-2 text-lg">
                                        <span className="text-gray-800 font-medium">
                                            {payment_profile.holder_name}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <CardFlag
                                        name={
                                            payment_profile.payment_company.code
                                        }
                                        size={70}
                                    />
                                </div>
                            </div>

                            <hr className="bg-gray-100 my-4" />

                            <div
                                className="flex"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                <SettingsSelect
                                    options={[{ value: 1, label: 'Editar' }]}
                                    onSelect={() => {
                                        browserHistory.push(
                                            `/meu-perfil/assinaturas/${id}/alterar-pagamento`,
                                        );
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <p>
                            Primeiro pagamento por{' '}
                            {code === 'credit_card'
                                ? 'Cartão de crédito'
                                : 'Boleto bancário'}
                            . As informações de pagamento serão disponibilizadas
                            aqui para visualização e edição a partir da segunda
                            fatura.
                        </p>
                    )
                ) : (
                    <span className="text-gray-800 font-medium">
                        Boleto bancário
                    </span>
                )}
            </Section>
        </>
    );
};

export default PaymentMethod;

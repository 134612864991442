import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '../../ui';
import OpenModalQuizAnswered from '../../../hooks/OpenModalQuizAnswered';
import {
    excludeItemLocal,
    handleStartQuestionario,
} from '../../../utils/localStorage';
import ModalQuestionnaire from '../ModalQuestionnaire';

const HealthQuestionnaireWelcome = () => {
    const history = useHistory();
    const [
        isOpenModalQuizAnswered,
        setIsOpenModalQuizAnswered,
    ] = OpenModalQuizAnswered();
    return (
        <div className="container border-gray-500 bg-white max-w-lg mx-auto py-4 sm:my-4 sm:border sm:rounded flex flex-col">
            <h1 className="font-medium text-teal-600 text-2xl">
                Como está sua saúde?
            </h1>
            <div className="mt-4">
                Nós preparamos um questionário para nos ajudar a entender como
                está a sua saúde, e te ajudaremos a montar um plano para a sua
                saúde de acordo com as suas respostas.
            </div>
            <div className="mt-2 rounded bg-yellow-200 border border-yellow-400 p-4 flex">
                <Icon
                    name="exclamation-triangle"
                    className="fill-current text-yellow-600 flex-shrink-0"
                />
                <div className="ml-2">
                    As suas respostas são <b>100% privadas e confidenciais</b> e
                    serão exibidas somente para os médicos e enfermeiros com
                    quem você se consultar.
                </div>
            </div>
            <Button
                className="w-1/2 flex-shrink-0 self-center mt-4"
                type="button"
                onClick={() => {
                    handleStartQuestionario(history);
                }}
            >
                Iniciar
            </Button>
            <ModalQuestionnaire
                isOpen={isOpenModalQuizAnswered}
                setOpen={setIsOpenModalQuizAnswered}
                label="Questionario Já respondido"
                buttonYes={{
                    label: 'Responder novamente',
                    onClick: () => {
                        setIsOpenModalQuizAnswered(false);
                        excludeItemLocal('questionarioAnswered');
                    },
                }}
                buttonNot={{
                    label: 'Não, voltar ao menu',
                    onClick: () => {
                        setIsOpenModalQuizAnswered(false);
                        history.replace('/');
                    },
                }}
                title="Questionário já respondido"
                content="Você já respondeu o questionário de saúde, deseja respondê-lo novamente?"
            />
        </div>
    );
};

export default HealthQuestionnaireWelcome;
